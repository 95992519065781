import NavigationBlocker from '@/components/navigation-blocker/navigation-blocker';
import { ConfigContext } from '@/context/config-context';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import Header from '@/layout/header/header';
import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import { Button, ButtonGroup, Col, Container, Row } from 'react-bootstrap';
import useSWR from 'swr';
import { SettingsContext } from '../../context/settings-context';
import AccountSettings from './components/account-settings';
import CommunicationSettings from './components/communication-settings';
import PrivacySettings from './components/privacy-settings';
import './settings-overview.scss';

function SettingsOverview() {

    useDocumentTitle(t('documentTitles:settings'));

    const configContext = useContext(ConfigContext);
    const settingsContext = useContext(SettingsContext);

    const { data, isLoading, error } = useSWR(configContext.configBody.api.endpoints.general.settings);

    useEffect(() => {

        if (data) {
            settingsContext.updateSettings(data);
        }

    }, [data]);

    return (
        <div className='settings-overview'>

            <Container fluid className='bg-paars position-relative'>
                <Container>

                    <div className='header-img full position-absolute px-0'>
                        <img src={'/assets/img/WIHV_3D_Visual_Profiel_3.jpg'} alt='' />
                    </div>

                    <div className="position-relative">
                        <Header />
                    </div>

                    <Row className='position-relative py-5 first-row-after-heading-img'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6} className='d-flex flex-column text-wit'>
                            <div className="font-heavy h2 pb-5">{t('settings:title')}</div>
                            <p className='flex-grow-1'>
                                {t('settings:description')}
                            </p>
                        </Col>
                    </Row>

                    <Row className='position-relative pb-5'>
                        <Col className='d-flex d-md-block justify-content-center'>
                            <ButtonGroup>
                                {!settingsContext.editable &&
                                    <Button variant="wit" onClick={() => settingsContext.updateEditable(true)}>
                                        <i className="fas fa-pen me-2"></i>
                                        <span>{t('settings:editSettings')}</span>
                                    </Button>
                                }

                                {settingsContext.editable &&
                                    <>
                                        <Button variant="zwartblauw" disabled={settingsContext.requestInProgress} onClick={() => settingsContext.updateEditable(false)}>
                                            <i className="fas fa-xmark me-2"></i>
                                            <span>{t('general:cancel')}</span>
                                        </Button>
                                        <Button variant="zwartblauw" disabled={settingsContext.requestInProgress} onClick={() => settingsContext.saveSettings(settingsContext.tempChanges)}>
                                            <i className="fas fa-floppy-disk me-2"></i>
                                            <span>{t('settings:saveSettings')}</span>
                                        </Button>
                                    </>
                                }
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className='bg-blauw py-5'>
                <Container>
                    <Row>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <CommunicationSettings />
                            <PrivacySettings />
                        </Col>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <AccountSettings />
                        </Col>
                    </Row>
                </Container>
            </Container>

            <NavigationBlocker shouldBlock={{ obj1: settingsContext.settings, obj2: settingsContext.tempChanges }} />
        </div>
    )
}

export default SettingsOverview