import { ConfigContext } from "@/context/config-context";
import Header from "@/layout/header/header";
import { t } from "i18next";
import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import useSWR from "swr";
import MeasurementsSlider from "./components/measurement-slider";
import './measurements-overview.scss';
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

function MeasurementsOverview() {

    useDocumentTitle(t('documentTitles:measurements'));

    const configContext = useContext(ConfigContext);
    const { data, isLoading, error } = useSWR(configContext.configBody.api.endpoints.measurements.list);

    return (
        <div className="measurements-overview">
            <Container fluid className='bg-groen position-relative pb-5'>
                <Container>

                    <div className='header-img full position-absolute px-0'>
                        <img src={'/assets/img/WIHV_3D_Visual_Groen_Wit.jpg'} style={{ objectPosition: 'center center' }} alt='' />
                    </div>

                    <div className="position-relative pb-5">
                        <Header />
                    </div>

                    <Row className='position-relative first-row-after-heading-img'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6} className='d-flex flex-column text-wit'>
                            <div className="font-heavy h2 pb-5">{t('measurements:overview:title')}</div>
                            <p>{t('measurements:overview:description')}</p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="py-5">
                <Container>
                    <Row className="pb-5">
                        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <MeasurementsSlider measurements={data} isLoading={isLoading} />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default MeasurementsOverview