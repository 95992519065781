import { Link } from "react-router-dom";
import { SwiperSlide } from 'swiper/react';
import SwiperComponent from "@/components/swiper-component/swiper-component";
import { MeasurementDefinition } from "@/measurements/model/measurement-definition";
import { Placeholder } from "react-bootstrap";
import './measurement-slider.scss';
import { MeasurementType } from "../model/measurement-types";
import { t } from "i18next";

type MeasurementsSliderProps = {
    measurements: MeasurementDefinition[];
    isLoading: boolean;
}

function MeasurementsSlider({ measurements, isLoading }: MeasurementsSliderProps) {

    return (
        <>
            {isLoading
                ?
                <>
                    <Placeholder as="div" className='d-flex flex-nowrap overflow-hidden' animation="glow">
                        <Placeholder className="slide me-4" />
                        <Placeholder className="slide me-4" />
                        <Placeholder className="slide" />
                    </Placeholder>
                </>
                :
                <SwiperComponent loop={false}>
                    {
                        measurements?.map((measurement) => (
                            <SwiperSlide key={measurement.id} className="measurements-slide">
                                <div className={`slide ${measurement.type == MeasurementType.Unknown ? 'locked' : ''}`}>

                                    {
                                        measurement.type == MeasurementType.Unknown &&
                                        <div className="locked-measurement">
                                            <i className="fa-solid fa-lock fa-xl" />
                                        </div>
                                    }

                                    <img
                                        src="https://images.pexels.com/photos/7821686/pexels-photo-7821686.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        alt="Card Image"
                                        className="slide-image"
                                    />

                                    <div className="slide-overlay">

                                        {
                                            measurement.completed &&
                                            <div className="status">
                                                <i className={`fas fa-check me-2 fa-md text-success`}></i>
                                                <p>{t('measurements:overview:completed')}</p>
                                            </div>
                                        }

                                        <h2 className="slide-title">{measurement.title}</h2>
                                        <p className="slide-intro">{measurement.introduction}</p>

                                        <div>
                                            {
                                                !measurement.completed &&
                                                <Link to={`/profile/measurements/${measurement.id}`} className='btn btn-secondary'>
                                                    {t('measurements:overview:startMeasurement')} <i className="fa-solid fa-arrow-right ms-2" />
                                                </Link>
                                            }
                                            {
                                                measurement.completed &&
                                                <Link to={`/profile/measurements/${measurement.id}/results`} className='btn btn-secondary'>
                                                    {t('measurements:overview:toResultMeasurement')} <i className="fa-solid fa-arrow-right ms-2" />
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </SwiperComponent>
            }
        </>
    )
}

export default MeasurementsSlider