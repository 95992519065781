import { Company } from '@/company/model/company';
import { ConfigContext } from '@/context/config-context';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import useSWR from 'swr';
import './admin-overview.scss';
import useAuthenticatedDelete from '@/hooks/useAuthenticatedDelete';
import toast from 'react-hot-toast';
import { EmployerFunction } from '@/functions/model/employer-function';

function AdminOverview() {

    useDocumentTitle('Admin Overview');
    const configContext = useContext(ConfigContext);
    const [showModal, setShowModal] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState<Company>(null);
    const [selectedEmployerFunction, setSelectedEmployerFunction] = useState<EmployerFunction>(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const deleteRequest = useAuthenticatedDelete();
    const { data: companies, isLoading: companiesLoading, error: companiesError, mutate } = useSWR<Company[]>(configContext.configBody.api.endpoints.admin.companies.list);
    const { data: companyFunctions } = useSWR<EmployerFunction[]>(selectedCompany?.id ? configContext.configBody.api.endpoints.admin.functions.list.replace('{id}', selectedCompany.id) : null);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);

    useEffect(() => {

        if (companies) {
            sortAndFilterCompanies();
        }

    }, [companies, searchTerm]);

    const sortAndFilterCompanies = () => {

        let result = [...companies];

        if (searchTerm) {
            result = result.filter((activity) =>
                activity.name.toLowerCase().includes(searchTerm.toLowerCase()) || activity.kvk.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        setFilteredCompanies(result);
    }

    const handleViewClick = (company) => {
        setSelectedCompany(company);
        setSelectedEmployerFunction(null);
        setShowModal(true);
    };

    const handleDeleteCompany = () => {
        deleteRequest(configContext.configBody.api.endpoints.admin.companies.detail.replace('{id}', selectedCompany.id))
            .then(() => {
                mutate();
                setShowConfirmModal(false);
                toast.success('Bedrijfsprofiel verwijderd');
            });
    }

    return (
        <div className='admin-overview'>
            <Container fluid className='bg-paars position-relative'>
                <Container>
                    <Row className='position-relative py-5'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6} className='d-flex flex-column text-wit'>
                            <div className="font-heavy h2"><i className='fas fa-file-shield me-2' /> Admin overzicht</div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className='py-5'>
                <Container>
                    <Row className='pb-4 d-flex justify-content-end'>
                        <Col sm={4}>
                            <Form.Control type="text" placeholder={'zoeken...'} value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} />
                        </Col>
                    </Row>

                    {companiesLoading ? (
                        <div>Loading...</div>
                    ) : companiesError ? (
                        <div>Error: {companiesError.message}</div>
                    ) : (
                        <Table striped hover bgcolor="white">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Bedrijfsnaam</th>
                                    <th>KVK</th>
                                    <th>Contactpersoon</th>
                                    <th>Aangemaakt op</th>
                                    <th>Gewijzigd op</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredCompanies?.map((company, index) => (
                                    <tr key={company.id}>
                                        <td>{index + 1}</td>
                                        <td>{company.name || ''}</td>
                                        <td>{company.kvk || ''}</td>
                                        <td>{`${company.contactPerson?.name || ''}`}</td>
                                        <td>{company.createdOn ? new Date(company.createdOn)?.toLocaleDateString() : ''}</td>
                                        <td>{company.updatedOn ? new Date(company.updatedOn)?.toLocaleDateString() : ''}</td>
                                        <td>
                                            <Button variant="primary" onClick={() => handleViewClick(company)}>info</Button>
                                            <Button variant="primary" onClick={() => { setSelectedCompany(company); setShowConfirmModal(true) }}>verwijderen</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Container>
            </Container>

            <Modal show={showModal} size='xl' onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='h3 font-bold text-donkerblauw'>{selectedCompany?.name || ''}</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'bg-lichtblauw'}>
                    <Form>
                        <Row>
                            <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <Form.Group className="mb-3" controlId="formCompanyName">
                                    <Form.Label>Bedrijfsnaam</Form.Label>
                                    <Form.Control type="text" value={selectedCompany?.name || ''} readOnly />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formKVK">
                                    <Form.Label>KVK nummer</Form.Label>
                                    <Form.Control type="text" value={selectedCompany?.kvk || ''} readOnly />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formContactDetails">
                                    <Form.Label>Straat</Form.Label>
                                    <Form.Control type="text" value={`${selectedCompany?.contactDetails?.street || ''}, ${selectedCompany?.contactDetails?.houseNumber || ''}`} readOnly />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formContactDetails">
                                    <Form.Label>Plaats</Form.Label>
                                    <Form.Control type="text" value={`${selectedCompany?.contactDetails?.city || ''}`} readOnly />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formContactDetails">
                                    <Form.Label>Postcode</Form.Label>
                                    <Form.Control type="text" value={`${selectedCompany?.contactDetails?.postalCode || ''}`} readOnly />
                                </Form.Group>
                            </Col>

                            <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <Form.Group className="mb-3" controlId="formContactPerson">
                                    <Form.Label>Naam contactpersoon</Form.Label>
                                    <Form.Control type="text" value={`${selectedCompany?.contactPerson?.name || ''}`} readOnly />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formContactPerson">
                                    <Form.Label>Functie contactpersoon</Form.Label>
                                    <Form.Control type="text" value={`${selectedCompany?.contactPerson?.function || ''}`} readOnly />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formContactPerson">
                                    <Form.Label>Email contactpersoon</Form.Label>
                                    <Form.Control type="text" value={`${selectedCompany?.contactPerson?.email || ''}`} readOnly />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formContactPerson">
                                    <Form.Label>Telefoon contactpersoon</Form.Label>
                                    <Form.Control type="text" value={`${selectedCompany?.contactPerson?.phone || ''}`} readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            companyFunctions &&
                            <Row className='pt-5'>
                                <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <div className='h4 font-bold text-donkerblauw mb-3'>Functies</div>
                                    <Form.Group className="mb-3" controlId="formFunction">
                                        <Form.Select placeholder='Kies een functie' defaultValue={''} onChange={(e) => setSelectedEmployerFunction(companyFunctions.find(x => x.id == e.target.value))}>
                                            <option value={''}>Kies een functie</option>
                                            {companyFunctions?.map((func) => (
                                                <option key={func.id} value={func.id}>
                                                    {func.name} - {new Date(func.createdOn)?.toLocaleDateString()}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                        {
                            selectedEmployerFunction &&
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <div className='h4 font-bold text-donkerblauw mb-3'>Functie details</div>
                                    <Form.Group className="mb-3" controlId="formFunction">
                                        <Form.Control as="textarea" rows={20} value={JSON.stringify(selectedEmployerFunction, null, 2)} readOnly />
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Sluiten
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="confirm-modal wihv-modal" show={showConfirmModal} onHide={() => setShowConfirmModal(false)} backdrop="static" centered>
                <Modal.Header>
                    <div className='h3 text-donkerblauw'>{selectedCompany?.name}</div>
                </Modal.Header>

                <Modal.Body as={Container}>
                    <p>Weet je zeker dat je dit bedrijfsprofiel wil verwijderen?</p>
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-primary-outline transparent" onClick={() => setShowConfirmModal(false)}>
                        Nee
                    </button>
                    <button className="btn btn-secondary" onClick={() => handleDeleteCompany()}>
                        Ja
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AdminOverview