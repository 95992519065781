import { useTranslation } from 'react-i18next';
import './footers.scss'
import { Col, Container, Row } from 'react-bootstrap';
import PartnerFooter from './partner-footer';
import { Link } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

function PageFooter() {

    const { t } = useTranslation();
    const isAuthenticated = useIsAuthenticated();

    return (
        <footer className="page-footer">
            <Container fluid className="bg-geel">
                <Container>
                    <Row className="bg-geel text-center text-md-start py-5 px-5">

                        <Col sm={12} md={6} lg={3} xl={3} className="mb-4 mb-md-0">
                            <img src="/assets/img/werk-in-het-vooruitzicht-fullcolor.svg" alt="" width="156" height="40" className="mb-4 d-none d-md-block" />
                        </Col>

                        <Col sm={12} md={6} lg={3} xl={3} className="d-none d-lg-block">
                            <h5 className='font-heavy mb-3'>{t('public:footer:employersTitle')}</h5>
                            <ul id="menu-footer-leren" className="nav footer-nav d-block" >
                                <li className="menu-item nav-item">
                                    <Link to="/" className="nav-link">
                                        <span>{t('public:footer:generalInfo')}</span>
                                    </Link>
                                </li>
                                <li className="menu-item nav-item">
                                    <Link to="/testimonials" className="nav-link">
                                        <span>{t('public:footer:experiences')}</span>
                                    </Link>
                                </li>
                                <li className="menu-item nav-item">
                                    <Link to="/faq" className="nav-link">
                                        <span>{t('public:footer:faq')}</span>
                                    </Link>
                                </li>
                                {isAuthenticated &&
                                    <li className="menu-item nav-item">
                                        <Link to="/profile/company" className="nav-link">
                                            <span>{t('public:footer:profile')}</span>
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </Col>

                        <Col sm={12} md={6} lg={3} xl={3} className="d-none d-lg-block">
                            <h5 className='font-heavy mb-3'>{t('public:footer:aboutUsTitle')}</h5>
                            <ul id="menu-footer-over-ons" className="nav footer-nav d-block">
                                <li className="menu-item nav-item">
                                    <Link to="/about-us" className="nav-link">
                                        <span>{t('public:footer:aboutUs')}</span>
                                    </Link>
                                </li>
                                <li className="menu-item nav-item">
                                    <Link to="/initiators" className="nav-link">
                                        <span>{t('public:footer:initiators')}</span>
                                    </Link>
                                </li>
                                <li className="menu-item nav-item">
                                    <Link to="/partners" className="nav-link">
                                        <span>{t('public:footer:partners')}</span>
                                    </Link>
                                </li>
                                <li className="menu-item nav-item">
                                    <Link to="/development" className="nav-link">
                                        <span>{t('public:footer:talentDevelopment')}</span>
                                    </Link>
                                </li>
                                <li className="menu-item nav-item">
                                    <Link to="/traineeships" className="nav-link">
                                        <span>{t('public:footer:traineeships')}</span>
                                    </Link>
                                </li>
                                <li className="menu-item nav-item">
                                    <a target="_blank" rel="noopener noreferrer" href="https://werkinhetvooruitzicht.nl/wp-content/uploads/2023/04/wihv-algemene-voorwaarden-werkgever.pdf" className="nav-link">
                                        <span>{t('public:footer:termsAndConditions')}</span>
                                    </a>
                                </li>
                                <li className="menu-item nav-item">
                                    <Link to="/contact" className="nav-link">
                                        <span>{t('public:footer:contact')}</span>
                                    </Link>
                                </li>
                            </ul>
                        </Col>

                        <Col sm={12} md={6} lg={3} xl={3}>
                            <h5 className='font-heavy mb-3'>{t('public:footer:contactTitle')}</h5>
                            <div className='footer-contact'>
                                <address className='address mb-3 mt-1'>
                                    Koekoeksweg 3
                                    <br />
                                    Postbus 90
                                    <br />
                                    8084 GN ’t Harde
                                </address>
                                <address className='address my-3'>
                                    Evert van de Beekstraat 354
                                    <br />
                                    1118 CZ Schiphol
                                </address>
                                <div className='d-flex flex-column'>
                                    <a href="mailto:info@werkinhetvooruitzicht.nl">info@werkinhetvooruitzicht.nl</a>
                                    <a href="tel:+31885253505">+31 88 525 3505</a>
                                </div>
                                <div className="social-icons mt-3">
                                    <a aria-label="Volg ons" href="https://www.linkedin.com/company/werkinhetvooruitzicht/" target="_blank">
                                        <i className='fa-brands fa-linkedin fa-2xl' />
                                    </a>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </Container>

            <PartnerFooter />
        </footer>
    )
}

export default PageFooter