import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import './talent-preview.scss';
import useFavoriteTalents from '@/hooks/useFavoriteTalents';

type TalentPreviewProps = {
    id: string;
    name: string;
    subtitle: string;
    description: string;
    videoSrc: string;
}

function TalentPreview({ id, name, subtitle, description, videoSrc }: TalentPreviewProps) {

    const { t } = useTranslation();
    const { toggleFavorite, favorites } = useFavoriteTalents();
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef && videoRef.current) {
            if (videoPlaying) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }, [videoPlaying]);

    return (
        <div className="talent-preview p-4 bg-wit default-br">
            <div className='d-flex justify-content-between'>
                <div className="h3 font-black text-oranje">{name}</div>
                <div className='favorite' onClick={() => toggleFavorite(id)}>
                    {favorites.includes(id)
                        ? <i className='fa-solid fa-heart fa-xl' />
                        : <i className='fa-regular fa-heart fa-xl' />}
                </div>
            </div>
            <div className="h5 font-heavy mt-2 mb-3">{subtitle}</div>
            <div className="video-container">
                {!videoPlaying && (
                    <div className="video-overlay" onClick={(e) => { e.preventDefault(); setVideoPlaying(!videoPlaying) }}>
                        <button className="play-button">
                            <i className="fas fa-play fa-2xl" />
                        </button>
                    </div>
                )}
                <video width={'100%'} height={'auto'}
                    onClick={(e) => { e.preventDefault(); setVideoPlaying(!videoPlaying) }}
                    ref={videoRef}
                    controls={videoPlaying}
                    controlsList="nodownload noremoteplayback"
                    autoPlay={false}
                    muted={false}
                    onEnded={() => { setVideoPlaying(false) }}
                    onPlay={() => setVideoPlaying(true)}
                    onPause={() => setVideoPlaying(false)}
                    preload={"auto"}>
                    <source src={videoSrc} type="video/mp4" />
                    {t('general:videoNotPlayable')}
                </video>
            </div>
            <p id="description" className={`description ${isExpanded ? 'expanded' : ''}`}>
                {description}
            </p>
            <button className='description-btn' onClick={() => setIsExpanded(!isExpanded)} aria-expanded={isExpanded} aria-controls="description">
                {isExpanded
                    ? <span><i className='fas fa-chevron-up me-2'/><span>{t('talents:overview:readLessLink', { name: name })}</span></span>
                    : <span><i className='fas fa-chevron-down me-2'/><span>{t('talents:overview:readMoreLink', { name: name })}</span></span>
                }
            </button>
        </div>
    )
}

export default TalentPreview