import { Route, Routes } from 'react-router-dom';
import TalentsOverview from './talents-overview';

function TalentsModule() {

    return (
        <Routes>
            <Route path="/">
                <Route index element={<TalentsOverview />} />
            </Route>
        </Routes>
    )
}

export default TalentsModule;