import { CompanySize } from "@/company/model/company-size";
import { FeedbackStatus } from "@/feedback/models/feedback-status";
import { ContractType } from "@/functions/model/contract-type";
import { EducationLevel } from "@/functions/model/education-level";
import { DataSourceSupplier, DataSourceType, FunctionDataSource } from "@/functions/model/function-data-source";
import { LanguageLevel } from "@/functions/model/language-level";
import { Vendor } from "@/functions/model/vendor";
import { WorkLocation } from "@/functions/model/work-location";
import { ReminderFrequency } from "@/settings/model/reminder-frequency";
import { useTranslation } from "react-i18next";

export function useEducationLevelTranslation() {

    const { t } = useTranslation();

    function translateEducationLevel(educationLevel: EducationLevel): string {

        switch (educationLevel) {
            case EducationLevel.MBO1:
                return t('enums:educationLevel:mbo1');
            case EducationLevel.MBO2:
                return t('enums:educationLevel:mbo2');
            case EducationLevel.MBO3:
                return t('enums:educationLevel:mbo3');
            case EducationLevel.MBO4:
                return t('enums:educationLevel:mbo4');
            case EducationLevel.MBOPlus:
                return t('enums:educationLevel:mboPlus');
            case EducationLevel.AD:
                return t('enums:educationLevel:ad');
            case EducationLevel.Bachelor:
                return t('enums:educationLevel:bachelor');
            case EducationLevel.Master:
                return t('enums:educationLevel:master');
            case EducationLevel.Doctorate:
                return t('enums:educationLevel:doctorate');
            default:
                return '';
        }
    }

    return { translateEducationLevel };
}

export function useLanguageLevelTranslation() {

    const { t } = useTranslation();

    function translateLanguageLevel(languageLevel: LanguageLevel): string {

        switch (languageLevel) {
            case LanguageLevel.A1:
                return t('enums:languageLevel:a1');
            case LanguageLevel.A2:
                return t('enums:languageLevel:a2');
            case LanguageLevel.B1:
                return t('enums:languageLevel:b1');
            case LanguageLevel.B2:
                return t('enums:languageLevel:b2');
            case LanguageLevel.C1:
                return t('enums:languageLevel:c1');
            case LanguageLevel.C2:
                return t('enums:languageLevel:c2');
            default:
                return t('enums:languageLevel:unknown');
        }
    }

    return { translateLanguageLevel };
}

export function useCompanySizeTranslation() {

    const { t } = useTranslation();

    function translateCompanySize(companySize: CompanySize): string {

        switch (companySize) {
            case CompanySize.Small:
                return t('enums:companySize:small');
            case CompanySize.Medium:
                return t('enums:companySize:medium');
            case CompanySize.Large:
                return t('enums:companySize:large');
            case CompanySize.Enterprise:
                return t('enums:companySize:enterprise');
            default:
                return '';
        }
    }

    return { translateCompanySize };
}

export function useWorkLocationTranslation() {

    const { t } = useTranslation();

    function translateWorkLocation(workLocation: WorkLocation): string {

        switch (workLocation) {
            case WorkLocation.Office:
                return t('enums:workLocation:office');
            case WorkLocation.Home:
                return t('enums:workLocation:home');
            case WorkLocation.FieldWork:
                return t('enums:workLocation:fieldWork');
            case WorkLocation.Hybrid:
                return t('enums:workLocation:hybrid');
            default:
                return '';
        }
    }

    return { translateWorkLocation };
}

export function useContractTypeTranslation() {

    const { t } = useTranslation();

    function translateContractType(contractType: ContractType): string {

        switch (contractType) {
            case ContractType.ToBeDetermined:
                return t('enums:contractType:toBeDetermined');
            case ContractType.Permanent:
                return t('enums:contractType:permanent');
            case ContractType.Temporary:
                return t('enums:contractType:temporary');
            default:
                return '';
        }
    }

    return { translateContractType };
}

export function useVendorTranslation() {

    const { t } = useTranslation();

    function translateVendor(vendor: Vendor): string {

        switch (vendor) {
            case Vendor.Microsoft:
                return t('enums:vendor:microsoft');
            case Vendor.LinkedIn:
                return t('enums:vendor:linkedIn');
            case Vendor.Other:
                return t('enums:vendor:other');
            default:
                return '';
        }
    }

    return { translateVendor };
}

export function useReminderFrequencyTranslation() {

    const { t } = useTranslation();

    function translateReminderFrequency(reminderFrequency: ReminderFrequency): string {

        switch (reminderFrequency) {
            case ReminderFrequency.Monthly:
                return t('enums:reminderFrequency:monthly');
            case ReminderFrequency.Quarterly:
                return t('enums:reminderFrequency:quarterly');
            case ReminderFrequency.Never:
                return t('enums:reminderFrequency:never');
            default:
                return '';
        }
    }

    return { translateReminderFrequency };
}

export function useFeedbackStatusTranslation() {

    const { t } = useTranslation();

    function translateFeedbackStatus(feedbackStatus: FeedbackStatus): string {

        switch (feedbackStatus) {
            case FeedbackStatus.Created:
                return t('enums:feedbackStatus:created');
            case FeedbackStatus.Invited:
                return t('enums:feedbackStatus:invited');
            case FeedbackStatus.Submitted:
                return t('enums:feedbackStatus:submitted');
            case FeedbackStatus.Reviewed:
                return t('enums:feedbackStatus:reviewed');
            case FeedbackStatus.Deleted:
                return t('enums:feedbackStatus:deleted');
            default:
                return '';
        }
    }

    return { translateFeedbackStatus };
}

export function useDataSourceTypeTranslation() {

    const { t } = useTranslation();

    function translateDataSourceType(dataSourceType: DataSourceType): string {

        switch (dataSourceType) {
            case DataSourceType.Profession:
                return t('enums:dataSourceType:profession');
            case DataSourceType.Vacancy:
                return t('enums:dataSourceType:vacancy');
            case DataSourceType.Feedback:
                return t('enums:dataSourceType:feedback');
            case DataSourceType.Employer:
                return t('enums:dataSourceType:employer');
            default:
                return '';
        }
    }

    return { translateDataSourceType };
}

export function useDataSourceSupplierTranslation() {

    const { t } = useTranslation();

    function translateDataSourceSupplier(dataSourceSupplier: DataSourceSupplier): string {

        switch (dataSourceSupplier) {
            case DataSourceSupplier.Esco:
                return t('enums:dataSourceSupplier:esco');
            case DataSourceSupplier.LinkedIn:
                return t('enums:dataSourceSupplier:linkedin');
            case DataSourceSupplier.Wihv:
                return t('enums:dataSourceSupplier:wihv');
            default:
                return '';
        }
    }

    return { translateDataSourceSupplier };
}