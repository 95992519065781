import { useContext, useEffect, useRef, useState } from "react";
import { ConfigContext } from "@/context/config-context";
import './company-logo.scss';
import axios from "axios";
import getToken from "@/general/token-retriever";
import { useMsal } from "@azure/msal-react";
import { Placeholder } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import toast from "react-hot-toast";

type CompanyLogoProps = {
    editable?: boolean;
    width?: string;
    height?: string;
    companyId: string;
};

function CompanyLogo({ editable = true, width = "100%", height = "100%", companyId }: CompanyLogoProps) {

    var configContext = useContext(ConfigContext);
    const msalContext = useMsal();
    const logoImageRef = useRef(null);
    const [logo, setLogo] = useState(null);
    const { t } = useTranslation();
    const { data, isLoading, error, mutate } = useSWR(companyId ? configContext.configBody.api.endpoints.company.logo.replace('{id}', companyId) : null, { shouldRetryOnError: false });

    useEffect(() => {
        const logoImage = logoImageRef.current;
        const logoWrapper = logoImage?.parentElement;

        if (logoImage) {
            logoImage.onload = () => {
                const aspectRatio = logoImage.naturalWidth / logoImage.naturalHeight;

                if (aspectRatio >= 0.8 && aspectRatio <= 1.2) {
                    logoImage.classList.add('cover');
                    logoWrapper.classList.remove('bg-wit');
                } else {
                    logoImage.classList.remove('cover');
                    logoWrapper.classList.add('bg-wit');
                }
            };
        }
    }, [logo]);

    useEffect(() => {

        if (data) {
            setCompanyLogo(data);
        }

    }, [data]);

    const handleFileChange = async (e) => {

        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        const file = e.target.files[0];

        if (!file.type.startsWith('image/')) {
            toast.error(t('company:toasts:uploadLogoInvalidFileType'));
            return;
        }

        const endpoint = configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.company.logo.replace('{id}', companyId);
        const token = await getToken(msalContext, configContext);

        await axios.post(endpoint, file, {
            headers: {
                'Authorization': token,
                'Content-Type': file.type,
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey,
            }
        })
            .then((response) => {
                mutate();
                toast.success(t('company:toasts:uploadLogoSuccess'));
                return response.data;
            })
            .catch(error => {
                toast.error(t('company:toasts:uploadLogoFailed'));
                console.error('Error uploading image:', error);
            });
    };

    const setCompanyLogo = async (imageBlob) => {

        if (imageBlob !== undefined && imageBlob.size > 0) {

            const imageObjectURL = URL.createObjectURL(imageBlob);
            setLogo(imageObjectURL);
        }
    };

    return (
        <>
            <div className="logo-container">
                <label className="logo" style={{ width: width, height: height }}>

                    {isLoading
                        ? <Placeholder as="div" animation="glow">
                            <Placeholder className='placeholder' style={{ width: width, height: height }} />
                        </Placeholder>
                        : logo
                            ? <div className={`logo-wrapper ${editable ? 'editable' : ''}`} style={{ width: width, height: height }}>
                                <img ref={logoImageRef} src={logo} alt="Logo of company" />
                            </div>
                            : <div className="no-logo" style={{ width: width, height: height }}>
                                <span className="text-uppercase font-bold">logo</span>
                            </div>
                    }

                    {!isLoading && editable &&
                        <div className={`upload-button ${editable ? 'editable' : ''}`}>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                className="file-input"
                            />
                            <i className="fa-solid fa-pen text-donkerblauw" />
                        </div>
                    }
                </label>
            </div>
        </>
    );

}

export default CompanyLogo;