import { ImageSelectionMeasurementAnswer, ImageSelectionMeasurementDefinition, ImageSelectionQuestion } from "@/measurements/model/image-selection-measurement-definition";
import { MeasurementAnswer } from "@/measurements/model/measurement-definition";
import { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import './image-selection-measurement.scss';

type ImageSelectionMeasurementProps = {
    measurement: ImageSelectionMeasurementDefinition;
    onSave: (data: MeasurementAnswer) => void;
}

function ImageSelectionMeasurement({ measurement, onSave }: ImageSelectionMeasurementProps) {

    const [currentQuestion, setCurrentQuestion] = useState<ImageSelectionQuestion>(measurement.questions[0]);
    const [answer, setAnswer] = useState<ImageSelectionMeasurementAnswer>({ type: measurement.type, answers: [] });
    const [selectedValue, setSelectedValue] = useState<string>("");

    useEffect(() => {
        setSelectedValue("");
    }, [currentQuestion]);

    const nextQuestion = () => {

        const index = measurement.questions.indexOf(currentQuestion);
        if (index < measurement.questions.length - 1) {
            setCurrentQuestion(measurement.questions[index + 1]);
        }
    }

    const updateAnswer = (value: string) => {

        const answer = { selectionId: currentQuestion.id, value: value };

        setAnswer((prevAnswers) => {
            const index = prevAnswers.answers.findIndex((selection) => selection.selectionId === answer.selectionId);
            if (index > -1) {
                prevAnswers.answers[index] = answer;
            } else {
                prevAnswers.answers.push(answer);
            }
            return { ...prevAnswers };
        });

        nextQuestion();
    }

    const handleSave = () => {
        onSave(answer);
    }

    return (
        <Container fluid className='measurement-wrapper bg-lichtblauw position-relative'>
            <Container className="image-selection-measurement py-5">
                <h1 className="h4 font-medium pb-5">Nu bezig met: {measurement.title}</h1>
                <h3 className="text-donkerblauw font-bold pt-5">Maak een keuze</h3>
                <p className="mt-2">Vraag <span className="font-black">{measurement.questions.indexOf(currentQuestion) + 1}</span> van {measurement.questions.length}</p>
                <Row>
                    <div className="image-selection mt-5">
                        {currentQuestion.images.map((image, i) => (
                            <label key={`${measurement.id}-${i}`} htmlFor={`${measurement.id}-${i}`}>
                                <input onChange={(e) => { setSelectedValue(image.value); updateAnswer(image.value) }} checked={selectedValue === image.value} type="radio" id={`${measurement.id}-${i}`} name={image.title} value={image.value} />
                                <img width={400} src={image.url} alt={currentQuestion.title} />
                                <p className="image-title">{image.title}</p>
                            </label>
                        ))}
                    </div>
                </Row>
                {answer.answers.length === measurement.questions.length && <Button className="btn btn-primary mt-5" onClick={() => handleSave()}>Bekijk resultaat <i className="fa-solid fa-arrow-right ms-2" /></Button>}
            </Container>
        </Container>
    )
}

export default ImageSelectionMeasurement