import useProfileCompletion from '@/hooks/useProfileCompletion';
import { Card, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './company-card.scss';
import { Link } from 'react-router-dom';

function CompanyStats() {

    const { t } = useTranslation();
    const { activities, functions, favorites, statsLoading } = useProfileCompletion();

    return (
        <>
            <Card className="company-card mb-4">
                <Card.Header>
                    <span>{t('company:stats:title')}</span>
                </Card.Header>
                <Card.Body>
                    <Card.Text as="div">
                        <div className='mt-4'>
                            <Row>
                                <div className='profile-counters'>
                                    <Link to='/profile/functions' className='counter'>
                                        <div className='title font-medium mb-2'>{t('company:stats:activeFunctions')}</div>
                                        {
                                            statsLoading
                                                ? <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                : <div className='count'>{functions?.filter(f => f.enabled)?.length ?? 0}</div>
                                        }
                                    </Link>
                                    <Link to='/profile/activities' className='counter'>
                                        <div className='title font-medium mb-2'>{t('company:stats:plannedActivities')}</div>
                                        {
                                            statsLoading
                                                ? <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                : <div className='count'>{activities?.filter(a => new Date(a.date) >= new Date()).length ?? 0}</div>
                                        }
                                    </Link>
                                    <Link to='/profile/talents' className='counter'>
                                        <div className='title font-medium mb-2'>{t('company:stats:favoritedTalents')}</div>
                                        {
                                            statsLoading
                                                ? <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                : <div className='count'>{favorites?.length ?? 0}</div>
                                        }
                                    </Link>
                                </div>
                            </Row>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

export default CompanyStats;