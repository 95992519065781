import PageFooter from "@/components/footers/page-footer"
import PageHeader from "@/components/page-header/page-header"
import SwiperComponent from "@/components/swiper-component/swiper-component"
import { useDocumentTitle } from "@/hooks/useDocumentTitle"
import { t } from "i18next"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { SwiperSlide } from "swiper/react"
import './pages.scss'

function Homepage() {
    useDocumentTitle(t('documentTitles:home'));

    const testimonials = t('public:participantTestimonials', { returnObjects: true }) as any[];

    return (
        <div className="app-container">
            <div className="main-container">
                <main className="public home">

                    <PageHeader
                        title={t("public:homepage:headerTitle")}
                        imgSrc="/assets/img/header-beroep-it-infrastructuur-specialist.jpg"
                        description={
                            <>
                                {t('public:homepage:headerDescription')}<a className="link" href="https://www.werkinhetvooruitzicht.nl">www.werkinhetvooruitzicht.nl</a>
                            </>
                        }
                        link="/profile/company/add"
                        linkText={t("public:homepage:headerLinkText")}
                    />

                    <Container fluid className='bg-lichtblauw'>
                        <Container>
                            <Row className="pt-5 pb-4">
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0 position-relative">
                                    <div className="img left position-absolute">
                                        <img src="/assets/img/header-beroep-systeemarchitect.jpg" className="img-fluid" alt='' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0">
                                    <div className="textblock right p-4 p-md-5">
                                        <h2>{t("public:homepage:howItWorksTitle")}</h2>
                                        <ol>
                                            <li><Link className="link" to="/profile/company/add">{t("public:homepage:howItWorksSteps:0")}</Link> {t("public:homepage:howItWorksSteps:1")}</li>
                                            <li>{t("public:homepage:howItWorksSteps:2")}</li>
                                            <li>{t("public:homepage:howItWorksSteps:3")}</li>
                                            <li>{t("public:homepage:howItWorksSteps:4")}</li>
                                        </ol>
                                        <Link to="/profile/company/add" className="btn btn-primary">
                                            {t("public:homepage:createEmployerProfileButton")}
                                            <i className="fas fa-chevron-right ms-2" />
                                        </Link>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="pb-5 pt-4">
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0">
                                    <div className="textblock left p-4 p-md-5">
                                        <h2>{t("public:homepage:getStartedTitle")}</h2>
                                        <p className="my-4">
                                            {t("public:homepage:getStartedText:part1")} <Link className="link" to="/faq">{t("public:homepage:getStartedText:part2")}</Link>
                                            {t("public:homepage:getStartedText:part3")}
                                            <Link className="link" to="/contact">{t("public:homepage:getStartedText:part4")}</Link> {t("public:homepage:getStartedText:part5")}
                                        </p>
                                        <Link to="/faq" className="btn btn-primary">
                                            {t("public:homepage:faqButton")}
                                            <i className="fas fa-chevron-right ms-2" />
                                        </Link>
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0 position-relative">
                                    <div className="img right position-absolute">
                                        <img src="/assets/img/header-beroep-information-security-officer.jpg" className="img-fluid" alt='' />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    <Container fluid className='bg-lila py-4'>
                        <Container>
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={12}>
                                    <SwiperComponent slidesPerView={1} spaceBetween={0} scrollbar={false} loop={true}>
                                        {
                                            testimonials?.map((testimonial, index) => (
                                                <SwiperSlide key={index} className="testimonial-slide d-flex align-items-center">
                                                    {
                                                        testimonial.imgSrc &&
                                                        <>
                                                            <Col sm={12} md={6} lg={6} xl={6} className="d-flex justify-content-center align-items-center px-0 px-sm-3 mb-4 mb-md-0">
                                                                <img src={testimonial.imgSrc} alt="" className="img-fluid rounded-circle" />
                                                            </Col>
                                                            <Col sm={12} md={6} lg={4} xl={4} className="text-center text-md-start px-3 pt-0 pt-md-0 pt-xl-0">
                                                                <p className="testimonial">{testimonial.review}</p>
                                                                <p className="function text-donkerblauw mb-0 mb-lg-0 mb-xl-0">
                                                                    <span className="name">{testimonial.name}</span>
                                                                    {testimonial.function &&
                                                                        <>
                                                                            <span className="mx-2">|</span>
                                                                            <span className="function">{testimonial.function}</span>
                                                                        </>
                                                                    }
                                                                </p>
                                                            </Col>
                                                        </>
                                                    }

                                                    {
                                                        !testimonial.imgSrc &&
                                                        <Col sm={12} md={{offset: 2, span: 8}} lg={{offset: 3, span: 6}} className="no-img d-flex flex-column justify-content-center align-items-center text-center px-0 px-sm-3 pt-0 pt-md-0 pt-xl-0">
                                                            <p className="testimonial">{testimonial.review}</p>
                                                            <p className="function text-donkerblauw mb-0 mb-lg-0 mb-xl-0">
                                                                <span className="name">{testimonial.name}</span>
                                                                {testimonial.function &&
                                                                    <>
                                                                        <span className="mx-2">|</span>
                                                                        <span className="function">{testimonial.function}</span>
                                                                    </>
                                                                }
                                                            </p>
                                                        </Col>
                                                    }
                                                </SwiperSlide>
                                            ))
                                        }
                                    </SwiperComponent>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    <Container fluid className='bg-lila pt-4 pb-5'>
                        <Container>
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={12}>
                                    <div className="bg-paars px-3 py-4 py-md-5 text-center default-br">
                                        <h4 className="text-wit font-heavy pb-3">{t("public:homepage:knowMoreTitle")}</h4>
                                        <Link to="/testimonials" className="btn btn-secondary">{t("public:homepage:knowMoreButton")}<i className="fas fa-chevron-right ms-2" /></Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                </main>
                <PageFooter />
            </div>
        </div>
    )
}

export default Homepage