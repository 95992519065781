import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { MenuLogoutIcon } from "./menu-icons";

function NavLogout() {

    const { instance } = useMsal();
    const { t } = useTranslation();

    return (

        <li onClick={() => instance.logoutRedirect()} className="mb-2">
            <a role="button" tabIndex={0}>
                <MenuLogoutIcon width="25" height="25" />
                <span>{t('navigationBar:logout')}</span>
            </a>
        </li>
    )
}

export default NavLogout