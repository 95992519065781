import CompanyLogo from '@/company/components/company-logo/company-logo';
import { CompanyContext } from '@/context/company-context';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { MenuEventsIcon, MenuFunctionsIcon, MenuMeasurementsIcon, MenuMoreIcon, MenuProfileIcon, MenuSettingsIcon, MenuTalentsIcon } from './components/menu-icons';
import NavLogout from './components/nav-logout';
import './navigation-bar.scss';

function NavigationBar() {

    const { t } = useTranslation();
    const companyContext = useContext(CompanyContext);
    const [mobilePopupOpen, setMobilePopupOpen] = useState(false);

    useEffect(() => {
        // Function to close popup when clicking outside
        const closePopup = (event) => {

            // Check if the click occurred on the button to open the popup
            if (event.target.closest('.more-button')) {
                return;
            }

            // Check if the click occurred on a link within the popup
            if (event.target.closest('.mobile-popup a')) {
                setMobilePopupOpen(false);
                return;
            }

            if (!event.target.closest('.mobile-popup') && mobilePopupOpen) {
                setMobilePopupOpen(false);
            }
        };

        // Add event listener
        document.addEventListener('click', closePopup);

        // Cleanup function
        return () => {
            document.removeEventListener('click', closePopup);
        };
    }, [mobilePopupOpen]);

    useEffect(() => {
        // Add class to body when company is selected to apply extra margin because of navbar
        if (companyContext.company) {
            document.body.classList.add('navbar-margin');
        } else {
            document.body.classList.remove('navbar-margin');
        }
    }, [companyContext.company]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <aside className={`navigation-bar ${companyContext.company ?? 'd-none'}`}>

                        <nav className="navigation-bar-nav">

                            <div className='navigation-bar-user d-none d-sm-block mb-sm-5'>
                                <CompanyLogo width='48px' height='48px' editable={false} companyId={companyContext.company?.id} />
                            </div>

                            <ul className='navigation-bar-links position-relative'>
                                <li>
                                    <NavLink
                                        to={`/profile/company/${companyContext.company?.id}`}
                                        className="nav-link"
                                    >
                                        <MenuProfileIcon width="32" height="32" />
                                        <span>{t('navigationBar:company1')}</span>
                                        <span>{t('navigationBar:company2')}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/profile/functions"
                                        className="nav-link"
                                    >
                                        <MenuFunctionsIcon width="32" height="24" />
                                        <span>{t('navigationBar:functions')}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/profile/activities"
                                        className="nav-link"
                                    >
                                        <MenuEventsIcon width="32" height="30" />
                                        <span>{t('navigationBar:activities')}</span>
                                    </NavLink>
                                </li>
                                <li className='d-block d-sm-none'>
                                    <a
                                        className="nav-link more-button"
                                        onClick={() => setMobilePopupOpen(!mobilePopupOpen)}
                                    >
                                        <MenuMoreIcon width="32" height="30" />
                                        <span>{t('navigationBar:more')}</span>
                                    </a>
                                </li>
                                <div className={`mobile-popup d-none d-sm-block ${mobilePopupOpen ? 'open' : ''}`}>
                                    <li>
                                        <NavLink
                                            to="/profile/measurements"
                                            className="nav-link"
                                        >
                                            <MenuMeasurementsIcon width="32" height="30" />
                                            <span>{t('navigationBar:measurements')}</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/profile/talents"
                                            className="nav-link"
                                        >
                                            <MenuTalentsIcon width="32" height="32" />
                                            <span>{t('navigationBar:talents')}</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/profile/settings"
                                            className="nav-link"
                                        >
                                            <MenuSettingsIcon width="30" height="32" />
                                            <span>{t('navigationBar:settings')}</span>
                                        </NavLink>
                                    </li>

                                    <AuthenticatedTemplate>
                                        <NavLogout />
                                    </AuthenticatedTemplate>
                                </div>

                            </ul>
                        </nav>
                    </aside>
                </div>
            </div>
        </div>
    );
}

export default NavigationBar;
