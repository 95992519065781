import { Route, Routes } from 'react-router-dom';
import FunctionsOverview from './functions-overview';
import FunctionAdd from './components/function-add';
import FunctionEdit from './components/function-edit';
import FunctionProvider from '@/context/function-provider';
import FunctionFeedback from './components/function-feedback';

function FunctionsModule() {

    return (
        <Routes>
            <Route path="/">
                <Route index element={<FunctionsOverview />} />
                <Route path={"add"} element={
                    <FunctionProvider>
                        <FunctionAdd />
                    </FunctionProvider>
                } />
                <Route path={":id"} element={
                    <FunctionProvider>
                        <FunctionEdit />
                    </FunctionProvider>
                } />
                <Route path={":id/feedback/:feedbackId"} element={
                    <FunctionProvider>
                        <FunctionFeedback />
                    </FunctionProvider>
                } />
            </Route>
        </Routes>
    )
}

export default FunctionsModule;