import Header from "@/layout/header/header";
import { t } from "i18next";
import { Col, Container, Row } from "react-bootstrap";
import TalentPreview from "./components/talent-preview";
import './talents-overview.scss';
import TalentPlaceholder from "./components/talent-placeholder";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

function TalentsOverview() {

    useDocumentTitle(t('documentTitles:talents'));

    return (
        <div className="talents-overview">
            <Container fluid className='bg-donkergeel position-relative pb-5'>
                <Container>

                    <div className='header-img full position-absolute px-0'>
                        <img src={'/assets/img/WIHV_3D_Visual_Geel.jpg'} style={{ objectPosition: 'center center' }} alt='' />
                    </div>

                    <div className="position-relative pb-5">
                        <Header />
                    </div>

                    <Row className='position-relative first-row-after-heading-img'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6} className='d-flex flex-column text-wit'>
                            <div className="font-heavy h2 pb-5">{t('talents:overview:title')}</div>
                            <p>{t('talents:overview:description1')}</p>
                            <p>{t('talents:overview:description2')}</p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="py-5 px-0">
                <Container>
                    <Row className="gy-4">
                        <Col sm={12} md={12} lg={6} xl={4} xxl={4}>
                            <TalentPreview
                                id="fetullah-bucak"
                                name="Fetullah"
                                subtitle={t('talents:fetullah:subtitle')}
                                description={t('talents:fetullah:description')}
                                videoSrc="/assets/video/fetullah-bucak-compressed.mp4" />
                        </Col>
                        <Col sm={12} md={12} lg={6} xl={4} xxl={4}>    
                            <TalentPreview
                                id="nazmi-dere"
                                name="Nazmi"
                                subtitle={t('talents:nazmi:subtitle')}
                                description={t('talents:nazmi:description')}
                                videoSrc="/assets/video/nazmi-dere-compressed.mp4" />
                        </Col>
                        <Col sm={12} md={12} lg={6} xl={4} xxl={4}>
                            <TalentPreview
                                id="srilatha-merapally"
                                name="Srilatha"
                                subtitle={t('talents:srilatha:subtitle')}
                                description={t('talents:srilatha:description')}
                                videoSrc="/assets/video/srilatha-merapally-compressed.mp4" />
                        </Col>
                    </Row>

                    <Row className="placeholders gy-4 mt-1">
                        {Array.from({ length: 6 }).map((_, index) => (
                            <Col key={index} sm={12} md={12} lg={6} xl={4} xxl={4} className={`talent-col talent-col-${index}`}>
                                <TalentPlaceholder />
                            </Col>
                        ))}

                        <div className="locked-info">
                            <div className="d-flex justify-content-center px-5 py-4"><i className="fas fa-lock fa-xl" /></div>
                            <div className="text-center h5 font-medium px-5 pb-4">
                                <p>{t('talents:overview:lockedDescription1')}</p>
                                <p>{t('talents:overview:lockedDescription2')} <Link to="/contact">{t('talents:overview:lockedLink1')}</Link>{t('talents:overview:lockedLink2')}</p>
                            </div>
                        </div>
                    </Row>

                </Container>
            </Container>
        </div>
    )
}

export default TalentsOverview