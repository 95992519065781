import ActivitiesModule from '@/activities/activities-module';
import CompanyModule from '@/company/company-module';
import { ADErrorComponent } from '@/components/active-directory/ad-error-component';
import { ADLoadingComponent } from '@/components/active-directory/ad-loading-component';
import PageNotFound from '@/components/not-found-component/page-not-found';
import CompanyProvider from '@/context/company-provider';
import { ConfigContext } from '@/context/config-context';
import FeedbackContainer from '@/feedback/feedback-container';
import FunctionsModule from '@/functions/functions-module';
import { AuthenticatedSWRConfig } from '@/general/authenticated-swr-config';
import MeasurementsModule from '@/measurements/measurements-module';
import AboutUsPage from '@/pages/about-us';
import ContactPage from '@/pages/contact';
import FaqPage from '@/pages/faq';
import Homepage from '@/pages/home';
import InitiatorsPage from '@/pages/initiators';
import PartnersPage from '@/pages/partners';
import TestimonialsPage from '@/pages/testimonials';
import SettingsModule from '@/settings/settings-module';
import AdminModule from '@/admin/admin-module';
import TalentsModule from '@/talents/talents-module';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { useContext } from 'react';
import { Navigate, Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import PlatformLayout from './platform-layout';
import PublicLayout from './public-layout';
import TalentDevelopmentPage from '@/pages/talent-development';
import TraineeshipsPage from '@/pages/traineeships';
import WelcomePage from '@/pages/welcome';

const AppRouter = () => {

    var configContext = useContext(ConfigContext);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route>
                <Route path="/" element={<PublicLayout />}>
                    <Route path="/" index element={<Homepage />} />
                    <Route path="/testimonials" element={<TestimonialsPage />} />
                    <Route path="/faq" element={<FaqPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/about-us" element={<AboutUsPage />} />
                    <Route path="/initiators" element={<InitiatorsPage />} />
                    <Route path="/partners" element={<PartnersPage />} />
                    <Route path="/development" element={<TalentDevelopmentPage />} />
                    <Route path="/traineeships" element={<TraineeshipsPage />} />
                    <Route path="/feedback" element={<FeedbackContainer />} />
                </Route>

                <Route path="/profile/*" element={
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={{ scopes: [...configContext?.configBody?.api?.endpoints?.scopes] }}
                        errorComponent={ADErrorComponent}
                        loadingComponent={ADLoadingComponent}
                    >
                        <AuthenticatedSWRConfig>
                            <CompanyProvider>
                                <Routes>
                                    <Route path="/" element={<PlatformLayout />}>
                                        <Route path="/" index element={<Navigate to={"/profile/company/add"} />} />
                                        <Route path="/welcome" element={<WelcomePage />} />
                                        <Route path="/company/*" element={<CompanyModule />} />
                                        <Route path="/functions/*" element={<FunctionsModule />} />
                                        <Route path="/activities/*" element={<ActivitiesModule />} />
                                        <Route path="/measurements/*" element={<MeasurementsModule />} />
                                        <Route path="/talents/*" element={<TalentsModule />} />
                                        <Route path="/settings" element={<SettingsModule />} />
                                        <Route path="/admin" element={<AdminModule />} />
                                        <Route path="*" element={<PageNotFound />} />
                                    </Route>
                                </Routes>
                            </CompanyProvider>
                        </AuthenticatedSWRConfig>
                    </MsalAuthenticationTemplate>
                } />

                <Route path="*" element={<PageNotFound />} />
            </Route>
        )
    );

    return (
        <RouterProvider router={router} />
    )
}

export default AppRouter;

