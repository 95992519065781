import {ConfigContext} from '@/context/config-context';
import debounce from "debounce-promise";
import {useContext, useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import {companySearchDocument} from './company-search-document';
import './search-bar.scss';
import {useTranslation} from "react-i18next";
import getToken from "@/general/token-retriever";
import {useMsal} from "@azure/msal-react";
import {Company} from "@/company/model/company";
import {SingleValue} from "react-select/dist/declarations/src/types";
import toast from 'react-hot-toast';

type SearchBarProps = {
    selectedCompany?: Company;
    onCompanyChange: (s: companySearchDocument) => void;
}

export default function CompanySearchBar({selectedCompany, onCompanyChange}: SearchBarProps) {
    const {t} = useTranslation();
    const configContext = useContext(ConfigContext);
    const msalContext = useMsal();

    const [selectedOption, setSelectedOption] = useState<SingleValue<any>[]>([]);

    useEffect(() => {

        if (selectedCompany) {
            const selectedOptions = {
                value: {
                    name: selectedCompany.name
                } as companySearchDocument,
                label: selectedCompany.name
            };

            setSelectedOption(selectedOptions as unknown as SingleValue<any>[]);
        }
    }, [selectedCompany]);

    const handleSelectedOption = (selectedOption: any) => {

        setSelectedOption(selectedOption);

        const selectedCompany = selectedOption?.value ?? {};
        onCompanyChange(selectedCompany);
    }


    const _loadCompanySuggestions = async (inputValue: string) => {

        const token = await getToken(msalContext, configContext);
        let url = `${configContext.configBody.api.baseUrl}${configContext.configBody.api.endpoints.search.companies}`;

        // Add query parameters to url
        const params = new URLSearchParams({name: inputValue} as any);
        url += `?${params}`;

        return fetch(url, {
            credentials: "same-origin",
            method: 'GET',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey,
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Invalid response");
            })
            .then((result) => {
                return result.map((item: companySearchDocument) => {
                    return {
                        value: item,
                        label: item.name
                    };
                });
            })
            .catch(
                (error) => {
                    toast.error(t('company:toasts:kvkSearchFailed'));
                });
    }

    const loadCompanySuggestions = debounce(_loadCompanySuggestions, 300);

    return (
        <Form.Group as={Row} className='company-search mt-4'>
            <Col>
                <Form.Label>{t('company:add:labelSearch')}</Form.Label>
                <AsyncSelect
                    isMulti={false}
                    isClearable
                    placeholder={t('company:add:placeholderSearch')}
                    value={selectedOption}
                    onChange={(s) => handleSelectedOption(s)}
                    defaultOptions={false}
                    loadOptions={loadCompanySuggestions}
                    required={false}
                    hideSelectedOptions={true}
                    className='p-0'
                    noOptionsMessage={() => t('company:add:noCompanies')}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: state.isDisabled ? '' : 'none',
                            borderRadius: '26px',
                            padding: '0.5rem',
                            boxShadow: 'none'
                        }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: 'black',
                            cursor: 'pointer'
                        }),
                    }}/>
            </Col>
        </Form.Group>
    );
};