import { Company } from '@/company/model/company';
import { CompanyContext } from '@/context/company-context';
import { ConfigContext } from '@/context/config-context';
import { AttributeType } from '@/general/model/attribute-type';
import { AttributeValue } from '@/general/model/attribute-value';
import { useContext, useState } from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import './company-card.scss';
import { Link } from 'react-router-dom';
import CtaBlock from '@/components/call-to-actions/cta-block';

function CompanyIdentity() {

    const { t } = useTranslation();
    const context = useContext(CompanyContext);
    const configContext = useContext(ConfigContext);

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    const { data: coreValues, isLoading: coreValuesIsLoading, error: coreValuesError } = useSWR<AttributeValue[]>(`${configContext.configBody.api.endpoints.attributes.list}?type=${AttributeType.CompanyCoreValues}`);
    const { data: culture, isLoading: cultureIsLoading, error: cultureError } = useSWR<AttributeValue[]>(`${configContext.configBody.api.endpoints.attributes.list}?type=${AttributeType.CompanyCulture}`);
    const { data: identity, isLoading: identityIsLoading, error: identityError } = useSWR<AttributeValue[]>(`${configContext.configBody.api.endpoints.attributes.list}?type=${AttributeType.CompanyIdentity}`);

    const updateCultureTempChanges = (cultureValue: AttributeValue) => {
        const currentCulture = context.tempChanges.culture || [];

        const updatedCompany: Company = {
            ...context.tempChanges,
            culture: currentCulture.findIndex(c => c === cultureValue.id) !== -1
                ? currentCulture.filter(c => c !== cultureValue.id)
                : [...currentCulture, cultureValue.id]
        };

        context.updateChanges(updatedCompany);
    };

    const updateCoreValuesTempChanges = (coreValue: AttributeValue) => {
        const currentCoreValues = context.tempChanges.coreValues || [];
        const updatedCompany: Company = {
            ...context.tempChanges,
            coreValues: currentCoreValues.findIndex(c => c === coreValue.id) !== -1
                ? currentCoreValues.filter(c => c !== coreValue.id)
                : [...currentCoreValues, coreValue.id]
        };

        context.updateChanges(updatedCompany);
    };

    const updateIdentityValuesTempChanges = (identityValue: AttributeValue) => {
        const currentIdentity = context.tempChanges.identity || [];
        const updatedCompany: Company = {
            ...context.tempChanges,
            identity: currentIdentity.findIndex(c => c === identityValue.id) !== -1
                ? currentIdentity.filter(c => c !== identityValue.id)
                : [...currentIdentity, identityValue.id]
        };

        context.updateChanges(updatedCompany);
    };

    return (
        <Row className='d-flex gy-2 flex-row align-items-stretch'>
            <Col sm={12} md={12} lg={6} xl={4} xxl={4} className='mt-0'>
                <div className={`identity-container px-4 py-4 bg-roze`}>
                    <div className='h3 font-heavy text-paars pb-3'>{t('company:identity:coreValuesTitle')}</div>
                    <p className='mb-4'>{t('company:identity:coreValuesInfo')}</p>

                    {!context.editable && coreValues &&
                        <div className='core-values'>
                            {context.company?.coreValues?.map((id, index) => {
                                let coreValue = coreValues?.find(c => c.id === id);
                                return (
                                    <div key={index} role='button'
                                        className={`pill view`}>
                                        <div>{coreValue?.name}</div>
                                        <div className='info' role='button' tabIndex={0} onClick={(e) => { e.stopPropagation(); setSelectedValue({ name: coreValue?.name, description: coreValue?.description }); setShowInfoModal(true); }}>
                                            <i className='fas fa-info' />
                                        </div>
                                    </div>
                                )
                            })}

                            {context.company?.coreValues?.length < 3 &&
                                <CtaBlock
                                    bgColor='bg-paars' textColor='text-wit' buttonLink='/profile/measurements'
                                    text={[t('callToActions:coreValuesTest:line1'), t('callToActions:coreValuesTest:line2')]}
                                    buttonText={t('callToActions:coreValuesTest:buttonText')} />
                            }

                            {context.company?.coreValues?.length === 0 &&
                                <p className='font-bold'>{t('company:identity:noCoreValuesSelected')}</p>
                            }

                        </div>
                    }

                    {context.editable && coreValues &&
                        <>
                            <p className='font-bold text-paars mb-3'>{t('company:identity:coreValuesDescription')}</p>

                            {context.company?.coreValues?.length < 3 &&
                                <CtaBlock
                                    bgColor='bg-paars' textColor='text-wit' buttonLink='/profile/measurements'
                                    text={[t('callToActions:coreValuesTest:line1'), t('callToActions:coreValuesTest:line2')]}
                                    buttonText={t('callToActions:coreValuesTest:buttonText')} />
                            }

                            <div className='core-values edit'>
                                {coreValues?.sort((a, b) => a.name.localeCompare(b.name)).map((coreValue, index) => (
                                    <div key={index} role='button'
                                        className={`pill edit ${context.tempChanges?.coreValues && context.tempChanges?.coreValues.findIndex(c => c === coreValue.id) !== -1 ? 'selected' : ''}`}
                                        onClick={() => updateCoreValuesTempChanges(coreValue)}>
                                        <div>{coreValue.name}</div>
                                        <div className='info' role='button' tabIndex={0} onClick={(e) => { e.stopPropagation(); setSelectedValue({ name: coreValue.name, description: coreValue.description }); setShowInfoModal(true); }}>
                                            <i className='fas fa-info' />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    }

                    {
                        coreValuesIsLoading &&
                        <div className='d-flex flex-column justify-content-center align-items-center h-100 mt-4'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">{t('general:retrievingData')}</span>
                            </Spinner>
                            <span className='mt-3'>{t('general:retrievingData')}</span>
                        </div>
                    }

                    {
                        coreValuesError &&
                        <div className='d-flex flex-column justify-content-center align-items-center h-100 mt-4'>
                            <span className='text-donkerblauw'>{t('company:identity:retrievingAttributesFailed')}</span>
                        </div>
                    }
                </div>
            </Col>

            <Col sm={12} md={12} lg={6} xl={4} xxl={4} className='mt-3 mt-lg-0'>
                <div className={`identity-container px-4 py-4 bg-roze`}>
                    <div className='h3 font-heavy text-paars pb-3'>{t('company:identity:cultureValuesTitle')}</div>
                    <p className='mb-4'>{t('company:identity:cultureValuesInfo')}</p>

                    {!context.editable && culture &&
                        <div className='culture'>
                            {
                                context.company?.culture?.map((id, index) => {
                                    let cultureValue = culture?.find(c => c.id === id);
                                    return (
                                        <div key={index} role='button'
                                            className={`pill view`}>
                                            <div>{cultureValue?.name}</div>
                                            <div className='info' role='button' tabIndex={0} onClick={(e) => { e.stopPropagation(); setSelectedValue({ name: cultureValue?.name, description: cultureValue?.description }); setShowInfoModal(true); }}>
                                                <i className='fas fa-info' />
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            {context.company?.culture?.length === 0 &&
                                <p className='font-bold'>{t('company:identity:noCultureValuesSelected')}</p>
                            }
                        </div>
                    }

                    {context.editable && culture &&
                        <>
                            <p className='font-bold text-paars mb-3'>{t('company:identity:cultureValuesDescription')}</p>
                            <div className='culture edit'>
                                {culture?.sort((a, b) => a.name.localeCompare(b.name)).map((cultureValue, index) => (
                                    <div
                                        className={`pill edit ${context.tempChanges?.culture && context.tempChanges?.culture.findIndex(c => c === cultureValue.id) !== -1 ? 'selected' : ''}`}
                                        key={index}
                                        role='button'
                                        onClick={() => updateCultureTempChanges(cultureValue)}>
                                        <div>{cultureValue.name}</div>
                                        <div className='info' role='button' tabIndex={0} onClick={(e) => { e.stopPropagation(); setSelectedValue({ name: cultureValue.name, description: cultureValue.description }); setShowInfoModal(true); }}>
                                            <i className='fas fa-info' />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    }

                    {
                        cultureIsLoading &&
                        <div className='d-flex flex-column justify-content-center align-items-center h-100 mt-4'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">{t('general:retrievingData')}</span>
                            </Spinner>
                            <span className='mt-3'>{t('general:retrievingData')}</span>
                        </div>
                    }

                    {
                        cultureError &&
                        <div className='d-flex flex-column justify-content-center align-items-center h-100 mt-4'>
                            <span className='text-donkerblauw'>{t('company:identity:retrievingAttributesFailed')}</span>
                        </div>
                    }
                </div>
            </Col>

            <Col sm={12} md={12} lg={6} xl={4} xxl={4} className='mt-3 mt-xl-0'>
                <div className={`identity-container px-4 py-4 bg-roze`}>
                    <div className='h3 font-heavy text-paars pb-3'>{t('company:identity:identityValuesTitle')}</div>
                    <p className='mb-4'>{t('company:identity:identityValuesInfo')}</p>

                    {!context.editable && identity &&
                        <div className='identity-values'>
                            {
                                context.company?.identity?.map((id, index) => {
                                    let identityValue = identity?.find(c => c.id === id);
                                    return (
                                        <div key={index} role='button'
                                            className={`pill view`}>
                                            <div>{identityValue?.name}</div>
                                            <div className='info' role='button' tabIndex={0} onClick={(e) => { e.stopPropagation(); setSelectedValue({ name: identityValue?.name, description: identityValue?.description }); setShowInfoModal(true); }}>
                                                <i className='fas fa-info' />
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            {context.company?.identity?.length === 0 &&
                                <p className='font-bold'>{t('company:identity:noIdentityValuesSelected')}</p>
                            }
                        </div>
                    }

                    {context.editable && identity &&
                        <>
                            <p className='font-bold text-paars mb-3'>{t('company:identity:identityValuesDescription')}</p>
                            <div className='identity-values edit'>
                                {identity?.sort((a, b) => a.name.localeCompare(b.name)).map((identityValue, index) => (
                                    <div
                                        className={`pill edit ${context.tempChanges?.identity && context.tempChanges?.identity.findIndex(c => c === identityValue.id) !== -1 ? 'selected' : ''}`}
                                        key={index}
                                        role='button'
                                        onClick={() => updateIdentityValuesTempChanges(identityValue)}>
                                        <div>{identityValue.name}</div>
                                        <div className='info' role='button' tabIndex={0} onClick={(e) => { e.stopPropagation(); setSelectedValue({ name: identityValue.name, description: identityValue.description }); setShowInfoModal(true); }}>
                                            <i className='fas fa-info' />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    }

                    {
                        identityIsLoading &&
                        <div className='d-flex flex-column justify-content-center align-items-center h-100 mt-4'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">{t('general:retrievingData')}</span>
                            </Spinner>
                            <span className='mt-3'>{t('general:retrievingData')}</span>
                        </div>
                    }

                    {
                        identityError &&
                        <div className='d-flex flex-column justify-content-center align-items-center h-100 mt-4'>
                            <span className='text-donkerblauw'>{t('company:identity:retrievingAttributesFailed')}</span>
                        </div>
                    }
                </div>
            </Col>

            <Modal className='wihv-modal' centered show={showInfoModal} onHide={() => setShowInfoModal(false)}>
                <Modal.Header closeButton>
                    <div className='h3 text-donkerblauw'>{t('general:meaning')}</div>
                </Modal.Header>
                <Modal.Body>
                    <p className='py-3 px-2'><span className='font-bold'>{selectedValue?.name}:</span> {selectedValue?.description}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary-outline" onClick={() => setShowInfoModal(false)}>{t('general:close')}</button>
                </Modal.Footer>
            </Modal>
        </Row>
    );
}

export default CompanyIdentity;