import { Route, Routes } from 'react-router-dom';
import MeasurementsOverview from './measurements-overview';
import MeasurementContainer from './components/measurement-container';
import MeasurementResultContainer from './components/measurement-result';

function MeasurementsModule() {

    return (
        <Routes>
            <Route path="/">
                <Route index element={<MeasurementsOverview />} />
                <Route path={":measurementId"} element={<MeasurementContainer />} />
                <Route path={":measurementId/results"} element={<MeasurementResultContainer />} />
            </Route>
        </Routes>
    )
}

export default MeasurementsModule;