import SkillsSelection from '@/components/skills-selection/skills-selection';
import { DataSourceType, FunctionDataSource } from '@/functions/model/function-data-source';
import { Knowledge } from '@/functions/model/knowledge';
import { Skills } from '@/functions/model/skills';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Feedback } from './models/feedback';
import { FeedbackEmployerFunction } from './models/feedback-employer-function';
import { t } from 'i18next';

type FeedbackSkillSelectionProps = {
    employerfunction: FeedbackEmployerFunction;
    sources: FunctionDataSource[];
    isSubmitting: boolean;
    saveEmployerFunctionFeedback: (feedback: Feedback) => void;
}

function FeedbackSkillSelection({ employerfunction, sources, isSubmitting, saveEmployerFunctionFeedback }: FeedbackSkillSelectionProps) {

    const [feedback, setFeedback] = useState<Feedback>(employerfunction.feedbacks[0]);

    const handleOnSkillsChange = (skills: Skills) => {
        setFeedback(prevFeedback => ({ ...prevFeedback, skills: skills }));
    }

    const handleOnKnowledgeChange = (knowledge: Knowledge) => {
        setFeedback(prevFeedback => ({ ...prevFeedback, knowledge: knowledge }));
    }

    return (
        <div className='position-relative'>

            <Row>
                <Col sm={12} md={12} lg={6} xl={6} xxl={6} className='d-flex flex-column text-wit pb-5'>
                    <div className="font-heavy h2 pb-3">{t('feedback:title')}</div>
                    <p>{t('feedback:description', { name: employerfunction.createdBy, functionName: employerfunction.name })}</p>
                </Col>
            </Row>

            <SkillsSelection
                type='feedback'
                sources={sources}
                skills={feedback.skills}
                knowledge={feedback.knowledge}
                onSkillsChange={handleOnSkillsChange}
                onKnowledgeChange={handleOnKnowledgeChange} />

            <Row className='d-flex justify-content-center pt-5'>
                <button className="button" disabled={isSubmitting} onClick={() => saveEmployerFunctionFeedback(feedback)}>
                    {t('feedback:saveBtn')}
                </button>
            </Row>
        </div>
    )
}

export default FeedbackSkillSelection