import { t } from 'i18next';
import { Col, Container, Row } from 'react-bootstrap';
import './error-component.scss';

type ErrorComponentProps = {
    message?: string;
}

export function ErrorComponent({ message }: ErrorComponentProps) {

    return (
        <Container fluid className='error-info pb-5'>
            <Container>

                <div className='header-img full position-absolute px-0'>
                    <img src={'/assets/img/WIHV_3D_Visual_Rood_Wit.jpg'} style={{ objectPosition: 'center center' }} alt='' />
                </div>

                <div className='error-info__logo'>
                    <a href='/'>
                        <img src={`/assets/img/werk-in-het-vooruitzicht-diapositief.svg`} alt='' />
                    </a>
                </div>

                <Row>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6} className='error-info__content'>
                        <div className="font-heavy h1 pb-3">{t('general:errorTitle')}</div>
                        <p className="pb-4">{t('general:errorDescription')}</p>
                        {
                            message &&
                            <p className='pb-4 font-bold'>{message}</p>
                        }
                        <a className="btn btn-primary-outline" onClick={() => window.location.reload()}>{t('general:errorBtn')}</a>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
