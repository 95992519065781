import NavigationBlocker from "@/components/navigation-blocker/navigation-blocker";
import ProfessionMultiSearchBar from "@/components/profession-search/profession-multi-search-bar";
import { ProfessionSearchDocument } from "@/components/profession-search/profession-search-document";
import ProfessionSingleSearchBar from "@/components/profession-search/profession-single-search-bar";
import VacancyUpload from "@/components/vacancy-upload/vacancy-upload";
import { FunctionContext } from "@/context/function-context";
import useDataSources from "@/hooks/useDataSources";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import Header from "@/layout/header/header";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { EmployerFunction } from "../model/employer-function";
import { FunctionDataSource } from "../model/function-data-source";
import { FunctionProfession } from "../model/function-profession";
import { WorkLocation } from "../model/work-location";
import FunctionPositions from "./function-additional-info";
import FunctionCertifications from "./function-certifications";
import './function-edit.scss';
import FunctionLanguages from "./function-languages";
import FunctionPrimaryEmploymentConditions from "./function-primary-employment-conditions";
import FunctionRequirements from "./function-requirements";
import FunctionSecondaryEmploymentConditions from "./function-secondary-employment-conditions";
import FunctionSkills from "./function-skills";
import FunctionValidators from "./function-validators";

function FunctionAdd() {

    useDocumentTitle(t('documentTitles:functionAdd'));

    const functionContext = useContext(FunctionContext);
    const navigate = useNavigate();
    const { sources } = useDataSources(functionContext.tempChanges?.professionIds);
    const [vacancyDataSource, setVacancyDataSource] = useState<FunctionDataSource>(undefined);

    useEffect(() => {

        // init new function
        functionContext.updateEmployerFunction(
            {
                numberOfOpenPositions: 1,
                workLocation: WorkLocation.Office,
                professionIds: [],
                employmentConditions: {
                    contractType: 0,
                    probationDurationInMonths: 1
                }
            } as EmployerFunction);
        functionContext.updateEditable(true);
    }, []);

    useEffect(() => {

        if (!functionContext.employerFunction?.id) { return; }
        //navigate after 100ms to prevent navigation blocker
        setTimeout(() => navigate(`/profile/functions/${functionContext.employerFunction.id}`), 100);

    }, [functionContext.employerFunction]);

    const handleProfessionChange = (profession: FunctionProfession) => {

        if (!profession) {
            const updatedFunction: EmployerFunction = {
                ...functionContext.tempChanges,
                name: '',
                customName: false,
                professionIds: []
            };

            functionContext.updateChanges(updatedFunction);
            return;
        }

        const updatedFunction: EmployerFunction = {
            ...functionContext.tempChanges,
            name: profession.name,
            customName: profession.professionId ? false : true,
            professionIds: profession.professionId ? [profession.professionId] : []
        };

        functionContext.updateChanges(updatedFunction);
    }

    const handleProfessionsChange = (professions: ProfessionSearchDocument[]) => {
        updateTempChanges('professionIds', professions?.map((p) => p.id));
    }

    const updateTempChanges = (propertyName, newValue) => {
        const updatedFunction: EmployerFunction = {
            ...functionContext.tempChanges,
            [propertyName]: newValue
        };

        functionContext.updateChanges(updatedFunction);
    };

    const handleSaveFunction = () => {
        functionContext.saveEmployerFunction(functionContext.tempChanges)
            .catch(() => { });
    }

    return (
        <div className="function-container">
            <Container fluid className="bg-oranje position-relative pb-5">
                <Container>

                    <div className='header-function-img'>
                        <img src='https://images.pexels.com/photos/3184360/pexels-photo-3184360.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' className='img-fluid d-none d-xl-block' alt='' />
                    </div>

                    <div className="position-relative pb-5">
                        <Header />
                    </div>

                    <Row className='position-relative'>
                        <Col sm={12} md={12} lg={12} xl={6} xxl={6} className='d-flex flex-column text-wit'>

                            <div className='h3 font-bold'>
                                {t('functions:add:title')}
                            </div>

                            <ProfessionSingleSearchBar onProfessionChange={(s) => { handleProfessionChange(s) }} />

                            {
                                functionContext.tempChanges?.name && functionContext.tempChanges?.customName &&
                                <ProfessionMultiSearchBar onProfessionsChange={(s) => handleProfessionsChange(s)} />
                            }

                            <Form.Group as={Row} className="mt-4">
                                <Col>
                                    <Form.Label className={`${!functionContext.editable && 'no-edit'}`}>{t('functions:detail:labelDescription')}</Form.Label>
                                    {functionContext.editable ?
                                        <Form.Control as="textarea" rows={6} placeholder={t('functions:detail:placeholderDescription')} value={functionContext.tempChanges?.description ?? ''} onChange={e => updateTempChanges('description', e.target.value)} />
                                        : <div>{functionContext.employerFunction?.description}</div>
                                    }
                                </Col>
                            </Form.Group>

                            <VacancyUpload
                                dataSourceRetrieved={(e) => { setVacancyDataSource(e); updateTempChanges('vacancyId', e?.id) }}
                                vacancyDescriptionChanged={(s) => updateTempChanges('vacancyDescription', s)} />

                            <div className='actions mt-4 d-flex d-md-block justify-content-center'>
                                <ButtonGroup>
                                    <>
                                        <Button variant="zwartblauw" onClick={() => { navigate('/profile/functions') }}>
                                            <i className="fas fa-xmark me-2"></i>
                                            <span>{t('general:cancel')}</span>
                                        </Button>

                                        <Button variant="zwartblauw" disabled={functionContext.requestInProgress || !functionContext.tempChanges?.name} onClick={() => handleSaveFunction()}>
                                            <i className="fas fa-floppy-disk me-2"></i>
                                            <span>{t('general:save')}</span>
                                        </Button>
                                    </>
                                </ButtonGroup>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </Container>

            {
                !functionContext.tempChanges?.name &&
                <Container fluid className="skills bg-geel py-4">
                    <Container>
                        <div className="font-heavy h4 text-center">{t('functions:detail:skills:lockedDescription')}</div>
                        <div>{functionContext.tempChanges?.name}</div>
                    </Container>
                </Container>
            }

            <div className={`position-relative ${functionContext.tempChanges?.name ? '' : 'locked'}`}>

                <Container fluid className="skills bg-blauw py-5">
                    <Container>
                        <FunctionSkills sources={vacancyDataSource ? [...sources, vacancyDataSource] : sources} />
                        <FunctionValidators />
                    </Container>
                </Container>

                <Container fluid className="bg-blauw pb-5">
                    <Container>
                        <Row>
                            <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <FunctionPrimaryEmploymentConditions />
                                <FunctionRequirements />
                                <FunctionPositions />
                            </Col>
                            <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <FunctionSecondaryEmploymentConditions />
                                <FunctionLanguages />
                                <FunctionCertifications />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>

            <NavigationBlocker shouldBlock={{ obj1: functionContext.employerFunction, obj2: functionContext.tempChanges }} />
        </div>
    )
}

export default FunctionAdd