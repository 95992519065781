import { Activity } from "@/company/model/activity";
import EmployerActivityCard from "@/components/cards/employer-activity-card";
import { ConfigContext } from "@/context/config-context";
import useAuthenticatedDelete from "@/hooks/useAuthenticatedDelete";
import useAuthenticatedPost from "@/hooks/useAuthenticatedPost";
import Header from "@/layout/header/header";
import dayjs from 'dayjs';
import { t } from "i18next";
import { createRef, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import useSWR from "swr";
import './activities-overview.scss';
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

function ActivitiesOverview() {

    useDocumentTitle(t('documentTitles:activities'));

    var configContext = useContext(ConfigContext);
    const { postRequest } = useAuthenticatedPost();
    const deleteRequest = useAuthenticatedDelete();

    // Add activity
    const [showAddActivityModal, setShowAddActivityModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [activity, setActivity] = useState<Activity>({} as Activity);
    const formRef = createRef<HTMLFormElement>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Activities grid
    const [searchTerm, setSearchTerm] = useState('');
    const [upcomingFilter, setUpcomingFilter] = useState(true);
    const [completedFilter, setCompletedFilter] = useState(false);
    const [filteredActivities, setFilteredActivities] = useState<Activity[]>([]);

    const [activities, setActivities] = useState<Activity[]>([]);
    const { data, mutate } = useSWR(configContext.configBody.api.endpoints.activities.list);

    useEffect(() => {

        if (!data) return;
        setActivities(data);

    }, [data]);

    useEffect(() => {
        sortAndFilterActivities();
    }, [activities, upcomingFilter, completedFilter, searchTerm]);

    const handleSaveActivity = () => {
        setValidated(true);
        if (formRef.current?.checkValidity()) {

            if (activity.date === undefined || activity.date === null) {
                toast.error(t('activities:toasts:missingDate'));
                return;
            }

            if (activity.startTime === undefined || activity.startTime === null) {
                toast.error(t('activities:toasts:missingStartTime'));
                return;
            }

            if (activity.endTime === undefined || activity.endTime === null) {
                toast.error(t('activities:toasts:missingEndTime'));
                return;
            }

            setIsSubmitting(true);

            postRequest(configContext.configBody.api.endpoints.activities.list, activity)
                .then((response) => {
                    toast.success(t('activities:toasts:saveSuccess'));
                    setShowAddActivityModal(false);
                    mutate();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(t('activities:toasts:saveFailed') + ' ' + error.message);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    }

    const handleDelete = (activity: Activity) => {
        deleteRequest(configContext.configBody.api.endpoints.activities.detail.replace('{id}', activity.id))
            .then((response) => {
                toast.success(t('activities:toasts:deleteSuccess'));
                mutate();
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('activities:toasts:deleteFailed') + ' ' + error.message);
            });
    }

    const updateActivity = (propertyName, newValue) => {
        const updatedActivity: Activity = {
            ...activity,
            [propertyName]: newValue
        };
        setActivity(updatedActivity);
    }

    const openActivityModal = (activity?: Activity) => {
        setValidated(false);
        setActivity(activity ? activity : {} as Activity);
        setShowAddActivityModal(true);
    }

    const sortAndFilterActivities = () => {

        let activitiesResult = [...activities];

        if (searchTerm) {
            activitiesResult = activitiesResult.filter((activity) =>
                activity.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        activitiesResult = activitiesResult.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        activitiesResult = activitiesResult.filter((activity) => {
            if (upcomingFilter && completedFilter) {
                return true;
            } else if (upcomingFilter) {
                return new Date(activity.date).getTime() >= new Date().getTime();
            } else if (completedFilter) {
                return new Date(activity.date).getTime() < new Date().getTime();
            }
            return false;
        });

        setFilteredActivities(activitiesResult);
    }

    return (
        <div className="acitvities-overview">
            <Container fluid className='bg-oranje position-relative pb-5'>
                <Container>

                    <div className='header-img full position-absolute px-0'>
                        <img src={'/assets/img/WIHV_3D_Visual_Rood_Wit.jpg'} style={{ objectPosition: 'center center' }} alt='' />
                    </div>

                    <div className="position-relative pb-5">
                        <Header />
                    </div>

                    <Row className='position-relative first-row-after-heading-img'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6} className='d-flex flex-column text-wit'>
                            <div className="font-heavy h2 pb-5">{t('activities:overview:title')}</div>
                            <p className='flex-grow-1'>
                                {t('activities:overview:description')}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="py-5 position-relative">
                <Container>
                    <div className="background-colored-block"></div>
                    <Row className="position-relative">
                        <Col sm={12} md={12} lg={12} xl={12} xxl={12} className='d-flex justify-content-between align-items-center mb-5 flex-column flex-lg-row'>
                            <div className="font-heavy h2 text-geel">
                                {t('activities:overview:gridTitle')}
                            </div>

                            <div className="filters mt-3 mt-lg-0">
                                <div className='filter-toggle'>
                                    <button
                                        onClick={() => { setUpcomingFilter(!upcomingFilter) }}
                                        className={`${upcomingFilter ? 'selected' : ''}`}>
                                        {t('activities:overview:toggleUpcoming')}
                                    </button>
                                </div>
                                <div className='filter-toggle'>
                                    <button
                                        onClick={() => { setCompletedFilter(!completedFilter) }}
                                        className={`${completedFilter ? 'selected' : ''}`}>
                                        {t('activities:overview:toggleCompleted')}
                                    </button>
                                </div>
                            </div>

                            <div className="d-flex mt-3 mt-lg-0">
                                <Form.Control type="text" placeholder={t('activities:overview:placeholderGridSearch')} value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} />
                            </div>
                        </Col>
                    </Row>

                    <div className="activities-container pb-5 z-1 position-relative">
                        <Row className="gy-4">
                            <Col sm={12} md={6} lg={4} xl={3} xxl={3}>
                                <div tabIndex={0} className="add-activity-card" role="button" onClick={() => openActivityModal()}>
                                    <div className="add-activity-icon">
                                        <i className="fas fa-plus fa-2x"></i>
                                    </div>
                                    <div className="add-activity-text">
                                        <span className="font-medium">{t('activities:overview:addActivity')}</span>
                                    </div>
                                </div>
                            </Col>

                            {
                                filteredActivities?.map((activity) => (
                                    <Col sm={12} md={6} lg={4} xl={3} xxl={3} key={activity.id}>
                                        <EmployerActivityCard activity={activity} onEdit={() => openActivityModal(activity)} onDelete={() => handleDelete(activity)} />
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                </Container>
            </Container>

            <Modal className="add-activity-modal wihv-modal" show={showAddActivityModal} onHide={() => setShowAddActivityModal(false)} backdrop="static" centered>
                <Modal.Header>
                    <div className='h3 text-donkerblauw'>{t('activities:add:title')}</div>
                </Modal.Header>

                <Modal.Body as={Container}>
                    <Form ref={formRef} validated={validated} noValidate>


                        <Form.Group as={Row}>
                            <Col>
                                <Form.Label>{t('activities:add:labelName')}<span className='required'>*</span></Form.Label>
                                <Form.Control type="text" placeholder="" autoComplete="off" required value={activity?.name ?? ''} onChange={e => updateActivity('name', e.target.value)} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-3">
                            <Col>
                                <Form.Label>{t('activities:add:labelDescription')}<span className='required'>*</span></Form.Label>
                                <Form.Control as="textarea" rows={5} placeholder="" autoComplete="off" required value={activity?.description ?? ''} onChange={e => updateActivity('description', e.target.value)} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-3">
                            <Col>
                                <Form.Label>{t('activities:add:labelLocation')}<span className='required'>*</span></Form.Label>
                                <Form.Control type="text" placeholder="" autoComplete="off" required value={activity?.location ?? ''} onChange={e => updateActivity('location', e.target.value)} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-3">
                            <Col>
                                <Form.Label>{t('activities:add:labelDate')}<span className='required'>*</span></Form.Label>
                                <Form.Control type="date" name="date" placeholder="" autoComplete="off" required
                                    defaultValue={activity?.date ? dayjs(activity.date).format('YYYY-MM-DD') : ''}
                                    onBlur={(e) => { if (e.target.value) updateActivity('date', e.target.value) }} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-3">
                            <Col>
                                <Form.Label>{t('activities:add:labelStartTime')}<span className='required'>*</span></Form.Label>
                                <Form.Control type="time" name="startTime" required
                                    defaultValue={activity?.startTime ? dayjs(activity?.startTime).format('HH:mm') : ''}
                                    onBlur={(e) => { updateActivity('startTime', e.target.value) }} />
                            </Col>
                            <Col>
                                <Form.Label>{t('activities:add:labelEndTime')}<span className='required'>*</span></Form.Label>
                                <Form.Control type="time" name="endTime"
                                    min={activity?.startTime ? dayjs(activity?.startTime).format('HH:mm') : ''}
                                    defaultValue={activity?.endTime ? dayjs(activity?.endTime).format('HH:mm') : ''}
                                    onBlur={(e) => { updateActivity('endTime', e.target.value) }} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-3">
                            <Col>
                                <Form.Label>{t('activities:add:labelLink')}</Form.Label>
                                <Form.Control type="text" placeholder="" autoComplete="off" value={activity?.link ?? ''} onChange={e => updateActivity('link', e.target.value)} />
                            </Col>
                        </Form.Group>

                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary-outline transparent" onClick={() => setShowAddActivityModal(false)}>
                        {t('general:cancel')}
                    </Button>
                    <Button variant="secondary" disabled={isSubmitting} onClick={() => handleSaveActivity()}>
                        {t('general:save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ActivitiesOverview