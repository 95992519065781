import { EmployerFunction } from "@/functions/model/employer-function";
import { createContext } from "react";

export interface IFunctionState {
    employerFunction: EmployerFunction;
    tempChanges: EmployerFunction;
    editable: boolean;
    requestInProgress: boolean;
    updateEditable: (editable: boolean) => void;
    updateChanges: (employerFunction: EmployerFunction) => void;
    updateEmployerFunction: (employerFunction: EmployerFunction) => void;
    saveEmployerFunction: (employerFunction: EmployerFunction) => Promise<EmployerFunction>;
}

export const FunctionContext = createContext<IFunctionState>(undefined);