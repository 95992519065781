import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { PropsWithChildren, useContext } from 'react';
import { useNavigate } from 'react-router';
import { SWRConfig } from 'swr';
import getToken from './token-retriever';
import toast from 'react-hot-toast';
import { ConfigContext } from '@/context/config-context';
import { useTranslation } from "react-i18next";

export const AuthenticatedSWRConfig = ({ children }: PropsWithChildren) => {

    const navigate = useNavigate();
    const msalContext = useMsal();
    var configContext = useContext(ConfigContext);
    const { t } = useTranslation();

    const fetcher = async (url: string) => {

        const token = await getToken(msalContext, configContext);
        const savedLanguage = localStorage.getItem('selectedLanguage');

        const response = await axios.get(configContext.configBody.api.baseUrl + url, {
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Accept-Language': savedLanguage,
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey,
            },
            responseType: url.includes('logo') ? 'blob' : 'json'
        });

        if (response.status === 401) {
            toast.error(t('unauthorizedAccess'));
            return navigate("/not-authorized");
        }

        if (!response.data) {
            // do something with other errors?
            console.error(response);
        }

        return response.data;
    };

    return (
        <SWRConfig value={{ fetcher, revalidateOnFocus: false, revalidateIfStale: false }}>
            {children}
        </SWRConfig>
    );
};