import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/core';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import './form-measurement.scss';
import { RJSFValidationError } from '@rjsf/utils';
import { FormMeasurementAnswer, FormMeasurementDefinition } from '@/measurements/model/form-measurement-definition';
import { MeasurementAnswer } from '@/measurements/model/measurement-definition';
import { t } from 'i18next';

type SubmitButtonProps = {
    hasNextStep: boolean;
    isSubmitting: boolean;
}

function SubmitButton({ hasNextStep, isSubmitting }: SubmitButtonProps) {

    return (
        <button type='submit' disabled={isSubmitting} className='btn btn-secondary form-submit'>
            {hasNextStep ? t('measurements:measurement:nextStepBtn') : t('measurements:measurement:saveBtn')}
            {isSubmitting && <i className="fa-solid fa-spinner fa-lg fa-spin ms-2" />}
            {!isSubmitting && <i className="fas fa-chevron-right ms-2" />}
        </button>
    );
}

type FormMeasurementProps = {
    measurement: FormMeasurementDefinition;
    isSubmitting: boolean;
    onSave: (data: MeasurementAnswer) => void;
}

function FormMeasurement({ measurement, isSubmitting, onSave }: FormMeasurementProps) {

    const [answer, setAnswer] = useState<FormMeasurementAnswer>({ type: measurement.type, formData: [] });
    const [currentStep, setCurrentStep] = useState(0);
    const hasNextStep = currentStep < measurement.steps.length - 1;

    const handleNext = (formData: any) => {

        if (!formData) {
            return;
        }

        const answerFormData = [
            ...answer.formData,
            { id: measurement.steps[currentStep].id, data: formData }
        ];

        setAnswer({ ...answer, formData: answerFormData });
    }

    useEffect(() => {

        if (answer.formData.length === 0) {
            return;
        }

        if (hasNextStep) {
            setCurrentStep(currentStep + 1);
        }
        else {
            onSave(answer);
        }
        
    }, [answer]);

    return (

        <Container fluid className='measurement-wrapper bg-lichtblauw py-5'>
            <Container className='form-measurement py-5'>
                <Form
                    schema={measurement.steps[currentStep].formSchema}
                    uiSchema={measurement.steps[currentStep].uiSchema}
                    validator={validator}
                    onSubmit={(e) => handleNext(e?.formData)}
                    onError={(e) => { console.error(e) }}
                    transformErrors={transformErrors}
                    showErrorList={false}
                    focusOnFirstError={true}
                    noHtml5Validate={true}
                    children={<SubmitButton isSubmitting={isSubmitting} hasNextStep={hasNextStep} />}
                />
            </Container>
        </Container>
    )
}

export default FormMeasurement

function transformErrors(errors, uiSchema) {

    return errors.map((error: RJSFValidationError) => {
        if (error.name === 'required') {
            error.message = t('measurements:measurement:errorRequired');
        }
        if (error.name === 'maxItems') {
            error.message = t('measurements:measurement:errorMax', { limit: error.params.limit });
        }
        return error;
    });
}