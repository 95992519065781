import { Chart as ChartJS, Tooltip as ChartTooltip, Filler, Legend, LineElement, PointElement, RadialLinearScale } from 'chart.js';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Radar } from 'react-chartjs-2';
import { MeasurementResultRadarChart } from '../model/measurement-result';
import { Link } from 'react-router-dom';
import './radar-chart-result.scss';
import { t } from 'i18next';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    ChartTooltip,
    Legend
);

type RadarChartResultProps = {
    measurementResult: MeasurementResultRadarChart
}

function RadarChartResult({ measurementResult }: RadarChartResultProps) {

    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        maintainAspectRatio: true,
        scales: {
            r: {
                ticks: {
                    display: false,
                    beginAtZero: true,
                    stepSize: 5
                },
                angleLines: {
                    display: true,

                },
                startAngle: 22,
                pointLabels: {
                    font: {
                        size: 13
                    }
                }
            }
        }
    };

    const [data, setData] = useState<any>();

    useEffect(() => {
        setData(composeRenderData())
    }, []);

    const composeRenderData = () => {

        let renderData = {
            labels: measurementResult.result.map(a => a.name),
            datasets: []
        };

        renderData.datasets.push({
            label: t('measurements:results:resultChartLabel'),
            data: measurementResult.result.map(a => a.value),
            backgroundColor: 'rgba(4, 29, 183, 0.2)',
            borderColor: 'rgba(4, 29, 183, 1)',
            borderWidth: 3
        });

        return renderData;
    }

    return (
        <Row className='radar-chart-result'>
            <Col sm={12} md={12} lg={5} xl={5}>
                <h2 className='font-bold h3 mb-3'>{t('measurements:results:explanationTitle')}</h2>
                <p>{t('measurements:results:explanationChart')}</p>
                <div className='mt-4'>
                    {[...measurementResult.result]?.sort((a, b) => b.value - a.value).slice(0, 3).map((result, index) => (
                        <div key={index} className='highlighted'>
                            <span className='me-3'>{result.name}</span>
                            <span>({result.value}%)</span>
                        </div>
                    ))}
                </div>
            </Col>
            <Col sm={12} md={12} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
                {data && data.datasets &&
                    <div className='radar p-3'>
                        <Radar options={options} data={data} />
                    </div>
                }
            </Col>
        </Row>
    )
}

export default RadarChartResult