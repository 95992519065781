import { EmployerFunction } from "@/functions/model/employer-function";
import { useNavigate } from "react-router-dom";
import FunctionActiveIndicator from "../function-active-indicator/function-active-indicator";
import './employer-function-card.scss';

type FunctionCardProps = {
    employerFunction: EmployerFunction;
}

function EmployerFunctionCard({ employerFunction }: FunctionCardProps) {

    const navigate = useNavigate();

    return (
        <div className='function-card' tabIndex={0} onClick={() => { navigate(`/profile/functions/${employerFunction.id}`) }}>
            <div className="card-image">
                <img
                    src='https://images.pexels.com/photos/18999466/pexels-photo-18999466/free-photo-of-zakenman-man-laptop-zitten.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                    alt='Card Image'
                />
            </div>
            <div className="card-active">
                <FunctionActiveIndicator enabled={employerFunction.enabled} />
            </div>
            <div className='card-content'>
                <div className='card-title'>{employerFunction.name}</div>
                <p className='card-description'>{employerFunction.description}</p>
            </div>
        </div>
    )
}

export default EmployerFunctionCard