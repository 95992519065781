import { LoadingComponent } from '@/components/loading-component/loading-component';
import NavigationBlocker from '@/components/navigation-blocker/navigation-blocker';
import PageNotFound from '@/components/not-found-component/page-not-found';
import { ConfigContext } from '@/context/config-context';
import useAuthenticatedPost from '@/hooks/useAuthenticatedPost';
import { FormMeasurementDefinition } from '@/measurements/model/form-measurement-definition';
import { ImageSelectionMeasurementDefinition } from '@/measurements/model/image-selection-measurement-definition';
import { MeasurementAnswer, MeasurementDefinition } from '@/measurements/model/measurement-definition';
import { MeasurementType } from '@/measurements/model/measurement-types';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import MeasurementStart from './measurement-start';
import FormMeasurement from './measurement-types/form-measurement';
import ImageSelectionMeasurement from './measurement-types/image-selection-measurement';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

function MeasurementContainer() {

    useDocumentTitle(t('documentTitles:measurement'));

    const navigate = useNavigate();
    const { postRequest } = useAuthenticatedPost();
    const configContext = useContext(ConfigContext);
    const { measurementId } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [measurementStarted, setMeasurementStarted] = useState<boolean>(false);
    const [measurementCompleted, setMeasurementCompleted] = useState<boolean>(false);
    const { data: measurement, isLoading, error } = useSWR<MeasurementDefinition>(measurementId ? configContext.configBody.api.endpoints.measurements.detail.replace('{id}', measurementId) : null);
    const { mutate } = useSWR(configContext.configBody.api.endpoints.measurements.list);

    const handleOnSaveMeasurement = async (data: MeasurementAnswer) => {

        setIsSubmitting(true);

        postRequest(configContext.configBody.api.endpoints.measurements.answers.replace('{id}', measurementId), data)
            .then((response) => {
                setMeasurementCompleted(true);
                mutate();
                toast.success(t('measurements:toasts:saveSuccess'));
                setTimeout(() => {
                    navigate(`/profile/measurements/${measurementId}/results`, { state: { result: response } });
                }, 600);
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('measurements:toasts:saveFailed'));
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const renderMeasurement = (type: MeasurementType) => {
        switch (type) {
            case MeasurementType.Form:
                return <FormMeasurement onSave={handleOnSaveMeasurement} isSubmitting={isSubmitting} measurement={measurement as FormMeasurementDefinition} />
            case MeasurementType.ImageSelection:
                return <ImageSelectionMeasurement onSave={handleOnSaveMeasurement} measurement={measurement as ImageSelectionMeasurementDefinition} />
            default:
                return <PageNotFound />
        }
    }

    return (
        <>
            {isLoading &&
                <LoadingComponent message='Loading measurement...' />
            }

            {(measurementStarted && measurement) &&
                renderMeasurement(measurement.type)
            }

            {(measurement && !measurementStarted) &&
                <MeasurementStart measurement={measurement} onStartMeasurement={() => setMeasurementStarted(true)} />
            }

            <NavigationBlocker shouldBlock={measurementStarted && !measurementCompleted} />
        </>
    )
}

export default MeasurementContainer