import ScrollToTopNavigation from "@/components/scroll-to-top/scroll-to-top-navigation";
import useIdleDetection from "@/hooks/useIdleDetection";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import './platform-layout.scss';

function PublicLayout() {

    useIdleDetection();

    return (
        <>
            <ScrollToTopNavigation />
            <Outlet />
            <Toaster
                position="bottom-center"
                gutter={8}
                toastOptions={
                    {
                        duration: 5000,
                        success: {
                            duration: 3000,
                        },
                    }
                }
            />
        </>
    );
}

export default PublicLayout;