import { useContext, useEffect, useState } from 'react'
import { Container, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs';
import './header.scss'
import toast from 'react-hot-toast';
import BetaMessageModal from '@/components/beta-message-modal/beta-message-modal';
import { CompanyContext } from '@/context/company-context';

function Header() {

    const { i18n, t } = useTranslation();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [aboutUsIsOpen, setAboutUsIsOpen] = useState(false);
    const [showBetaInfo, setShowBetaInfo] = useState(false);
    const companyContext = useContext(CompanyContext);

    useEffect(() => {
        setAboutUsIsOpen(false);

        if (menuIsOpen) {
            document.body.classList.add('noscroll');
        } else {
            document.body.classList.remove('noscroll');
        }

    }, [menuIsOpen])

    const handleLanguageChange = (languageCode: string) => {

        if (languageCode === i18n.language) { return; };

        i18n.changeLanguage(languageCode);
        dayjs.locale(languageCode);
        localStorage.setItem('selectedLanguage', languageCode);
        toast.success(t('languages:toasts:languageChanged', { language: t(`languages:${languageCode}`) }));
    };

    return (
        <Container fluid className='header py-4 px-0 px-md-3'>
            <div>
                <div className='d-flex justify-content-between align-items-center'>

                    <Link to='/' className='position-relative'>
                        <div className='logo-banner'></div>
                        <img className='position-relative' src={`/assets/img/werk-in-het-vooruitzicht-diapositief.svg`} alt='' />
                    </Link>

                    <div className={`menu-actions ${menuIsOpen ? 'open' : ''}`}>
                        
                        {companyContext && companyContext.company &&
                            <>
                                <Link className={'header__help'} title={t('header:help')} to={'/profile/welcome'} onClick={() => { setMenuIsOpen(false) }}>
                                    <i className='fas fa-circle-question fa-xl' />
                                </Link>
                        
                                <button className={`header__warning`} title={t('header:warning')} tabIndex={0} onClick={() => { setShowBetaInfo(true) }}>
                                    <i className='fas fa-circle-exclamation fa-xl' />
                                </button>
                            </>
                        }

                        <Dropdown className="language-switch" drop="down-centered">
                            <Dropdown.Toggle className="language-toggle">
                                <span>{i18n.language}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleLanguageChange("en")}>{t('languages:en')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleLanguageChange("nl")}>{t('languages:nl')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <button className={`toggle-menu-icon`} tabIndex={0} onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            {!menuIsOpen && <i className='fas fa-bars fa-xl' />}
                            {menuIsOpen && <i className='fas fa-times fa-xl' />}
                        </button>
                    </div>
                </div>

                <div className={`main-nav d-flex align-items-center transition ${menuIsOpen ? 'open' : ''}`}>
                    <div className='main-nav-wrapper'>

                        <ul className="nav navbar-nav d-block text-wit">
                            <li className="menu-item menu-item-has-children dropdown nav-item">
                                <span role='button' aria-haspopup="true" aria-expanded="false" className="nav-dropdown nav-link" onClick={() => { setAboutUsIsOpen(true) }} tabIndex={0}>
                                    <div className='d-flex align-items-center'>
                                        <span>{t('header:about')}</span>
                                        <i className="fas fa-chevron-right fa-sm ms-2"></i>
                                    </div>
                                </span>
                                <ul className={`dropdown-menu about-us transition ${aboutUsIsOpen ? 'show' : ''}`}>
                                    <li className="close-menu menu-item nav-item">
                                        <span role='button' className="nav-link" tabIndex={0} onClick={() => { setAboutUsIsOpen(false) }}>
                                            <div className='d-flex align-items-center'>
                                                <i className="fas fa-chevron-left fa-sm me-3"></i>
                                                <span>{t('header:back')}</span>
                                            </div>
                                        </span>
                                    </li>
                                    <li className="menu-item nav-item">
                                        <Link to="/about-us" className="nav-link" tabIndex={0}>
                                            <span>{t('header:about')}</span>
                                        </Link>
                                    </li>
                                    <li className="menu-item nav-item">
                                        <Link to="/initiators" className="nav-link" tabIndex={0}>
                                            <span>{t('header:initiators')}</span>
                                        </Link>
                                    </li>
                                    <li className="menu-item nav-item">
                                        <Link to="/partners" className="nav-link" tabIndex={0}>
                                            <span>{t('header:partners')}</span>
                                        </Link>
                                    </li>
                                    <li className="menu-item nav-item">
                                        <Link to="/development" className="nav-link" tabIndex={0}>
                                            <span>{t('header:talentDevelopment')}</span>
                                        </Link>
                                    </li>
                                    <li className="menu-item nav-item">
                                        <Link to="/traineeships" className="nav-link" tabIndex={0}>
                                            <span>{t('header:traineeships')}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item nav-item">
                                <Link to="/" className="nav-link" tabIndex={0}>
                                    <span>{t('header:general')}</span>
                                </Link>
                            </li>
                            <li className="menu-item nav-item">
                                <Link to="/faq" className="nav-link" tabIndex={0}>
                                    <span>{t('header:faq')}</span>
                                </Link>
                            </li>
                            <li className="menu-item nav-item">
                                <Link to="/testimonials" className="nav-link" tabIndex={0}>
                                    <span>{t('header:experiences')}</span>
                                </Link>
                            </li>
                            <li className="menu-item nav-item">
                                <Link to="/contact" className="nav-link" tabIndex={0}>
                                    <span>{t('header:contact')}</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={`main-nav-shadow transition d-none d-md-block ${menuIsOpen ? 'show' : ''}`}></div>

                <BetaMessageModal show={showBetaInfo} onClose={() => setShowBetaInfo(false)} />

            </div>
        </Container>
    )
}

export default Header