import { Certification } from '@/company/model/certification';
import { Company } from '@/company/model/company';
import { CompanyContext } from '@/context/company-context';
import { useContext } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';

type CertificationFormProps = {
    index: number;
    tempChanges: Company;
    onUpdate: (event: any) => void;
};

const CertificationForm = ({ tempChanges, index, onUpdate }: CertificationFormProps) => {

    const { t } = useTranslation();

    const handleSelectedOption = (event: any) => {
        tempChanges.certifications[index] = event.value;
        onUpdate(tempChanges.certifications);
    }

    const handleCreatedOption = (input: string) => {
        tempChanges.certifications[index] = { name: input } as Certification;
        onUpdate(tempChanges.certifications);
    }

    const getCertifications = () => {
        return certifications.map((certification) => {
            return {
                value: certification,
                label: certification.name
            }
        });
    }

    const removeCertification = (index: number) => {
        tempChanges.certifications = tempChanges.certifications.filter((item, i) => i !== index);
        onUpdate(tempChanges.certifications);
    }

    return (
        <Row className='certification-item-form mb-3 d-flex align-items-center'>
            <Col sm={10}>
                <Form.Group as={Row}>
                    <Col>
                        <CreatableSelect
                            placeholder={t('company:certifications:placeholderSearch')}
                            className="certification-select"
                            value={tempChanges.certifications[index]?.name ? { label: tempChanges.certifications[index].name, value: tempChanges.certifications[index] } : null}
                            formatCreateLabel={(inputValue) => <span>{t('general:create')}: "<span className='font-bold'>{inputValue}</span>"</span>}
                            isClearable={false}
                            isSearchable={true}
                            options={getCertifications()}
                            onChange={(s) => handleSelectedOption(s)}
                            onCreateOption={(s) => handleCreatedOption(s)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    borderRadius: '25px',
                                    padding: '0.5rem',
                                    boxShadow: 'none'
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#0c1732',
                                }),
                            }}
                        />
                    </Col>
                </Form.Group>
            </Col>
            <Col sm={2} className='p-0'>
                <Button onClick={() => removeCertification(index)} className="btn-delete"><i className="fa-solid fa-trash fa-lg" /></Button>
            </Col>
        </Row>
    );
};

function CompanyCertifications() {

    const { t } = useTranslation();
    const context = useContext(CompanyContext);

    const updateTempChanges = (certifications: Certification[]) => {
        const updatedCompany: Company = {
            ...context.tempChanges,
            certifications: [...certifications]
        };

        context.updateChanges(updatedCompany);
    }

    const addCertification = () => {

        const updatedCompany: Company = {
            ...context.tempChanges,
            certifications: [...context.tempChanges.certifications, {} as Certification]
        };

        context.updateChanges(updatedCompany);
    }

    return (
        <>
            <Card className="company-card mb-4">
                <Card.Header>
                    <span>{t('company:certifications:title')}</span>
                </Card.Header>
                <Card.Body>
                    <Card.Text as="div">
                        <div className='mt-4'>

                            {context.editable ?
                                <>
                                    {(!context.tempChanges?.certifications || context.tempChanges?.certifications?.length === 0) &&
                                        <div className='field view'>{t('company:certifications:noCertifications')}</div>
                                    }
                                    <Row id='modal-items'>
                                        <Col>
                                            {
                                                context.tempChanges?.certifications?.map((item, i) => (
                                                    <CertificationForm tempChanges={context.tempChanges} index={i} onUpdate={updateTempChanges} key={i} />
                                                ))
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='mt-4'>
                                            <Button onClick={addCertification} className="btn btn-secondary"><i className="fa-solid fa-plus fa-lg me-2" />{t('company:certifications:addCertification')}</Button>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <Row>
                                    {(!context.company?.certifications || context.company?.certifications?.length === 0) &&
                                        <div className='field view'>{t('company:certifications:noCertifications')}</div>
                                    }
                                    {context.company?.certifications?.map((certification, index) =>
                                        <div key={index} className='field view'>{certification.name}</div>
                                    )}
                                </Row>
                            }
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

export default CompanyCertifications

const certifications: Certification[] = [
    {
        "id": "1a",
        "name": "AWS Partner Network (APN)",
        "description": "Partnerprogramma voor organisaties die AWS-cloudoplossingen aanbieden."
    },
    {
        "id": "2b",
        "name": "CIPP",
        "description": "Certificering voor privacyprofessionals, met verschillende specialisaties zoals US, Europe, en Asia."
    },
    {
        "id": "3c",
        "name": "Cisco Select, Premier of Gold Certified Partner",
        "description": "Partnercertificeringen voor netwerk- en communicatieoplossingen met Cisco-producten."
    },
    {
        "id": "4d",
        "name": "CMMI",
        "description": "Model voor procesverbetering in software- en systeemontwikkeling."
    },
    {
        "id": "5e",
        "name": "COBIT",
        "description": "Raamwerk voor IT-governance en beheersing."
    },
    {
        "id": "6f",
        "name": "CompTIA-certificeringen",
        "description": "Verschillende IT-gerelateerde certificeringen, zoals CompTIA A+, Network+, en Security+."
    },
    {
        "id": "7g",
        "name": "CSA STAR",
        "description": "Certificering voor cloudserviceproviders."
    },
    {
        "id": "8h",
        "name": "Dell Technologies Partner Program",
        "description": "Partnerprogramma voor organisaties die Dell Technologies-producten en -diensten leveren."
    },
    {
        "id": "9i",
        "name": "EUCIP",
        "description": "Europees certificeringsprogramma voor IT-professionals."
    },
    {
        "id": "10j",
        "name": "FedRAMP",
        "description": "Amerikaans programma voor cloudsecuritycertificering voor overheidsdienstverleners."
    },
    {
        "id": "11k",
        "name": "HIPAA",
        "description": "Wet voor bescherming van medische gegevens in de VS."
    },
    {
        "id": "12l",
        "name": "IBM Business Partner",
        "description": "Partnerprogramma voor organisaties die IBM-oplossingen implementeren en ondersteunen."
    },
    {
        "id": "13m",
        "name": "ISO 13485",
        "description": "Certificering voor medische apparatuur en kwaliteitsmanagementsystemen."
    },
    {
        "id": "14n",
        "name": "ISO 14001",
        "description": "Certificering voor milieubeheer."
    },
    {
        "id": "15o",
        "name": "ISO 19600",
        "description": "Certificering voor compliance management."
    },
    {
        "id": "16p",
        "name": "ISO 20000",
        "description": "Certificering voor service management, met de nadruk op IT-dienstverlening."
    },
    {
        "id": "17q",
        "name": "ISO 20000-1",
        "description": "Certificering voor IT-servicebeheer."
    },
    {
        "id": "18r",
        "name": "ISO 22000",
        "description": "Certificering voor voedselveiligheidssystemen, relevant voor IT-dienstverleners in de voedingsindustrie."
    },
    {
        "id": "19s",
        "name": "ISO 22301",
        "description": "Certificering voor business continuity management."
    },
    {
        "id": "20t",
        "name": "ISO 22301",
        "description": "Certificering voor bedrijfscontinuïteit en crisisbeheer."
    },
    {
        "id": "21u",
        "name": "ISO 22316",
        "description": "Norm voor organisatorische veerkracht."
    },
    {
        "id": "22v",
        "name": "ISO 27017",
        "description": "Beveiligingscertificering voor cloud service providers."
    },
    {
        "id": "23w",
        "name": "ISO 31000",
        "description": "Certificering voor risicobeheer."
    },
    {
        "id": "24x",
        "name": "ISO 38500",
        "description": "Certificering voor IT-governance."
    },
    {
        "id": "25y",
        "name": "ISO 50001",
        "description": "Certificering voor energiemanagement."
    },
    {
        "id": "26z",
        "name": "ISO 90003",
        "description": "Aanvulling op ISO 9001 voor organisaties die software-ontwikkeling en -ondersteuning bieden."
    },
    {
        "id": "27aa",
        "name": "ISO 9001",
        "description": "Algemene kwaliteitscertificering."
    },
    {
        "id": "28ab",
        "name": "ITIL",
        "description": "Raamwerk voor IT-servicebeheer."
    },
    {
        "id": "29ac",
        "name": "Microsoft Gold Partner",
        "description": "Partnerprogramma met verschillende specialisatieniveaus voor Microsoft-technologieën."
    },
    {
        "id": "30ad",
        "name": "NIST Cybersecurity Framework",
        "description": "Raamwerk voor cybersecuritybeheer."
    },
    {
        "id": "31ae",
        "name": "Oracle Certified Partner",
        "description": "Partnerprogramma voor organisaties die Oracle-producten en -diensten aanbieden."
    },
    {
        "id": "32af",
        "name": "PCI DSS",
        "description": "Beveiligingsstandaard voor betalingstransacties."
    },
    {
        "id": "33ag",
        "name": "PRINCE2",
        "description": "Raamwerk voor projectmanagement."
    },
    {
        "id": "34ah",
        "name": "Red Hat Certified Partner",
        "description": "Partnerprogramma voor organisaties die Red Hat-oplossingen implementeren en ondersteunen."
    },
    {
        "id": "35ai",
        "name": "SAFe",
        "description": "Raamwerk voor het schalen van agile principes in grote organisaties."
    },
    {
        "id": "36aj",
        "name": "TOGAF",
        "description": "Certificering voor enterprise-architectuur."
    },
    {
        "id": "37ak",
        "name": "VMware Partner Program",
        "description": "Partnerprogramma voor organisaties die VMware-virtualisatie- en cloudoplossingen aanbieden."
    }
]
