import PageFooter from "@/components/footers/page-footer"
import PageHeader from "@/components/page-header/page-header"
import { useDocumentTitle } from "@/hooks/useDocumentTitle"
import { t } from "i18next"
import { Col, Container, Row } from "react-bootstrap"
import './pages.scss'

function TestimonialsPage() {

    const testimonials = t('public:employerTestimonials', { returnObjects: true }) as any[];
    useDocumentTitle(t('documentTitles:testimonials'));

    return (
        <div className="app-container">
            <div className="main-container">
                <main className="public testimonials">

                    <PageHeader
                        title={t('public:testimonialsPage:employerExperiences')}
                        imgSrc="/assets/img/header-beroep-gis-specialist.jpg"
                        description={t('public:testimonialsPage:description')}
                        link="/profile/company/add"
                        linkText={t('public:testimonialsPage:signUpAsEmployer')} />

                    <Container fluid className='bg-lichtblauw'>
                        <Container>
                            <Row className="py-5 gy-5 d-flex justify-content-center">
                                {
                                    testimonials?.map((review, index) => (
                                        <Col key={index} sm={12} md={6} className="px-0">
                                            <div className="testimonial-card">
                                                <div className="card-img">
                                                    <img src={review.imgSrc} alt="" className="img-fluid"/>
                                                </div>
                                                <div className="card-body">
                                                    <h2>{review.name}</h2>
                                                    <h3>{review.function}</h3>
                                                    {
                                                        review.review as string[] && review.review.map((paragraph, index) => (
                                                            <p key={index}>{paragraph}</p>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Container>
                    </Container>

                    <PageFooter />

                </main>
            </div>
        </div>
    )
}

export default TestimonialsPage