import { ConfigContext } from '@/context/config-context';
import { FunctionDataSource } from '@/functions/model/function-data-source';
import { useContext } from 'react';
import useSWR from 'swr';

const useDataSources = (professionIds: string[]) => {

    const configContext = useContext(ConfigContext);

    const { data: sources } = useSWR<FunctionDataSource[]>(`${configContext.configBody.api.endpoints.sources.list}?professionIds=${professionIds}`);

    return { sources: sources || []};
}

export default useDataSources;