import PageFooter from "@/components/footers/page-footer";
import PageHeader from "@/components/page-header/page-header";
import { Col, Container, Row } from "react-bootstrap";
import './pages.scss';
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { t } from "i18next";

function InitiatorsPage() {
    useDocumentTitle(t('documentTitles:initiators'));

    const translations = t('public:initiators', { returnObjects: true }) as any;

    return (
        <div className="app-container">
            <div className="main-container">
                <main className="public initiators">

                    <PageHeader
                        paddingHeader={false}
                        colWidth={8}
                        title={translations.title}
                        description={translations.description} />

                    <Container fluid className='bg-lichtblauw py-5'>
                        <Container>
                            <Row className="gy-4">
                                {translations.cards.map((card, index) => (
                                    <Col key={index} sm={12} className="d-flex justify-content-center">
                                        <div className="initiator-card">
                                            <div className="card-img">
                                                <img src={card.imgSrc} alt="" />
                                            </div>
                                            <div className="card-body">
                                                <h3>{card.name}</h3>
                                                <p>{card.description}</p>
                                            </div>
                                            <div className="card-footer">
                                                <a className="btn btn-primary" href={card.link} target="_blank" rel="noreferrer">
                                                    {card.linkText}
                                                    <i className="fas fa-chevron-right ms-2" />
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            <Row className="py-5">
                                <Col sm={12}>
                                    <div className="textblock p-4 p-md-5">                                        
                                        <h2>{translations.collaborationTitle}</h2>
                                        <p className="my-4">{translations.collaborationText}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </main>
                <PageFooter />
            </div>
        </div>
    );
}

export default InitiatorsPage;