import PageFooter from "@/components/footers/page-footer"
import PageHeader from "@/components/page-header/page-header"
import { Col, Container, Row } from "react-bootstrap"
import './pages.scss'
import { useDocumentTitle } from "@/hooks/useDocumentTitle"
import { t } from "i18next"
import { Link } from "react-router-dom"

const PartnersPage = () => {
    useDocumentTitle(t('documentTitles:partners'));

    const translations = t('public:partners', { returnObjects: true }) as any;

    return (
        <div className="app-container">
            <div className="main-container">
                <main className="public partners">

                    <PageHeader
                        title={translations.title}
                        imgSrc="/assets/img/header-beroepenoverzicht.jpg"
                        description={translations.description}
                    />

                    <Container fluid className='bg-lichtblauw'>
                        <Container>
                            <Row className="pt-5 pb-4 mx-0 mx-md-5 ">
                                <Col sm={12} md={12} lg={12} xl={12}>
                                    <div className="textblock full py-4 py-md-5">
                                        <h2 className="font-bold pb-4">{translations.title}</h2>
                                        <p>{translations.descriptionPart1}</p>
                                        <p>{translations.descriptionPart2}</p>
                                        <p>{translations.descriptionPart3}</p>
                                        <p>
                                            {translations.descriptionPart4}
                                            <Link to="/contact" className="text-decoration-underline">{translations.descriptionPart5}</Link>
                                        </p>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="pt-4 pb-5 mx-0 mx-md-5 ">
                                <Col sm={12} md={12} lg={12} xl={12}>
                                    <div className="textblock p-4 p-md-5 text-center">
                                        <h2 className="font-bold pb-4">{translations.partnersListTitle}</h2>
                                        <p className="font-bold pb-5">{translations.partnersListSubtitle}</p>
                                        <div className="logos">
                                            <img src="/assets/img/partners/abn-amro.svg" alt="ABN AMRO" />
                                            <img src="/assets/img/partners/coa.jpg" alt="COA" />
                                            <img src="/assets/img/partners/dictu.jpg" alt="DICTU" />
                                            <img src="/assets/img/partners/it-campus-rotterdam.svg" alt="IT Campus Rotterdam" />
                                            <img src="/assets/img/partners/motion10.svg" alt="Motion10" />
                                            <img src="/assets/img/partners/gemeente-hoorn.svg" alt="Gemeente Hoorn" />
                                            <img src="/assets/img/partners/gemeente-rotterdam.svg" alt="Gemeente Rotterdam" />
                                            <img src="/assets/img/partners/provincie-noord-holland.gif" alt="Provincie Noord-Holland" />
                                            <img src="/assets/img/partners/provincie-overijssel.svg" alt="Provincie Overijssel" />
                                            <img src="/assets/img/partners/refugee-talent-hub.jpg" alt="Refugee Talent Hub" />
                                            <img src="/assets/img/partners/roc-amsterdam.png" alt="ROC Amsterdam" />
                                            <img src="/assets/img/partners/stichting-ca-ict.svg" alt="Stichting CA-ICT" />
                                            <img src="/assets/img/partners/itperformancehouse.jpg" alt="Stichting IT Performance House" />
                                            <img src="/assets/img/partners/stichting-vluchtelingenwerk.svg" alt="Stichting VluchtelingenWerk" />
                                            <img src="/assets/img/partners/uaf.svg" alt="UAF" />
                                            <img src="/assets/img/partners/uwv.svg" alt="UWV" />
                                            <img src="/assets/img/partners/werhere.png" alt="We’RHERE" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </main>

                <PageFooter />
            </div>
        </div>
    )
}

export default PartnersPage