import PageFooter from "@/components/footers/page-footer"
import PageHeader from "@/components/page-header/page-header"
import { useDocumentTitle } from "@/hooks/useDocumentTitle"
import { t } from "i18next"
import { Col, Container, Row } from "react-bootstrap"
import './pages.scss'

function TalentDevelopmentPage() {

    useDocumentTitle(t('documentTitles:talentDevelopment'));
    const approach = t('public:talentDevelopment:approach', { returnObjects: true }) as any[];

    return (
        <div className="app-container">
            <div className="main-container">
                <main className="public talent-development">

                    <PageHeader
                        title={t("public:talentDevelopment:title")}
                        imgSrc="/assets/img/afbeelding-meeting.jpg"
                        description={t('public:talentDevelopment:description')}
                    />

                    <Container fluid className='bg-lichtblauw'>
                        <Container>
                            <Row className="pt-5 pb-4">
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0 position-relative">
                                    <div className="img left position-absolute">
                                        <img src="/assets/img/afbeelding-talent-development.jpg" className="img-fluid" alt='' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0">
                                    <div className="textblock right p-4 p-md-5">
                                        <h2 className="mb-4">{t("public:talentDevelopment:approachTitle")}</h2>
                                        <ul className="approach">
                                            {
                                                approach?.map((item: any, index: number) => {
                                                    return (
                                                        <li key={index}>
                                                            <span>{item.title}:</span>
                                                            <span>{item.description}</span>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="pb-5 pt-4">
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0">
                                    <div className="textblock left p-4 p-md-5">
                                        <h2>{t("public:talentDevelopment:coursesTitle")}</h2>
                                        <p className="my-4">
                                            {t("public:talentDevelopment:coursesDescription")}
                                        </p>
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0 position-relative">
                                    <div className="img right position-absolute">
                                        <img src="/assets/img/header-beroep-information-security-officer.jpg" className="img-fluid" alt='' />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="pb-5 pt-4">
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0 position-relative">
                                    <div className="img left position-absolute">
                                        <img src="/assets/img/afbeelding-over-ons.jpg" className="img-fluid" alt='' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0">
                                    <div className="textblock right p-4 p-md-5">
                                        <h2>{t("public:talentDevelopment:qualityMarksTitle")}</h2>
                                        <p className="my-4">
                                            {t("public:talentDevelopment:qualityMarksDescription")}
                                        </p>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="pb-5 pt-4">
                                <Col sm={12} md={12} lg={12} xl={12} className="px-0">
                                    <div className="textblock p-4 p-md-5 text-center">
                                        <h2 className="font-bold pb-4">{t("public:talentDevelopment:trainerListTitle")}</h2>
                                        <p className="pb-5 px-5">{t("public:talentDevelopment:trainerListDescription")}</p>
                                        <div className="logos">
                                            <img src="/assets/img/trainers/linkedin-learning.png" alt="LinkedIn Learning Logo"/>
                                            <img src="/assets/img/trainers/microsoft-learning.png" alt="Microsoft Learning Logo"/>
                                            <img src="/assets/img/trainers/itph-academy.svg" alt="ITPH Academy Logo"/>
                                            <img src="/assets/img/trainers/azure-academy.png" alt="Azure Academy Logo"/>
                                            <img src="/assets/img/trainers/computrain.svg" alt="Computrain Logo"/>
                                            <img src="/assets/img/trainers/clarusway.png" alt="Clarusway Logo"/>
                                            <img src="/assets/img/trainers/dirksen-opleidingen.jpg" alt="Dirksen Opleidingen Logo"/>
                                            <img src="/assets/img/trainers/hogeschool-van-amsterdam.svg" alt="Hogeschool van Amsterdam Logo"/>
                                            <img src="/assets/img/trainers/infosupport.png" alt="Infosupport Logo"/>
                                            <img src="/assets/img/trainers/global-knowledge.svg" alt="Global Knowledge Logo"/>
                                            <img src="/assets/img/trainers/roc-amsterdam.png" alt="ROC van Amsterdam Logo"/>
                                            <img src="/assets/img/trainers/techionista.svg" alt="Techionista Logo"/>                                            
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    </Container>
                </main>
                <PageFooter />
            </div>
        </div>
    )
}

export default TalentDevelopmentPage