import { Company } from '@/company/model/company';
import { CompanyContext } from '@/context/company-context';
import { useContext } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './company-card.scss';

function CompanyContactDetails() {

    const { t } = useTranslation();

    const context = useContext(CompanyContext);

    const updateTempChanges = (propertyName, newValue) => {

        const updatedCompany: Company = {
            ...context.tempChanges,
            contactDetails: {
                ...context.tempChanges.contactDetails,
                [propertyName]: newValue
            }
        };

        context.updateChanges(updatedCompany);
    };

    return (
        <>
            <Card className="company-card mb-4">
                <Card.Header>
                    <span>{t('company:contactDetails:title')}</span>
                </Card.Header>
                <Card.Body>
                    <Card.Text as="div">
                        <div className='mt-4'>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label>{t('company:contactDetails:labelStreet')}</Form.Label>
                                            <Form.Control type="text" placeholder="" value={context.tempChanges?.contactDetails?.street ?? ''} onChange={e => updateTempChanges('street', e.target.value)} />
                                        </div>
                                        : <div className='field view'>{t('company:contactDetails:labelStreet')}: {context.company?.contactDetails?.street}</div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label>{t('company:contactDetails:labelHouseNumber')}</Form.Label>
                                            <Form.Control type="text" placeholder="" value={context.tempChanges?.contactDetails?.houseNumber ?? ''} onChange={e => updateTempChanges('houseNumber', e.target.value)} />
                                        </div>
                                        : <div className='field view'>{t('company:contactDetails:labelHouseNumber')}: {context.company?.contactDetails?.houseNumber}</div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label>{t('company:contactDetails:labelPostalcode')}</Form.Label>
                                            <Form.Control type="text" placeholder="" value={context.tempChanges?.contactDetails?.postalCode ?? ''} onChange={e => updateTempChanges('postalCode', e.target.value)} />
                                        </div>
                                        : <div className='field view'>{t('company:contactDetails:labelPostalcode')}: {context.company?.contactDetails?.postalCode}</div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label>{t('company:contactDetails:labelCity')}</Form.Label>
                                            <Form.Control type="text" placeholder="" value={context.tempChanges?.contactDetails?.city ?? ''} onChange={e => updateTempChanges('city', e.target.value)} />
                                        </div>
                                        : <div className='field view'>{t('company:contactDetails:labelCity')}: {context.company?.contactDetails?.city}</div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label><span>{t('company:contactDetails:labelEmail')}</span></Form.Label>
                                            <Form.Control type="email" placeholder="" value={context.tempChanges?.contactDetails?.emailPublic ?? ''} onChange={e => updateTempChanges('emailPublic', e.target.value)} />
                                        </div>
                                        : <div className='field view'>{t('company:contactDetails:labelEmail')}: {context.company?.contactDetails?.emailPublic}</div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label>{t('company:contactDetails:labelPhone')}</Form.Label>
                                            <Form.Control type="phone" pattern="[0-9\/]*" placeholder="" value={context.tempChanges?.contactDetails?.phonePublic ?? ''} onChange={e => updateTempChanges('phonePublic', e.target.value.replace(/\D/g, ''))} />
                                        </div>
                                        : <div className='field view'>{t('company:contactDetails:labelPhone')}: {context.company?.contactDetails?.phonePublic}</div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label>{t('company:contactDetails:labelWebsite')}</Form.Label>
                                            <Form.Control type="text" placeholder="" value={context.tempChanges?.contactDetails?.website ?? ''} onChange={e => updateTempChanges('website', e.target.value)} />
                                        </div>
                                        : <div className='field view'>{t('company:contactDetails:labelWebsite')}: {context.company?.contactDetails?.website}</div>
                                    }
                                </Col>
                            </Form.Group>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

export default CompanyContactDetails;