import { CompanyContext } from "@/context/company-context";
import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import './welcome.scss';
import { t } from "i18next";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

function WelcomePage() {

    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const companyContext = useContext(CompanyContext);
    const swiperRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    useDocumentTitle(t('documentTitles:welcome'));

    const guideData = t('welcome:guide', { returnObjects: true }) as any[];

    useEffect(() => {

        const navigationBar = document.querySelector('.navigation-bar');
        if (navigationBar) {
            navigationBar.classList.add('d-none');
            document.body.classList.remove('navbar-margin');
        }

        return () => {
            if (navigationBar) {
                navigationBar.classList.remove('d-none');
                document.body.classList.add('navbar-margin');
            }
        };

    }, [companyContext.company]);

    return (
        <div className="app-container">
            <div className="welcome">

                <Container fluid className="py-5">
                    <Container>
                        <Row className="welcome__introduction">
                            <Col sm={12} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} className="px-0">
                                <h1>{t('welcome:welcomeUser', { name: account.name ?? '' })}</h1>
                                <h1>{t('welcome:welcomePlatform')}</h1>
                                <p>{t('welcome:introduction')}</p>
                            </Col>
                        </Row>
                        <Row className="welcome__guide">
                            <Col sm={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="px-0">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={24}
                                    loop={false}
                                    pagination={true}
                                    modules={[Pagination]}
                                    className="swiper-container"
                                    onSwiper={swiper => { swiperRef.current = swiper }}
                                    onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
                                >
                                    {
                                        guideData?.map((slide, index) => {
                                            return (
                                                <SwiperSlide key={index} className="welcome__slide">
                                                    <div className="welcome__content">
                                                        <img className="welcome__img" src={'/assets/img/bg-welcome-slide-' + (index + 1) + '.jpg'} alt='' />
                                                        <h2><span className="welcome__index">{index + 1}</span>{slide.title}</h2>
                                                        <p>{slide.description}</p>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </Col>
                        </Row>
                        <Row className="welcome__actions">
                            <Col sm={12} className="px-0">
                                <div className="d-flex flex-column align-items-center gap-3">
                                    <div>
                                        <button className="btn btn-primary" disabled={currentIndex == 0} onClick={() => { swiperRef?.current?.slidePrev() }}>
                                            <i className="fas fa-arrow-left me-2"></i>
                                            {t('welcome:previousLink')}
                                        </button>

                                        {currentIndex == 3 &&
                                            <Link to={`/profile/company/${companyContext?.company?.id}`} className="btn btn-primary ms-3">
                                                {t('welcome:completedLink')}
                                                <i className="fas fa-arrow-right ms-2"></i>
                                            </Link>
                                        }

                                        {
                                            currentIndex < 3 &&
                                            <button className="btn btn-primary" onClick={() => { swiperRef?.current?.slideNext() }}>
                                                {t('welcome:nextLink')}
                                                <i className="fas fa-arrow-right ms-2"></i>
                                            </button>
                                        }

                                    </div>

                                    <Link to={`/profile/company/${companyContext?.company?.id}`} className="welcome__skip-link">
                                        {t('welcome:skipLink')}
                                    </Link>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        </div>
    )
}

export default WelcomePage