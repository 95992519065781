import { Route, Routes } from 'react-router-dom';
import ActivitiesOverview from './activities-overview';

function ActivitiesModule() {

    return (
        <Routes>
            <Route path="/">
                <Route index element={<ActivitiesOverview />} />
            </Route>
        </Routes>
    )
}

export default ActivitiesModule;