import { Placeholder, Card, Col, Row } from 'react-bootstrap';
import './talent-preview.scss';

function TalentPlaceholder() {

    return (
        <div className="talent-placeholder p-4 bg-wit default-br">
            <Row className='m-0'>
                <Col xs={8} className='p-0'>
                    <Placeholder as={Card.Text} animation='wave'>
                        <Placeholder xs={12} />
                    </Placeholder>
                </Col>
                <Col xs={{ span: 1, offset: 3 }} className='p-0'>
                    <i className='fa-regular fa-heart fa-xl' />
                </Col>
            </Row>
            <div className='mb-3'>
                <Placeholder as={Card.Text} animation='wave'>
                    <Placeholder xs={5} />
                </Placeholder>
            </div>
            <div className="video-container">
                <div className='placeholder-video'></div>
            </div>
            <div className='mt-3'>
                <Placeholder as={Card.Text} animation='wave'>
                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />
                    <Placeholder xs={6} /> <Placeholder xs={8} />
                </Placeholder>
            </div>
        </div>
    )
}

export default TalentPlaceholder