import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Container, Dropdown } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './public-navigation-bar.scss';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

type PublicNavigationBarProps = {
    lightTheme?: boolean
}

function PublicNavigationBar({ lightTheme = false }: PublicNavigationBarProps) {

    const { i18n, t } = useTranslation();
    const { instance } = useMsal();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [aboutUsIsOpen, setAboutUsIsOpen] = useState(false);
    const [isSticky, setSticky] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    const checkScrollTop = () => {
        setSticky(window.scrollY > 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, []);

    useEffect(() => {
        setAboutUsIsOpen(false);

        if (menuIsOpen) {
            document.body.classList.add('noscroll');
        } else {
            document.body.classList.remove('noscroll');
        }

    }, [menuIsOpen])

    const handleLanguageChange = (languageCode: string) => {

        if (languageCode === i18n.language) { return; };

        i18n.changeLanguage(languageCode);
        dayjs.locale(languageCode);
        localStorage.setItem('selectedLanguage', languageCode);
        toast.success(t('languages:toasts:languageChanged', { language: t(`languages:${languageCode}`) }));
    };

    return (
        <header className={`position-relative bg-transparent`}>
            <nav id="public-navbar" className={`navbar navbar-expand-lg fixed-top transition-fast py-3 ${isSticky ? 'sticky py-md-2' : 'py-md-4'}`}>
                <Container fluid>
                    <Container className='px-0'>
                        <div>
                            <div className='d-flex justify-content-between align-items-center'>

                                <div className='navbar-brand position-relative'>
                                    <Link to='/'>
                                        <img src={menuIsOpen ? `/assets/img/werk-in-het-vooruitzicht-diapositief.svg` : `/assets/img/werk-in-het-vooruitzicht-fullcolor.svg`} alt='' />
                                    </Link>
                                </div>


                                <div className={`menu-actions ${menuIsOpen ? 'open' : ''} ${lightTheme ? 'light' : 'dark'}`}>

                                    <Link to="/profile/company" className="portal-link" tabIndex={0}>
                                        <span className='text-center'>
                                            {
                                                isAuthenticated
                                                    ? <><i className="fas fa-user-circle me-2 d-none d-sm-inline-block" />{t('header:profileLink')}</>
                                                    : <><i className="fas fa-right-to-bracket me-2 d-none d-sm-inline-block" />{t('header:registerLink')}</>
                                            }
                                        </span>
                                    </Link>

                                    <div className='d-flex align-items-center justify-content-end'>
                                        <Dropdown className="language-switch" drop="down-centered" tabIndex={0}>
                                            <Dropdown.Toggle className="language-toggle">
                                                <span>{i18n.language}</span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleLanguageChange("en")}>{t('languages:en')}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleLanguageChange("nl")}>{t('languages:nl')}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <button className={`toggle-menu-icon`} tabIndex={0} onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                            {!menuIsOpen && <i className='fas fa-bars fa-xl' />}
                                            {menuIsOpen && <i className='fas fa-times fa-xl' />}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className={`main-nav d-flex align-items-center transition ${menuIsOpen ? 'open' : ''}`}>
                                <div className='main-nav-wrapper'>

                                    <ul className="nav navbar-nav d-block text-wit">
                                        <li className="menu-item menu-item-has-children dropdown nav-item">
                                            <span role='button' aria-haspopup="true" aria-expanded="false" className="nav-dropdown nav-link" onClick={() => { setAboutUsIsOpen(true) }} tabIndex={0}>
                                                <div className='d-flex align-items-center'>
                                                    <span>{t('header:about')}</span>
                                                    <i className="fas fa-chevron-right fa-sm ms-2"></i>
                                                </div>
                                            </span>
                                            <ul className={`dropdown-menu about-us transition ${aboutUsIsOpen ? 'show' : ''}`}>
                                                <li className="close-menu menu-item nav-item">
                                                    <span role='button' className="nav-link" tabIndex={0} onClick={() => { setAboutUsIsOpen(false) }}>
                                                        <div className='d-flex align-items-center'>
                                                            <i className="fas fa-chevron-left fa-sm me-3"></i>
                                                            <span>{t('header:back')}</span>
                                                        </div>
                                                    </span>
                                                </li>
                                                <li className="menu-item nav-item">
                                                    <Link to="/about-us" className="nav-link" tabIndex={0}>
                                                        <span>{t('header:about')}</span>
                                                    </Link>
                                                </li>
                                                <li className="menu-item nav-item">
                                                    <Link to="/initiators" className="nav-link" tabIndex={0}>
                                                        <span>{t('header:initiators')}</span>
                                                    </Link>
                                                </li>
                                                <li className="menu-item nav-item">
                                                    <Link to="/partners" className="nav-link" tabIndex={0}>
                                                        <span>{t('header:partners')}</span>
                                                    </Link>
                                                </li>
                                                <li className="menu-item nav-item">
                                                    <Link to="/development" className="nav-link" tabIndex={0}>
                                                        <span>{t('header:talentDevelopment')}</span>
                                                    </Link>
                                                </li>
                                                <li className="menu-item nav-item">
                                                    <Link to="/traineeships" className="nav-link" tabIndex={0}>
                                                        <span>{t('header:traineeships')}</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item nav-item">
                                            <Link to="/" className="nav-link" tabIndex={0}>
                                                <span>{t('header:general')}</span>
                                            </Link>
                                        </li>
                                        <li className="menu-item nav-item">
                                            <Link to="/faq" className="nav-link" tabIndex={0}>
                                                <span>{t('header:faq')}</span>
                                            </Link>
                                        </li>
                                        <li className="menu-item nav-item">
                                            <Link to="/testimonials" className="nav-link" tabIndex={0}>
                                                <span>{t('header:experiences')}</span>
                                            </Link>
                                        </li>
                                        <li className="menu-item nav-item">
                                            <Link to="/contact" className="nav-link" tabIndex={0}>
                                                <span>{t('header:contact')}</span>
                                            </Link>
                                        </li>
                                        {
                                            isAuthenticated &&
                                            <li className="menu-item nav-item mt-5">
                                                <a role='button' onClick={() => instance.logoutRedirect()} className="nav-link d-flex align-items-center" tabIndex={0}>
                                                    <i className='fas fa-arrow-right-from-bracket me-3' />
                                                    <span>{t('header:logout')}</span>
                                                </a>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className={`main-nav-shadow transition d-none d-md-block ${menuIsOpen ? 'show' : ''}`}></div>
                        </div>
                    </Container>
                </Container>
            </nav>
        </header>
    )
}

export default PublicNavigationBar