export const MenuProfileIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 31.951 31.951">
        <path id="fi-rr-face-smile-upside-down" d="M15.976,0A15.976,15.976,0,1,0,31.951,15.976,15.993,15.993,0,0,0,15.976,0Zm0,29.289A13.313,13.313,0,1,1,29.289,15.976,13.328,13.328,0,0,1,15.976,29.289Zm7.543-18.3a1.331,1.331,0,0,1-1.773,1.986,10,10,0,0,0-5.77-2.324A10,10,0,0,0,10.2,12.978,1.331,1.331,0,0,1,8.432,10.99a12.586,12.586,0,0,1,7.543-3,12.585,12.585,0,0,1,7.543,3ZM22.632,19.3a2,2,0,1,1-2-2A2,2,0,0,1,22.632,19.3Zm-9.319,0a2,2,0,1,1-2-2A2,2,0,0,1,13.313,19.3Z" transform="translate(31.951 31.951) rotate(180)" />
    </svg>
)

export const MenuSettingsIcon = (props) => (
    <svg id="fi-rr-settings" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 30.2 33.564">
        <path id="Path_3181" data-name="Path 3181" d="M13.594,8a5.594,5.594,0,1,0,5.594,5.594A5.594,5.594,0,0,0,13.594,8Zm0,8.391a2.8,2.8,0,1,1,2.8-2.8A2.8,2.8,0,0,1,13.594,16.391Z" transform="translate(1.506 3.188)" />
        <path id="Path_3182" data-name="Path 3182" d="M29.3,19.439l-.621-.358a12.726,12.726,0,0,0,0-4.6l.621-.358a4.2,4.2,0,1,0-4.2-7.272l-.622.359a12.554,12.554,0,0,0-3.984-2.3V4.2a4.2,4.2,0,0,0-8.391,0v.717a12.554,12.554,0,0,0-3.983,2.3L7.5,6.853a4.2,4.2,0,1,0-4.2,7.272l.621.358a12.726,12.726,0,0,0,0,4.6l-.621.358a4.2,4.2,0,0,0,4.2,7.272l.622-.359a12.554,12.554,0,0,0,3.986,2.3v.717a4.2,4.2,0,0,0,8.391,0v-.717a12.555,12.555,0,0,0,3.983-2.3l.625.361a4.2,4.2,0,1,0,4.2-7.272Zm-3.563-5.281a9.857,9.857,0,0,1,0,5.244,1.4,1.4,0,0,0,.649,1.585l1.516.875a1.4,1.4,0,1,1-1.4,2.424l-1.519-.878a1.4,1.4,0,0,0-1.7.231,9.767,9.767,0,0,1-4.535,2.622,1.4,1.4,0,0,0-1.05,1.355v1.752a1.4,1.4,0,0,1-2.8,0V27.618a1.4,1.4,0,0,0-1.05-1.355,9.767,9.767,0,0,1-4.534-2.628,1.4,1.4,0,0,0-1.7-.231L6.1,24.281a1.4,1.4,0,1,1-1.4-2.422l1.516-.875A1.4,1.4,0,0,0,6.87,19.4a9.857,9.857,0,0,1,0-5.244,1.4,1.4,0,0,0-.65-1.579L4.7,11.7A1.4,1.4,0,0,1,6.1,9.276l1.519.878a1.4,1.4,0,0,0,1.7-.225,9.767,9.767,0,0,1,4.535-2.622A1.4,1.4,0,0,0,14.9,5.946V4.2a1.4,1.4,0,1,1,2.8,0V5.946A1.4,1.4,0,0,0,18.752,7.3a9.767,9.767,0,0,1,4.535,2.628,1.4,1.4,0,0,0,1.7.231L26.5,9.283a1.4,1.4,0,1,1,1.4,2.422l-1.516.875a1.4,1.4,0,0,0-.648,1.579Z" transform="translate(-1.203)" />
    </svg>
)

export const MenuLogoutIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 24.994 25">
        <g id="fi-rr-exit" transform="translate(0.5 0.5)" opacity="0.503">
            <path id="Path_3281" data-name="Path 3281" d="M22.829,9.172,18.95,5.293a1,1,0,1,0-1.414,1.414l3.879,3.879a2.057,2.057,0,0,1,.3.39c-.015,0-.027-.008-.042-.008h0L5.989,11a1,1,0,0,0,0,2h0l15.678-.032c.028,0,.051-.014.078-.016a2,2,0,0,1-.334.462l-3.879,3.879a1,1,0,1,0,1.414,1.414l3.879-3.879a4,4,0,0,0,0-5.656Z" fill="#0c1732" />
            <path id="Path_3281_-_Outline" data-name="Path 3281 - Outline" d="M18.243,4.5a1.49,1.49,0,0,1,1.061.44l4.732,4.732h-.188a4.508,4.508,0,0,1-.669,5.51L19.3,19.061a1.5,1.5,0,1,1-2.121-2.121l3.469-3.469L5.99,13.5a1.5,1.5,0,1,1,0-3l14.6-.03-3.41-3.41A1.5,1.5,0,0,1,18.243,4.5Zm3.379,4.172L18.6,5.647a.5.5,0,1,0-.707.707l3.879,3.879a2.569,2.569,0,0,1,.376.488l.448.755h-.878a.545.545,0,0,1-.093-.008L5.99,11.5a.5.5,0,1,0,0,1l15.624-.032a.611.611,0,0,1,.095-.015l.9-.067-.418.8a2.5,2.5,0,0,1-.418.578l-.006.006-3.879,3.879a.5.5,0,1,0,.707.707l3.879-3.879a3.5,3.5,0,0,0,0-4.949l-.853-.854Z" fill="#0c1732" />
            <path id="Path_3282" data-name="Path 3282" d="M7,22H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H7A1,1,0,1,0,7,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H7a1,1,0,0,0,0-2Z" fill="#0c1732" />
            <path id="Path_3282_-_Outline" data-name="Path 3282 - Outline" d="M7,24.5H5A5.515,5.515,0,0,1-.5,19V5A5.515,5.515,0,0,1,5-.5H7a1.5,1.5,0,0,1,0,3H5A2.5,2.5,0,0,0,2.5,5V19A2.5,2.5,0,0,0,5,21.5H7a1.5,1.5,0,0,1,0,3ZM5,.5A4.513,4.513,0,0,0,.5,5V19A4.513,4.513,0,0,0,5,23.5H7a.5.5,0,0,0,0-1H5A3.5,3.5,0,0,1,1.5,19V5A3.5,3.5,0,0,1,5,1.5H7a.5.5,0,0,0,0-1Z" fill="#0c1732" />
        </g>
    </svg>
)

export const MenuFunctionsIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 31.924 23.943">
        <path id="fi-rr-laptop-code" d="M10.2,15.171a2.984,2.984,0,0,1-.02-4.225L12.417,8.71A1.33,1.33,0,1,1,14.3,10.591l-2.236,2.236a.313.313,0,0,0,0,.443l2.16,2.071a1.331,1.331,0,0,1-1.842,1.921Zm9.66-2.344a.309.309,0,0,1,.02.423L17.7,15.341a1.331,1.331,0,0,0,1.842,1.921l2.2-2.11a2.975,2.975,0,0,0,0-4.2L19.507,8.712a1.33,1.33,0,0,0-1.881,1.881l2.236,2.236ZM31.924,22.953a4,4,0,0,1-3.991,3.991H3.991a3.99,3.99,0,0,1-1.33-7.752V9.651A6.658,6.658,0,0,1,9.311,3h13.3a6.658,6.658,0,0,1,6.651,6.651v9.54A4,4,0,0,1,31.924,22.953Zm-26.6-13.3v9.311h5.986a1.327,1.327,0,0,1,.94.39l.94.94h5.549l.94-.94a1.329,1.329,0,0,1,.94-.39H26.6V9.651A4,4,0,0,0,22.613,5.66H9.311A4,4,0,0,0,5.321,9.651Zm23.943,13.3a1.332,1.332,0,0,0-1.33-1.33H21.168l-.94.94a1.329,1.329,0,0,1-.94.39H12.637a1.327,1.327,0,0,1-.94-.39l-.94-.94H3.991a1.33,1.33,0,1,0,0,2.66H27.934A1.332,1.332,0,0,0,29.264,22.953Z" transform="translate(0 -3)" />
    </svg>
)

export const MenuTalentsIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 31.32 31.32">
        <path id="fi-rr-face-monocle_2" data-name="fi-rr-face-monocle 2" d="M31.32,15.66a15.769,15.769,0,0,1-.257,2.845,1.3,1.3,0,0,1-2.563-.471,13.328,13.328,0,0,0,.214-2.373A13.032,13.032,0,1,0,19.22,28.219a1.3,1.3,0,1,1,.71,2.508A15.66,15.66,0,1,1,31.32,15.66ZM16.584,22.2A7.516,7.516,0,0,1,9.476,19.74a1.3,1.3,0,0,0-1.15-.44,1.332,1.332,0,0,0-.691.306,1.3,1.3,0,0,0-.128,1.841,10.046,10.046,0,0,0,9.408,3.34,1.3,1.3,0,1,0-.33-2.588Zm-6.8-9.151A1.958,1.958,0,1,0,7.83,11.091,1.955,1.955,0,0,0,9.788,13.048ZM26.1,13.7V30.017a1.3,1.3,0,1,1-2.606,0V18.578A5.869,5.869,0,1,1,26.1,13.7Zm-2.612,0a3.26,3.26,0,1,0-3.26,3.26A3.263,3.263,0,0,0,23.49,13.7Z" />
    </svg>
)

export const MenuEventsIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 31.649 31.648">
        <path id="fi-rr-calendar-day" d="M10.55,15.825H7.912a2.64,2.64,0,0,0-2.637,2.637V21.1a2.64,2.64,0,0,0,2.637,2.637H10.55A2.64,2.64,0,0,0,13.187,21.1V18.462A2.64,2.64,0,0,0,10.55,15.825ZM7.912,21.1V18.462H10.55V21.1ZM25.056,2.637H23.737V1.319a1.319,1.319,0,0,0-2.637,0V2.637H10.55V1.319a1.319,1.319,0,0,0-2.637,0V2.637H6.594A6.6,6.6,0,0,0,0,9.231V25.056a6.6,6.6,0,0,0,6.594,6.594H25.056a6.6,6.6,0,0,0,6.594-6.594V9.231A6.6,6.6,0,0,0,25.056,2.637ZM6.594,5.275H25.056a3.961,3.961,0,0,1,3.956,3.956V10.55H2.637V9.231A3.961,3.961,0,0,1,6.594,5.275ZM25.056,29.012H6.594a3.961,3.961,0,0,1-3.956-3.956V13.187H29.012V25.056A3.961,3.961,0,0,1,25.056,29.012Z" />
    </svg>
)

export const MenuMeasurementsIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 31.815 29.17">
        <g id="fi-rr-blog-pencil" transform="translate(0 -42.699)">
            <path id="Path_3397" data-name="Path 3397" d="M25.185,42.7H6.63A6.631,6.631,0,0,0,0,49.324V62.578a6.633,6.633,0,0,0,6.63,6.63h9.277a1.324,1.324,0,1,0,0-2.647H6.63a3.989,3.989,0,0,1-3.977-3.977V51.977H29.168v6.63a1.324,1.324,0,1,0,2.647,0V49.324A6.631,6.631,0,0,0,25.185,42.7ZM2.653,49.324A3.989,3.989,0,0,1,6.63,45.347H25.191a3.989,3.989,0,0,1,3.977,3.977Zm19.1,6.854A5.253,5.253,0,0,0,18,54.625H15.9a1.329,1.329,0,0,0-1.324,1.324v2.107a5.316,5.316,0,0,0,1.553,3.753L25.017,70.7a3.972,3.972,0,1,0,5.617-5.617L21.743,56.19Zm7.009,12.645a1.347,1.347,0,0,1-1.87,0L18,59.931a2.634,2.634,0,0,1-.783-1.87v-.783H18a2.613,2.613,0,0,1,1.87.783l8.892,8.892a1.305,1.305,0,0,1,.385.938,1.285,1.285,0,0,1-.385.938v-.006Z" transform="translate(0 0)" />
            <path id="Path_3398" data-name="Path 3398" d="M92.941,236.024a1.329,1.329,0,0,1-1.324,1.324H90.294v5.3a1.324,1.324,0,1,1-2.647,0v-5.3H86.324a1.324,1.324,0,1,1,0-2.647h5.3A1.327,1.327,0,0,1,92.941,236.024Z" transform="translate(-79.718 -180.069)" />
        </g>
    </svg>
)

export const MenuMoreIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 27.42 6.76">
        <path id="Path_3350" data-name="Path 3350" d="M10.331,23.97a3.38,3.38,0,1,0,0,6.76,3.38,3.38,0,0,0,0-6.76Zm10.33,0a3.38,3.38,0,1,0,3.364,3.349A3.433,3.433,0,0,0,20.661,23.97Zm10.33,0a3.38,3.38,0,1,0,3.364,3.349A3.433,3.433,0,0,0,30.991,23.97Z" transform="translate(-6.935 -23.97)" />
    </svg>
)
