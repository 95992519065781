import { Company } from '@/company/model/company';
import { CompanyContext } from '@/context/company-context';
import { useContext } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './company-card.scss';

function CompanyContact() {

    const { t } = useTranslation();
    const context = useContext(CompanyContext);

    const updateTempChanges = (propertyName, newValue) => {

        const updatedCompany: Company = {
            ...context.tempChanges,
            contactPerson: {
                ...context.tempChanges.contactPerson,
                [propertyName]: newValue
            }
        };

        context.updateChanges(updatedCompany);
    };

    return (
        <>
            <Card className="company-card mb-4">
                <Card.Header>
                    <span>{t('company:contactPerson:title')}</span>
                </Card.Header>
                <Card.Body>
                    <Card.Text as="div">
                        <div className='mt-4'>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label>{t('company:contactPerson:labelName')}</Form.Label>
                                            <Form.Control type="text" placeholder="" value={context.tempChanges?.contactPerson?.name ?? ''} onChange={e => updateTempChanges('name', e.target.value)} />
                                        </div>
                                        : <div className='field view'>{t('company:contactPerson:labelName')}: {context.company?.contactPerson?.name}</div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label>{t('company:contactPerson:labelFunction')}</Form.Label>
                                            <Form.Control type="text" placeholder="" value={context.tempChanges?.contactPerson?.function ?? ''} onChange={e => updateTempChanges('function', e.target.value)} />
                                        </div>
                                        : <div className='field view'>{t('company:contactPerson:labelFunction')}: {context.company?.contactPerson?.function}</div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label><span>{t('company:contactPerson:labelEmail')}</span></Form.Label>
                                            <Form.Control type="email" className='readonly' readOnly placeholder="" value={context.tempChanges?.contactPerson?.email ?? ''} onChange={e => updateTempChanges('email', e.target.value)} />
                                        </div>
                                        : <div className='field view'>{t('company:contactPerson:labelEmail')}: {context.company?.contactPerson?.email}</div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label>{t('company:contactPerson:labelPhone')}</Form.Label>
                                            <Form.Control type="phone" pattern="[0-9\/]*" placeholder="" value={context.tempChanges?.contactPerson?.phone ?? ''} onChange={e => updateTempChanges('phone', e.target.value.replace(/\D/g, ''))} />
                                        </div>
                                        : <div className='field view'>{t('company:contactPerson:labelPhone')}: {context.company?.contactPerson?.phone}</div>
                                    }
                                </Col>
                            </Form.Group>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

export default CompanyContact;