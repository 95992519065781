import { ConfigContext } from '@/context/config-context';
import { useContext } from 'react';
import useSWR from 'swr';
import useFavoriteTalents from './useFavoriteTalents';
import { ProfileCompletion } from '@/general/model/profile-completion';
import { Activity } from '@/company/model/activity';
import { EmployerFunction } from '@/functions/model/employer-function';

const useProfileCompletion = () => {

    const configContext = useContext(ConfigContext);

    const { data: profileCompletion, isLoading: completionLoading } = useSWR<ProfileCompletion>(configContext.configBody.api.endpoints.company.completion);
    const { data: activities, isLoading: activitiesLoading } = useSWR<Activity[]>(configContext.configBody.api.endpoints.activities.list);
    const { data: functions, isLoading: functionsLoading } = useSWR<EmployerFunction[]>(configContext.configBody.api.endpoints.functions.list);

    const { favorites } = useFavoriteTalents();

    const statsLoading = activitiesLoading || functionsLoading;

    return { profileCompletion, activities, functions, favorites, completionLoading, statsLoading };
}

export default useProfileCompletion;