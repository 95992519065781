import { ErrorComponent } from "@/components/error-component/error-component";
import { LoadingComponent } from "@/components/loading-component/loading-component";
import NavigationBlocker from "@/components/navigation-blocker/navigation-blocker";
import { ConfigContext } from "@/context/config-context";
import { FunctionContext } from "@/context/function-context";
import { FeedbackStatus } from "@/feedback/models/feedback-status";
import useAuthenticatedPost from "@/hooks/useAuthenticatedPost";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import Header from "@/layout/header/header";
import { t } from "i18next";
import { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { EmployerFunction } from "../model/employer-function";
import { Knowledge } from "../model/knowledge";
import { Skills } from "../model/skills";
import FunctionFeedbackSelection from "./function-feedback-selection";

function FunctionFeedback() {

    useDocumentTitle(t('documentTitles:functionFeedback'));

    const { id, feedbackId } = useParams();
    const navigate = useNavigate();
    const { postRequest } = useAuthenticatedPost();
    const functionContext = useContext(FunctionContext);
    const configContext = useContext(ConfigContext);
    const { data: employerFunction, isLoading, error, mutate: mutateFunction } = useSWR<EmployerFunction>(id ? configContext.configBody.api.endpoints.functions.detail.replace('{id}', id) : null);

    useEffect(() => {

        if (employerFunction) {
            functionContext.updateEmployerFunction(employerFunction);

            const feedback = employerFunction.feedbacks?.find(f => f.id === feedbackId);

            if (feedback && feedback.status === FeedbackStatus.Submitted) {
                updateFeedbackStatus();
            }
        }

    }, [id, employerFunction]);

    const handleSave = async () => {

        functionContext.saveEmployerFunction(functionContext.tempChanges).then(() => {
            mutateFunction();
            //navigate after 100ms to prevent navigation blocker
            setTimeout(() => navigate(`/profile/functions/${id}`), 100);
        }).catch((error) => { });
    }

    const handleOnSkillsChange = (skills: Skills) => {
        functionContext.updateChanges({ ...functionContext.tempChanges, skills: skills });
    }

    const handleOnKnowledgeChange = (knowledge: Knowledge) => {
        functionContext.updateChanges({ ...functionContext.tempChanges, knowledge: knowledge });
    }

    const updateFeedbackStatus = () => {

        const updatedFunction: EmployerFunction = {
            ...employerFunction,
            feedbacks: employerFunction?.feedbacks?.map((feedback) => {
                if (feedback.id === feedbackId) {
                    feedback.status = FeedbackStatus.Reviewed;
                }
                return feedback;
            })
        };

        postRequest(configContext.configBody.api.endpoints.functions.list, updatedFunction)
            .then((response: EmployerFunction) => {
                mutateFunction();
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('functions:toasts:saveFailed') + ' ' + error.message);
            });
    }

    return (

        <div className="function-feedback-overview">
            {
                isLoading &&
                <LoadingComponent message={t('functions:detail:feedback:skills:loading')} />
            }

            {
                error &&
                <ErrorComponent />
            }

            {
                !isLoading && employerFunction &&
                <>
                    {
                        employerFunction.feedbacks.some(f => f.id === feedbackId)
                            ?
                            <>
                                <Container fluid className='bg-blauw position-relative py-5'>
                                    <Container>
                                        <div className='header-img full position-absolute px-0'>
                                            <img src={'/assets/img/WIHV_3D_Visual_Blauw_Wit.jpg'} style={{ objectPosition: 'center center' }} alt='' />
                                        </div>

                                        <div className="position-relative pb-5">
                                            <Header />
                                        </div>

                                        <Row className='position-relative first-row-after-heading-img'>
                                            <Col sm={12} md={12} lg={5} xl={5} xxl={5} className='d-flex flex-column text-wit'>
                                                <div className="font-heavy h2 pb-5">{t('functions:detail:feedback:skills:title')} {employerFunction.feedbacks.find(f => f.id === feedbackId).name}</div>
                                                <p className='flex-grow-1'>
                                                    {t('functions:detail:feedback:skills:description')}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Container>
                                <Container fluid className="bg-blauw py-5 position-relative">
                                    <Container>
                                        <FunctionFeedbackSelection
                                            feedback={employerFunction.feedbacks.find(f => f.id === feedbackId)}
                                            skills={functionContext.tempChanges?.skills ?? { essential: [], optional: [] }}
                                            knowledge={functionContext.tempChanges?.knowledge ?? { essential: [], optional: [] }}
                                            onSkillsChange={handleOnSkillsChange}
                                            onKnowledgeChange={handleOnKnowledgeChange} />

                                        <div className="d-flex w-100 justify-content-center">

                                            <button className="btn btn-primary mt-5" disabled={functionContext.requestInProgress} onClick={() => { handleSave() }}>{t('functions:detail:feedback:skills:save')}</button>
                                        </div>
                                    </Container>
                                </Container>
                            </>
                            :
                            <ErrorComponent />
                    }
                </>
            }

            <NavigationBlocker shouldBlock={{ obj1: functionContext.employerFunction, obj2: functionContext.tempChanges }} />

        </div>
    )
}

export default FunctionFeedback