import { Feedback } from "@/feedback/models/feedback";
import { Knowledge, KnowledgeValue } from "@/functions/model/knowledge";
import { Skill, Skills } from "@/functions/model/skills";
import { useContext, useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import './function-feedback.scss';
import useAuthenticatedPost from "@/hooks/useAuthenticatedPost";
import { ConfigContext } from "@/context/config-context";
import { FunctionContext } from "@/context/function-context";
import { FeedbackStatus } from "@/feedback/models/feedback-status";

interface FeedbackCategories {
    added: Skill[] | KnowledgeValue[];
    deleted: Skill[] | KnowledgeValue[];
    updatedToEssential: Skill[] | KnowledgeValue[];
    updatedToOptional: Skill[] | KnowledgeValue[];
    unchanged: Skill[] | KnowledgeValue[];
}

type FunctionFeedbackSelectionProps = {
    feedback: Feedback;
    skills: Skills;
    knowledge: Knowledge;
    onSkillsChange: (skills: Skills) => void;
    onKnowledgeChange: (knowledge: Knowledge) => void;
}

function FunctionFeedbackSelection({ feedback, skills, knowledge, onSkillsChange, onKnowledgeChange }: FunctionFeedbackSelectionProps) {

    const prevFunctionSkills = useRef<Skills>();
    const prevFunctionKnowledge = useRef<Knowledge>();

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    const [skillsFeedbackCategories, setSkillsFeedbackCategories] = useState<FeedbackCategories>(null);
    const [knowledgeFeedbackCategories, setKnowledgeFeedbackCategories] = useState<FeedbackCategories>(null);

    const [functionSkills, setFunctionSkills] = useState<Skills>({ essential: [], optional: [] });
    const [functionKnowledge, setFunctionKnowledge] = useState<Knowledge>({ essential: [], optional: [] });
    
    const { t } = useTranslation();

    useEffect(() => {

        if (!prevFunctionSkills.current || JSON.stringify(prevFunctionSkills.current) !== JSON.stringify(skills)) {
            setFunctionSkills(skills);
            prevFunctionSkills.current = skills;
        }

        if (!prevFunctionKnowledge.current || JSON.stringify(prevFunctionKnowledge.current) !== JSON.stringify(knowledge)) {
            setFunctionKnowledge(knowledge);
            prevFunctionKnowledge.current = knowledge;
        }

    }, [skills, knowledge]);

    useEffect(() => {

        if (!feedback) return;

        const skillsFeedbackCategories: FeedbackCategories = {
            added: [],
            deleted: [],
            updatedToEssential: [],
            updatedToOptional: [],
            unchanged: []
        };

        const knowledgeFeedbackCategories: FeedbackCategories = {
            added: [],
            deleted: [],
            updatedToEssential: [],
            updatedToOptional: [],
            unchanged: []
        };

        const allFeedbackSkills = [...feedback.skills.essential, ...feedback.skills.optional];
        const allOriginalFeedbackSkills = [...feedback.originalSkills.essential, ...feedback.originalSkills.optional];
        const allFeedbackKnowledge = [...feedback.knowledge.essential, ...feedback.knowledge.optional];
        const allOriginalFeedbackKnowledge = [...feedback.originalKnowledge.essential, ...feedback.originalKnowledge.optional];

        // Skills added
        allFeedbackSkills.forEach((feedbackSkill) => {
            if (!allOriginalFeedbackSkills.some(x => x.name.trim().toLowerCase() === feedbackSkill.name.trim().toLowerCase())) {
                skillsFeedbackCategories.added.push(feedbackSkill);
            }
        });

        // Skills deleted
        allOriginalFeedbackSkills.forEach((originalFeedbackSkill) => {
            if (!allFeedbackSkills.some(x => x.name.trim().toLowerCase() === originalFeedbackSkill.name.trim().toLowerCase())) {
                skillsFeedbackCategories.deleted.push(originalFeedbackSkill);
            }
        });

        // Skills updated to essential
        feedback.skills.essential.forEach((feedbackSkill) => {
            if (feedback.originalSkills.optional.some(x => x.name.trim().toLowerCase() === feedbackSkill.name.trim().toLowerCase())) {
                skillsFeedbackCategories.updatedToEssential.push(feedbackSkill);
            }
        });

        // Skills updated to optional
        feedback.skills.optional.forEach((feedbackSkill) => {
            if (feedback.originalSkills.essential.some(x => x.name.trim().toLowerCase() === feedbackSkill.name.trim().toLowerCase())) {
                skillsFeedbackCategories.updatedToOptional.push(feedbackSkill);
            }
        });

        // Skills unchanged
        feedback.skills.essential.forEach((feedbackSkill) => {
            if (feedback.originalSkills.essential.some(x => x.name.trim().toLowerCase() === feedbackSkill.name.trim().toLowerCase())) {
                skillsFeedbackCategories.unchanged.push(feedbackSkill);
            }
        });
        feedback.skills.optional.forEach((feedbackSkill) => {
            if (feedback.originalSkills.optional.some(x => x.name.trim().toLowerCase() === feedbackSkill.name.trim().toLowerCase())) {
                skillsFeedbackCategories.unchanged.push(feedbackSkill);
            }
        });

        // Knowledge added
        allFeedbackKnowledge.forEach((feedbackKnowledge) => {
            if (!allOriginalFeedbackKnowledge.some(x => x.name.trim().toLowerCase() === feedbackKnowledge.name.trim().toLowerCase())) {
                knowledgeFeedbackCategories.added.push(feedbackKnowledge);
            }
        });

        // Knowledge deleted
        allOriginalFeedbackKnowledge.forEach((originalFeedbackKnowledge) => {
            if (!allFeedbackKnowledge.some(x => x.name.trim().toLowerCase() === originalFeedbackKnowledge.name.trim().toLowerCase())) {
                knowledgeFeedbackCategories.deleted.push(originalFeedbackKnowledge);
            }
        });

        // Knowledge updated to essential
        feedback.knowledge.essential.forEach((feedbackKnowledge) => {
            if (feedback.originalKnowledge.optional.some(x => x.name.trim().toLowerCase() === feedbackKnowledge.name.trim().toLowerCase())) {
                knowledgeFeedbackCategories.updatedToEssential.push(feedbackKnowledge);
            }
        });

        // Knowledge updated to optional
        feedback.knowledge.optional.forEach((feedbackKnowledge) => {
            if (feedback.originalKnowledge.essential.some(x => x.name.trim().toLowerCase() === feedbackKnowledge.name.trim().toLowerCase())) {
                knowledgeFeedbackCategories.updatedToOptional.push(feedbackKnowledge);
            }
        });

        // Knowledge unchanged
        feedback.knowledge.essential.forEach((feedbackKnowledge) => {
            if (feedback.originalKnowledge.essential.some(x => x.name.trim().toLowerCase() === feedbackKnowledge.name.trim().toLowerCase())) {
                knowledgeFeedbackCategories.unchanged.push(feedbackKnowledge);
            }
        });
        feedback.knowledge.optional.forEach((feedbackKnowledge) => {
            if (feedback.originalKnowledge.optional.some(x => x.name.trim().toLowerCase() === feedbackKnowledge.name.trim().toLowerCase())) {
                knowledgeFeedbackCategories.unchanged.push(feedbackKnowledge);
            }
        });

        setSkillsFeedbackCategories(skillsFeedbackCategories);
        setKnowledgeFeedbackCategories(knowledgeFeedbackCategories);

    }, [feedback]);

    useEffect(() => {
        if (!prevFunctionSkills.current || JSON.stringify(prevFunctionSkills.current) !== JSON.stringify(functionSkills)) {
            onSkillsChange(functionSkills);
            prevFunctionSkills.current = functionSkills;
        }
    }, [functionSkills]);

    useEffect(() => {
        if (!prevFunctionKnowledge.current || JSON.stringify(prevFunctionKnowledge.current) !== JSON.stringify(functionKnowledge)) {
            onKnowledgeChange(functionKnowledge);
            prevFunctionKnowledge.current = functionKnowledge;
        }
    }, [functionKnowledge]);

    const addSkill = (selectedSkill) => {

        if (selectedSkill === null || selectedSkill === undefined) return;

        const normalizedSkillName = selectedSkill.name.trim().toLowerCase();

        if (feedback?.skills?.essential?.find(x => x.name.trim().toLowerCase() === normalizedSkillName)) {
            toast.success(t('functions:detail:skills:skillsAdded'));
            setFunctionSkills(prevSkills => {
                const updatedList = [...prevSkills.essential, selectedSkill as Skill];
                return {
                    ...prevSkills,
                    essential: updatedList
                };
            });
        } else if (feedback?.skills?.optional?.find(x => x.name.trim().toLowerCase() === normalizedSkillName)) {
            toast.success(t('functions:detail:skills:skillsAdded'));
            setFunctionSkills(prevSkills => {
                const updatedList = [...prevSkills.optional, selectedSkill as Skill];
                return {
                    ...prevSkills,
                    optional: updatedList
                };
            });
        }
    }

    const deleteSkill = (selectedSkill) => {

        if (selectedSkill === null || selectedSkill === undefined) return;

        const normalizedSkillName = selectedSkill.name.trim().toLowerCase();

        if (functionSkills?.essential?.find(x => x.name.trim().toLowerCase() === normalizedSkillName)) {
            toast.success(t('functions:detail:skills:skillsDeleted'));
            setFunctionSkills(prevSkills => {
                const updatedList = prevSkills.essential.filter(item => item.name.trim().toLowerCase() !== normalizedSkillName);
                return {
                    ...prevSkills,
                    essential: updatedList
                };
            });
        } else if (functionSkills.optional?.find(x => x.name.trim().toLowerCase() === normalizedSkillName)) {
            toast.success(t('functions:detail:skills:skillsDeleted'));
            setFunctionSkills(prevSkills => {
                const updatedList = prevSkills.optional.filter(item => item.name.trim().toLowerCase() !== normalizedSkillName);
                return {
                    ...prevSkills,
                    optional: updatedList
                };
            });
        }
    }

    const moveSkill = (selectedSkill) => {

        if (selectedSkill === null || selectedSkill === undefined) return;

        const normalizedSkillName = selectedSkill.name.trim().toLowerCase();

        if (functionSkills?.essential?.find(x => x.name.trim().toLowerCase() === normalizedSkillName)) {
            toast.success(t('functions:detail:skills:skillsMoved'));
            setFunctionSkills(prevSkills => {
                const updatedList = prevSkills.essential.filter(item => item.name.trim().toLowerCase() !== normalizedSkillName);
                return {
                    ...prevSkills,
                    essential: updatedList
                };
            });
            setFunctionSkills(prevSkills => {
                const updatedList = [...prevSkills.optional, selectedSkill as Skill];
                return {
                    ...prevSkills,
                    optional: updatedList
                };
            });
        } else if (functionSkills.optional?.find(x => x.name.trim().toLowerCase() === normalizedSkillName)) {
            toast.success(t('functions:detail:skills:skillsMoved'));
            setFunctionSkills(prevSkills => {
                const updatedList = prevSkills.optional.filter(item => item.name.trim().toLowerCase() !== normalizedSkillName);
                return {
                    ...prevSkills,
                    optional: updatedList
                };
            });
            setFunctionSkills(prevSkills => {
                const updatedList = [...prevSkills.essential, selectedSkill as Skill];
                return {
                    ...prevSkills,
                    essential: updatedList
                };
            });
        }
    }

    const addKnowledge = (selectedKnowledge) => {

        if (selectedKnowledge === null || selectedKnowledge === undefined) return;

        const normalizedKnowledgeName = selectedKnowledge.name.trim().toLowerCase();

        if (feedback?.knowledge?.essential?.find(x => x.name.trim().toLowerCase() === normalizedKnowledgeName)) {
            toast.success(t('functions:detail:skills:knowledgeAdded'));
            setFunctionKnowledge(prevKnowledge => {
                const updatedList = [...prevKnowledge.essential, selectedKnowledge as KnowledgeValue];
                return {
                    ...prevKnowledge,
                    essential: updatedList
                };
            });
        } else if (feedback?.knowledge?.optional?.find(x => x.name.trim().toLowerCase() === normalizedKnowledgeName)) {
            toast.success(t('functions:detail:skills:knowledgeAdded'));
            setFunctionKnowledge(prevKnowledge => {
                const updatedList = [...prevKnowledge.optional, selectedKnowledge as KnowledgeValue];
                return {
                    ...prevKnowledge,
                    optional: updatedList
                };
            });
        }
    }

    const deleteKnowledge = (selectedKnowledge) => {

        if (selectedKnowledge === null || selectedKnowledge === undefined) return;

        const normalizedKnowledgeName = selectedKnowledge.name.trim().toLowerCase();

        if (functionKnowledge?.essential?.find(x => x.name.trim().toLowerCase() === normalizedKnowledgeName)) {
            toast.success(t('functions:detail:skills:knowledgeDeleted'));
            setFunctionKnowledge(prevKnowledge => {
                const updatedList = prevKnowledge.essential.filter(item => item.name.trim().toLowerCase() !== normalizedKnowledgeName);
                return {
                    ...prevKnowledge,
                    essential: updatedList
                };
            });
        } else if (functionKnowledge.optional?.find(x => x.name.trim().toLowerCase() === normalizedKnowledgeName)) {
            toast.success(t('functions:detail:skills:knowledgeDeleted'));
            setFunctionKnowledge(prevKnowledge => {
                const updatedList = prevKnowledge.optional.filter(item => item.name.trim().toLowerCase() !== normalizedKnowledgeName);
                return {
                    ...prevKnowledge,
                    optional: updatedList
                };
            });
        }
    }

    const moveKnowledge = (selectedKnowledge) => {

        if (selectedKnowledge === null || selectedKnowledge === undefined) return;

        const normalizedKnowledgeName = selectedKnowledge.name.trim().toLowerCase();

        if (functionKnowledge?.essential?.find(x => x.name.trim().toLowerCase() === normalizedKnowledgeName)) {
            toast.success(t('functions:detail:skills:knowledgeMoved'));
            setFunctionKnowledge(prevKnowledge => {
                const updatedList = prevKnowledge.essential.filter(item => item.name.trim().toLowerCase() !== normalizedKnowledgeName);
                return {
                    ...prevKnowledge,
                    essential: updatedList
                };
            });
            setFunctionKnowledge(prevKnowledge => {
                const updatedList = [...prevKnowledge.optional, selectedKnowledge as KnowledgeValue];
                return {
                    ...prevKnowledge,
                    optional: updatedList
                };
            });
        } else if (functionKnowledge.optional?.find(x => x.name.trim().toLowerCase() === normalizedKnowledgeName)) {
            toast.success(t('functions:detail:skills:knowledgeMoved'));
            setFunctionKnowledge(prevKnowledge => {
                const updatedList = prevKnowledge.optional.filter(item => item.name.trim().toLowerCase() !== normalizedKnowledgeName);
                return {
                    ...prevKnowledge,
                    optional: updatedList
                };
            });
            setFunctionKnowledge(prevKnowledge => {
                const updatedList = [...prevKnowledge.essential, selectedKnowledge as KnowledgeValue];
                return {
                    ...prevKnowledge,
                    essential: updatedList
                };
            });
        }
    }

    const isSkillSelected = (skillName, type?: 'essential' | 'optional') => {
        if (skillName === null || skillName === undefined) return false;

        const normalizedSkillName = skillName.trim().toLowerCase();

        if (type === 'essential') {
            return !!(
                functionSkills?.essential.find(x => x.name.trim().toLowerCase() === normalizedSkillName)
            );
        } else if (type === 'optional') {
            return !!(
                functionSkills?.optional.find(x => x.name.trim().toLowerCase() === normalizedSkillName)
            );
        }

        return !!(
            functionSkills?.essential.find(x => x.name.trim().toLowerCase() === normalizedSkillName) ||
            functionSkills?.optional.find(x => x.name.trim().toLowerCase() === normalizedSkillName)
        );
    };

    const isKnowledgeSelected = (skillName, type?: 'essential' | 'optional') => {
        if (skillName === null || skillName === undefined) return false;

        const normalizedSkillName = skillName.trim().toLowerCase();

        if (type === 'essential') {
            return !!(
                functionKnowledge?.essential.find(x => x.name.trim().toLowerCase() === normalizedSkillName)
            );
        } else if (type === 'optional') {
            return !!(
                functionKnowledge?.optional.find(x => x.name.trim().toLowerCase() === normalizedSkillName)
            );
        }

        return !!(
            functionKnowledge?.essential.find(x => x.name.trim().toLowerCase() === normalizedSkillName) ||
            functionKnowledge?.optional.find(x => x.name.trim().toLowerCase() === normalizedSkillName)
        );
    };

    const handleInfoClick = (event, item, type) => {
        event.stopPropagation();

        // Set the selected value
        setSelectedValue({
            name: item.name,
            description: item.description,
            type: type
        });

        // Show the info modal
        setShowInfoModal(true);
    };

    return (
        <div className="feedback-skills-selection-wrapper">

            <Row className="skills-container bg-wit">

                <div className="font-bold text-paars h3 py-2 m-0">
                    {t('functions:detail:skills:skillsTitle')}
                </div>

                <p className="pb-4">
                    {t('functions:detail:feedback:skills:skillsDescription')}
                </p>

                <Col sm={12} md={12} lg={6} xl={6} xxl={6} className="p-0 p-sm-2">
                    <div className="h4 text-oranje font-heavy mb-2">
                        {t('functions:detail:skills:functionTitle')}
                    </div>

                    <div className="function-column">
                        <div className="droppables-wrapper p-2">
                            <div className="skill-type">{t('functions:detail:skills:essential')}</div>
                            <div className={`droppable-container mb-4`}>
                                {functionSkills?.essential.map((item, index) => (
                                    <div key={index} className={`option mb-2`}>
                                        <span className={`drag-item-title`}>{index + 1}. {item.name}</span>
                                        {
                                            item.description &&
                                            <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill')}>
                                                <i className='fas fa-info fa-sm' />
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className="skill-type">{t('functions:detail:skills:optional')}</div>
                            <div className={`droppable-container optional p-2`}>
                                {functionSkills?.optional.map((item, index) => (
                                    <div key={index} className={`option mb-2`}>
                                        <span className="drag-item-title">{index + 1}. {item.name}</span>
                                        {
                                            item.description &&
                                            <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill')}>
                                                <i className='fas fa-info fa-sm' />
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={12} lg={6} xl={6} xxl={6} className="p-0 p-sm-2 mt-4 mt-md-0">
                    <div>
                        <span className="h4 font-bold text-oranje ms-1">
                            {t('functions:detail:feedback:skills:sourceTitle')} {feedback.name}
                        </span>
                    </div>

                    <div className="source-column">
                        <div className={`source-options`}>

                            {
                                skillsFeedbackCategories && skillsFeedbackCategories.added?.length > 0 &&
                                <>
                                    <div className="category-type mt-3">
                                        <i className="fas fa-plus me-2"></i>
                                        {t('functions:detail:feedback:skills:addedBy')} {feedback.name}
                                    </div>

                                    {skillsFeedbackCategories.added?.map((item, index) => (

                                        <div key={index} className={`feedback-row added`}>
                                            <div className={`option`}>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {
                                                    item.description &&
                                                    <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill')}>
                                                        <i className='fas fa-info fa-sm' />
                                                    </div>
                                                }
                                            </div>

                                            <button
                                                className={`${isSkillSelected(item.name) ? 'disabled' : ''}`}
                                                disabled={isSkillSelected(item.name)}
                                                onClick={() => { addSkill(item) }}>
                                                <i className="fas fa-circle-check me-2" />
                                                {t('functions:detail:feedback:skills:accept')}
                                            </button>
                                        </div>
                                    ))}
                                </>
                            }

                            {
                                skillsFeedbackCategories && skillsFeedbackCategories.deleted?.length > 0 &&
                                <>
                                    <div className="category-type mt-3">
                                        <i className="fas fa-trash-can me-2"></i>
                                        {t('functions:detail:feedback:skills:deletedBy')} {feedback.name}
                                    </div>

                                    {skillsFeedbackCategories.deleted?.map((item, index) => (

                                        <div key={index} className={`feedback-row deleted`}>
                                            <div className={`option`}>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {
                                                    item.description &&
                                                    <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill')}>
                                                        <i className='fas fa-info fa-sm' />
                                                    </div>
                                                }
                                            </div>

                                            <button
                                                className={`${!isSkillSelected(item.name) ? 'disabled' : ''}`}
                                                disabled={!isSkillSelected(item.name)}
                                                onClick={() => { deleteSkill(item) }}>
                                                <i className="fas fa-circle-check me-2" />
                                                {t('functions:detail:feedback:skills:accept')}
                                            </button>
                                        </div>
                                    ))}
                                </>
                            }

                            {
                                skillsFeedbackCategories && skillsFeedbackCategories.updatedToEssential?.length > 0 &&
                                <>
                                    <div className="category-type mt-3">
                                        <i className="fas fa-arrow-up me-2"></i>
                                        {t('functions:detail:feedback:skills:updatedToEssentialBy')} {feedback.name}
                                    </div>

                                    {skillsFeedbackCategories.updatedToEssential?.map((item, index) => (

                                        <div key={index} className={`feedback-row updated`}>
                                            <div className={`option`} onClick={() => { }}>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {
                                                    item.description && <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill')}>
                                                        <i className='fas fa-info fa-sm' />
                                                    </div>
                                                }
                                            </div>

                                            <button
                                                className={`${(!isSkillSelected(item.name) || isSkillSelected(item.name, 'essential')) ? 'disabled' : ''}`}
                                                disabled={(!isSkillSelected(item.name) || isSkillSelected(item.name, 'essential'))}
                                                onClick={() => { moveSkill(item) }}>
                                                <i className="fas fa-circle-check me-2" />
                                                {t('functions:detail:feedback:skills:accept')}
                                            </button>
                                        </div>
                                    ))}
                                </>
                            }

                            {
                                skillsFeedbackCategories && skillsFeedbackCategories.updatedToOptional?.length > 0 &&
                                <>
                                    <div className="category-type mt-3">
                                        <i className="fas fa-arrow-down me-2"></i>
                                        {t('functions:detail:feedback:skills:updatedToOptionalBy')} {feedback.name}
                                    </div>

                                    {skillsFeedbackCategories.updatedToOptional?.map((item, index) => (

                                        <div key={index} className={`feedback-row updated`}>
                                            <div className={`option`} onClick={() => { }}>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {
                                                    item.description &&
                                                    <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill')}>
                                                        <i className='fas fa-info fa-sm' />
                                                    </div>
                                                }
                                            </div>
                                            <button
                                                className={`${(!isSkillSelected(item.name) || isSkillSelected(item.name, 'optional')) ? 'disabled' : ''}`}
                                                disabled={(!isSkillSelected(item.name) || isSkillSelected(item.name, 'optional'))}
                                                onClick={() => { moveSkill(item) }}>
                                                <i className="fas fa-circle-check me-2" />
                                                {t('functions:detail:feedback:skills:accept')}
                                            </button>
                                        </div>
                                    ))}
                                </>
                            }

                            {
                                skillsFeedbackCategories && skillsFeedbackCategories.unchanged?.length > 0 &&
                                <>
                                    <div className="category-type mt-3">
                                        <i className="fas fa-check me-2"></i>
                                        {t('functions:detail:feedback:skills:unchanged')}
                                    </div>

                                    {skillsFeedbackCategories.unchanged?.map((item, index) => (

                                        <div key={index} className={`feedback-row unchanged`}>
                                            <div className={`option`}>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {
                                                    item.description &&
                                                    <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill')}>
                                                        <i className='fas fa-info fa-sm' />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </>
                            }
                        </div>
                    </div>
                </Col>
            </Row >

            <Row className="knowledge-container bg-wit mt-5">

                <div className="font-bold text-paars h3 py-2 m-0">
                    {t('functions:detail:skills:knowledgeTitle')}
                </div>

                <p className="pb-4">
                    {t('functions:detail:feedback:skills:knowledgeDescription')}
                </p>

                <Col sm={12} md={12} lg={6} xl={6} xxl={6} className="p-0 p-sm-2">
                    <div className="h4 text-oranje font-heavy mb-2">
                        {t('functions:detail:skills:functionTitle')}
                    </div>

                    <div className="function-column">
                        <div className="droppables-wrapper p-2">
                            <div className="skill-type">{t('functions:detail:skills:essential')}</div>
                            <div className={`droppable-container mb-4`}>
                                {functionKnowledge?.essential.map((item, index) => (
                                    <div key={index} className={`option mb-2`}>
                                        <span className={`drag-item-title`}>{index + 1}. {item.name}</span>
                                        {
                                            item.description &&
                                            <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill')}>
                                                <i className='fas fa-info fa-sm' />
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className="skill-type">{t('functions:detail:skills:optional')}</div>
                            <div className={`droppable-container optional p-2`}>
                                {functionKnowledge?.optional.map((item, index) => (
                                    <div key={index} className={`option mb-2`}>
                                        <span className="drag-item-title">{index + 1}. {item.name}</span>
                                        {
                                            item.description &&
                                            <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill')}>
                                                <i className='fas fa-info fa-sm' />
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={12} lg={6} xl={6} xxl={6} className="p-0 p-sm-2 mt-4 mt-md-0">
                    <div>
                        <span className="h4 font-bold text-oranje ms-1">
                            {t('functions:detail:feedback:skills:sourceTitle')} {feedback.name}
                        </span>
                    </div>

                    <div className="source-column">
                        <div className={`source-options`}>
                            {
                                knowledgeFeedbackCategories && knowledgeFeedbackCategories.added?.length > 0 &&
                                <>
                                    <div className="category-type mt-3">
                                        <i className="fas fa-plus me-2"></i>
                                        {t('functions:detail:feedback:skills:addedBy')} {feedback.name}
                                    </div>

                                    {knowledgeFeedbackCategories.added?.map((item, index) => (

                                        <div key={index} className={`feedback-row added`}>
                                            <div className={`option`}>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {
                                                    item.description &&
                                                    <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill')}>
                                                        <i className='fas fa-info fa-sm' />
                                                    </div>
                                                }
                                            </div>

                                            <button
                                                className={`${isKnowledgeSelected(item.name) ? 'disabled' : ''}`}
                                                disabled={isKnowledgeSelected(item.name)}
                                                onClick={() => { addKnowledge(item) }}>
                                                <i className="fas fa-circle-check me-2" />
                                                {t('functions:detail:feedback:skills:accept')}
                                            </button>
                                        </div>
                                    ))}
                                </>
                            }

                            {
                                knowledgeFeedbackCategories && knowledgeFeedbackCategories.deleted?.length > 0 &&
                                <>
                                    <div className="category-type mt-3">
                                        <i className="fas fa-trash-can me-2"></i>
                                        {t('functions:detail:feedback:skills:deletedBy')} {feedback.name}
                                    </div>

                                    {knowledgeFeedbackCategories.deleted?.map((item, index) => (

                                        <div key={index} className={`feedback-row deleted`}>
                                            <div className={`option`}>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {
                                                    item.description &&
                                                    <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill')}>
                                                        <i className='fas fa-info fa-sm' />
                                                    </div>
                                                }
                                            </div>

                                            <button
                                                className={`${!isKnowledgeSelected(item.name) ? 'disabled' : ''}`}
                                                disabled={!isKnowledgeSelected(item.name)}
                                                onClick={() => { deleteKnowledge(item) }}>
                                                <i className="fas fa-circle-check me-2" />
                                                {t('functions:detail:feedback:skills:accept')}
                                            </button>
                                        </div>
                                    ))}
                                </>
                            }

                            {
                                knowledgeFeedbackCategories && knowledgeFeedbackCategories.updatedToEssential?.length > 0 &&
                                <>
                                    <div className="category-type mt-3">
                                        <i className="fas fa-arrow-up me-2"></i>
                                        {t('functions:detail:feedback:skills:updatedToEssentialBy')} {feedback.name}
                                    </div>

                                    {knowledgeFeedbackCategories.updatedToEssential?.map((item, index) => (

                                        <div key={index} className={`feedback-row updated`}>
                                            <div className={`option`}>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {
                                                    item.description &&
                                                    <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'knowledge')}>
                                                        <i className='fas fa-info fa-sm' />
                                                    </div>
                                                }
                                            </div>

                                            <button
                                                className={`${(!isKnowledgeSelected(item.name) || isKnowledgeSelected(item.name, 'essential')) ? 'disabled' : ''}`}
                                                disabled={(!isKnowledgeSelected(item.name) || isKnowledgeSelected(item.name, 'essential'))}
                                                onClick={() => { moveKnowledge(item) }}>
                                                <i className="fas fa-circle-check me-2" />
                                                {t('functions:detail:feedback:skills:accept')}
                                            </button>
                                        </div>
                                    ))}
                                </>
                            }

                            {
                                knowledgeFeedbackCategories && knowledgeFeedbackCategories.updatedToOptional?.length > 0 &&
                                <>
                                    <div className="category-type mt-3">
                                        <i className="fas fa-arrow-down me-2"></i>
                                        {t('functions:detail:feedback:skills:updatedToOptionalBy')} {feedback.name}
                                    </div>

                                    {knowledgeFeedbackCategories.updatedToOptional?.map((item, index) => (

                                        <div key={index} className={`feedback-row updated`}>
                                            <div className={`option`}>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {
                                                    item.description &&
                                                    <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'knowledge')}>
                                                        <i className='fas fa-info fa-sm' />
                                                    </div>
                                                }
                                            </div>

                                            <button
                                                className={`${(!isKnowledgeSelected(item.name) || isKnowledgeSelected(item.name, 'optional')) ? 'disabled' : ''}`}
                                                disabled={(!isKnowledgeSelected(item.name) || isKnowledgeSelected(item.name, 'optional'))}
                                                onClick={() => { moveKnowledge(item) }}>
                                                <i className="fas fa-circle-check me-2" />
                                                {t('functions:detail:feedback:skills:accept')}
                                            </button>
                                        </div>
                                    ))}
                                </>
                            }

                            {
                                knowledgeFeedbackCategories && knowledgeFeedbackCategories.unchanged?.length > 0 &&
                                <>
                                    <div className="category-type mt-3">
                                        <i className="fas fa-check me-2"></i>
                                        {t('functions:detail:feedback:skills:unchanged')}
                                    </div>

                                    {knowledgeFeedbackCategories.unchanged?.map((item, index) => (
                                        <div key={index} className={`feedback-row unchanged`}>

                                            <div className={`option`} onClick={() => { }}>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {
                                                    item.description &&
                                                    <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill')}>
                                                        <i className='fas fa-info fa-sm' />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </>
                            }
                        </div>
                    </div>
                </Col>
            </Row>

            <Modal className="wihv-modal" centered show={showInfoModal} onHide={() => setShowInfoModal(false)}>
                <Modal.Header closeButton>
                    <div className='h3 text-donkerblauw'>{t('general:meaning')}</div>
                </Modal.Header>
                <Modal.Body>
                    <p className='py-3 px-2'><span className='font-bold'>{selectedValue?.name}:</span> {selectedValue?.description}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary-outline" onClick={() => setShowInfoModal(false)}>{t('general:close')}</button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default FunctionFeedbackSelection;