import { ConfigContext } from "@/context/config-context";
import useAuthenticatedPost from "@/hooks/useAuthenticatedPost";
import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { EmployerFunction } from "../modules/functions/model/employer-function";
import { FunctionContext, IFunctionState } from "./function-context";

function FunctionProvider({ children }): React.ReactElement {

    const { t } = useTranslation();
    const configContext = useContext(ConfigContext);
    const { postRequest } = useAuthenticatedPost();
    const { mutate: mutateList } = useSWR(configContext.configBody.api.endpoints.functions.list);

    const [contextValue, setContextValue] = useState<IFunctionState>({
        employerFunction: undefined,
        tempChanges: undefined,
        editable: false,
        requestInProgress: false,
        saveEmployerFunction: saveEmployerFunction,
        updateEmployerFunction: updateEmployerFunction,
        updateChanges: updateChanges,
        updateEditable: updateEditable,
    });

    function updateEditable(editable: boolean) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                tempChanges: prevContextValue.employerFunction,
                editable: editable,
            };
        });
    }

    function updateChanges(employerFunction: EmployerFunction) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                tempChanges: employerFunction,
            };
        });
    }

    function updateEmployerFunction(employerFunction: EmployerFunction) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                employerFunction: employerFunction,
                tempChanges: employerFunction,
            };
        });
    }

    function saveEmployerFunction(employerFunction: EmployerFunction): Promise<EmployerFunction> {

        if (!employerFunction.name) {
            toast.error(t('functions:toasts:titleNotSet'));
            return Promise.reject();
        }

        let updatedEnabled = false;
        
        setContextValue((prevContextValue) => {

            //check if function has been activated/deactivated
            updatedEnabled = prevContextValue.employerFunction.enabled !== employerFunction.enabled;
            
            return {
                ...prevContextValue,
                requestInProgress: true,
            };
        });

        return postRequest(configContext.configBody.api.endpoints.functions.list, employerFunction)
            .then((response: EmployerFunction) => {
                updateEmployerFunction(response);
                updateEditable(false);
                mutateList();

                if (updatedEnabled) {
                    toast.success(employerFunction.enabled ? t('functions:toasts:enabledSuccess') : t('functions:toasts:disabledSuccess'));
                } else {
                    toast.success(t('functions:toasts:saveSuccess'));
                }

                if (!employerFunction.enabled) {
                    toast.success(t('functions:toasts:disabledWarning'), { duration: 5000, icon: '⚠️' });
                }

                return response;
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('functions:toasts:saveFailed') + ' ' + error.message);
                throw error;
            })
            .finally(() => {
                setContextValue((prevContextValue) => {
                    return {
                        ...prevContextValue,
                        requestInProgress: false,
                    };
                });
            });
    };

    return (
        <FunctionContext.Provider value={contextValue}>
            {children}
        </FunctionContext.Provider>
    );
}

export default FunctionProvider;