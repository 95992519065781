import { Link } from "react-router-dom"

type CtaBlockProps = {
    textColor?: string
    bgColor?: string
    text: string[]
    buttonText?: string
    buttonLink?: string
}

function CtaBlock({ textColor = "text-zwartblauw", bgColor = "bg-wit", text, buttonText, buttonLink }: CtaBlockProps) {
    return (
        <div className={`p-3 text-center mb-3 default-br ${textColor} ${bgColor}`}>
            {
                text?.map((text, index) => <p key={index} className="mb-0">{text}</p>)
            }
            {
                buttonText &&
                <Link to={buttonLink} className='btn btn-secondary mt-2'>
                    {buttonText}
                    <i className='fas fa-chevron-right ms-2' />
                </Link>
            }
        </div>
    )
}

export default CtaBlock