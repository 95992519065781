import { Company } from '@/company/model/company';
import { CompanyContext } from '@/context/company-context';
import { useContext } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './company-card.scss';
import { CompanySize } from '@/company/model/company-size';
import { useCompanySizeTranslation } from '@/general/i18n/translation-helpers';
import FieldOfWorkSearchBar from '@/components/field-of-work-search/field-of-work-search-bar';

function CompanyDetails() {

    const { t } = useTranslation();
    const context = useContext(CompanyContext);
    const companySizes = Object.values(CompanySize).filter(value => !isNaN(Number(value)));
    const { translateCompanySize } = useCompanySizeTranslation();

    const updateTempChanges = (propertyName, newValue) => {

        const updatedCompany: Company = {
            ...context.tempChanges,
            [propertyName]: newValue
        };

        context.updateChanges(updatedCompany);
    };

    return (
        <>
            <Card className="company-card mb-4">
                <Card.Header>
                    <span>{t('company:details:title')}</span>
                </Card.Header>
                <Card.Body>
                    <Card.Text as="div">
                        <div className='mt-4'>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label>{t('company:details:labelKvk')}</Form.Label>
                                            <Form.Control type="text" placeholder="" value={context.tempChanges?.kvk ?? ''}  className='readonly' readOnly onChange={e => updateTempChanges('kvk', e.target.value)} />
                                        </div>
                                        : <div className='field view'>{t('company:details:labelKvk')}: {context.company?.kvk}</div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <FieldOfWorkSearchBar selectedFieldOfWork={context.tempChanges?.fieldOfWork} onFieldOfWorkChange={(s) => updateTempChanges('fieldOfWork', s)} />
                                        </div>
                                        : <div className='field view'>{t('company:details:labelFieldOfWork')}: {context.company?.fieldOfWork?.description}</div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <div className='field edit'>
                                            <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('company:details:labelCompanySize')}</Form.Label>
                                            <Form.Select
                                                aria-label="Select Company size"
                                                value={context.tempChanges?.companySize || 0}
                                                onChange={(e) => {
                                                    if (e.target.selectedIndex == 0) { return; }
                                                    updateTempChanges('companySize', e.target.value)
                                                }}
                                            >
                                                <option key={-1} disabled value="">{t('company:details:placeholderCompanySize')}</option>
                                                {companySizes.map((level, index) => (
                                                    <option key={index} value={level}>
                                                        {translateCompanySize(+level)}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                        : <div className='field view'>{t('company:details:labelCompanySize')}: {translateCompanySize(context.company?.companySize)}</div>
                                    }
                                </Col>
                            </Form.Group>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

export default CompanyDetails;