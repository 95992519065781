import { t } from "i18next";
import './function-active-indicator.scss';

type FunctionActiveIndicatorProps = {
    enabled: boolean;
}

function FunctionActiveIndicator({enabled}: FunctionActiveIndicatorProps) {
    return (
        <div className="active-indicator">
            <i className={`fas fa-circle me-2 fa-sm ${enabled ? 'enabled' : ''}`}></i>
            <p className="font-medium-italic">{enabled ? t('general:enabled') : t('general:disabled')} </p>
        </div>
    )
}

export default FunctionActiveIndicator