import PageFooter from "@/components/footers/page-footer"
import PageHeader from "@/components/page-header/page-header"
import { useDocumentTitle } from "@/hooks/useDocumentTitle"
import { t } from "i18next"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import './pages.scss'

function TraineeshipsPage() {

    useDocumentTitle(t('documentTitles:traineeships'));
    const info = t('public:traineeships:info', { returnObjects: true }) as any[];

    return (
        <div className="app-container">
            <div className="main-container">
                <main className="public traineeships">

                    <PageHeader
                        title={t("public:traineeships:title")}
                        imgSrc="/assets/img/header-beroep-gis-specialist.jpg"
                        description={t('public:traineeships:description')}
                    />

                    <Container fluid className='bg-lichtblauw'>
                        <Container>
                            <Row className="py-5">
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0 position-relative">
                                    <div className="img left position-absolute">
                                        <img src="/assets/img/afbeelding-traineeships.jpg" className="img-fluid" alt='' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0">
                                    <div className="textblock right p-4 p-md-5">
                                        {
                                            info?.map((item, index) =>
                                                <div className="info" key={index}>
                                                    <h2>{item.title}</h2>
                                                    <p>{item.description}</p>
                                                </div>
                                            )
                                        }
                                        <Link to="/contact" className="btn btn-secondary mt-4">
                                            {t('public:traineeships:contactButton')}
                                            <i className="fas fa-chevron-right ms-2"></i>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </main>
                <PageFooter />
            </div>
        </div>
    )
}

export default TraineeshipsPage