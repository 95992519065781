import EmployerFunctionCard from "@/components/cards/employer-function-card";
import { ConfigContext } from "@/context/config-context";
import Header from "@/layout/header/header";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import './functions-overview.scss';
import { EmployerFunction } from "./model/employer-function";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

function FunctionsOverview() {

    useDocumentTitle(t('documentTitles:functions'));

    var configContext = useContext(ConfigContext);
    const navigate = useNavigate();

    // Functions grid
    const [searchTerm, setSearchTerm] = useState('');
    const [activeFilter, setActiveFilter] = useState(true);
    const [inactiveFilter, setInactiveFilter] = useState(true);
    const [filteredFunctions, setFilteredFunctions] = useState<EmployerFunction[]>([]);

    const [functions, setFunctions] = useState<EmployerFunction[]>([]);
    const { data, isLoading, error } = useSWR(configContext.configBody.api.endpoints.functions.list);

    useEffect(() => {

        if (!data) return;
        setFunctions(data);

    }, [data]);

    useEffect(() => {
        sortAndFilterFunctions();
    }, [functions, activeFilter, inactiveFilter, searchTerm]);

    const sortAndFilterFunctions = () => {

        let functionsResult = [...functions];

        if (searchTerm) {
            functionsResult = functionsResult.filter((employerFunction) =>
                employerFunction.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        functionsResult = functionsResult.sort((a, b) => { return new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime() });

        functionsResult = functionsResult.filter((employerFunction) => {
            if (activeFilter && inactiveFilter) {
                return true;
            } else if (activeFilter) {
                return employerFunction.enabled;
            } else if (inactiveFilter) {
                return !employerFunction.enabled;
            }
            return false;
        });

        setFilteredFunctions(functionsResult);
    }

    return (
        <div className="functions-overview">
            <Container fluid className='bg-oranje position-relative pb-5'>
                <Container>

                    <div className='header-img full position-absolute px-0'>
                        <img src={'/assets/img/WIHV_3D_Visual_Rood.jpg'} style={{ objectPosition: 'center center' }} alt='' />
                    </div>

                    <div className="position-relative pb-5">
                        <Header />
                    </div>

                    <Row className='position-relative first-row-after-heading-img'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6} className='d-flex flex-column text-wit'>
                            <div className="font-heavy h2 pb-5">{t('functions:overview:title')}</div>
                            <p className='flex-grow-1'>
                                {t('functions:overview:description1')}<br /><br />
                                {t('functions:overview:description2')}<br /><br />
                                {t('functions:overview:description3')}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="bg-blauw py-5 position-relative">
                <Container>
                    <div className="background-colored-block"></div>
                    <Row className="position-relative">
                        <Col sm={12} md={12} lg={12} xl={12} xxl={12} className='d-flex justify-content-between align-items-center mb-5 flex-column flex-lg-row'>
                            <div className="font-heavy h2 text-geel">
                                {t('functions:overview:gridTitle')}
                            </div>

                            <div className="filters mt-3 mt-lg-0">
                                <div className='filter-toggle'>
                                    <button
                                        onClick={() => { setActiveFilter(!activeFilter) }}
                                        className={`${activeFilter ? 'selected' : ''}`}>
                                        {t('functions:overview:toggleActive')}
                                    </button>
                                </div>
                                <div className='filter-toggle'>
                                    <button
                                        onClick={() => { setInactiveFilter(!inactiveFilter) }}
                                        className={`${inactiveFilter ? 'selected' : ''}`}>
                                        {t('functions:overview:toggleInactive')}
                                    </button>
                                </div>
                            </div>

                            <div className="d-flex mt-3 mt-lg-0">
                                <Form.Control type="text" placeholder={t('functions:overview:placeholderGridSearch')} value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} />
                            </div>
                        </Col>
                    </Row>

                    <div className="functions-container pb-5 z-1 position-relative">

                        {isLoading && <div className="loader"></div>}

                        <Row className="gy-4">
                            {!isLoading && (
                                <>
                                    <Col sm={12} md={6} lg={4} xl={3} xxl={3}>
                                        <div tabIndex={0} className="add-function-card" role="button" onClick={() => navigate('/profile/functions/add')}>
                                            <div className="add-function-icon">
                                                <i className="fas fa-plus fa-2x"></i>
                                            </div>
                                            <div className="add-function-text">
                                                <span className="font-medium">{t('functions:overview:addFunction')}</span>
                                            </div>
                                        </div>
                                    </Col>

                                    {
                                        filteredFunctions.map((employerFunction) => (
                                            <Col sm={12} md={6} lg={4} xl={3} xxl={3} key={employerFunction.id}>
                                                <EmployerFunctionCard employerFunction={employerFunction} />
                                            </Col>
                                        ))
                                    }
                                </>
                            )}
                        </Row>
                    </div>
                </Container>
            </Container>
        </div>
    )
}

export default FunctionsOverview