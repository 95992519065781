import { Col, Container, Row } from 'react-bootstrap';
import { MeasurementDefinition } from '../model/measurement-definition'
import './measurement-start.scss';
import { t } from 'i18next';
import Header from '@/layout/header/header';

type MeasurementStartComponentProps = {
    measurement: MeasurementDefinition;
    onStartMeasurement: () => void;
}

function MeasurementStart({ measurement, onStartMeasurement }: MeasurementStartComponentProps) {

    return (
        <div className='start-container'>
            <Container fluid className="bg-groen pb-5 position-relative">
                <Container>
                    <Row className='measurement-header pb-0 pb-md-5'>
                        <div className='measurement-img position-absolute px-0'>
                            <img src="/assets/img/WIHV_3D_Visual_Groen_Wit_Large.png" alt='' />
                        </div>

                        <div className="position-relative pb-5">
                            <Header />
                        </div>

                        <Col sm={12} md={12} lg={8} xl={8} xxl={8} className='position-relative text-wit d-flex flex-column justify-content-center'>
                            <h1 className='title'>{measurement.title}</h1>
                            <p className='intro'>{measurement.introduction}</p>
                        </Col>

                        <Col sm={12} md={12} lg={{ span: 3, offset: 1 }} xl={{ span: 3, offset: 1 }} xxl={{ span: 3, offset: 1 }} className='position-relative mt-4 mt-md-0'>
                            <div className='metadata'>
                                <h5 className='title'>{t('measurements:start:aboutTitle')}</h5>
                                <p>{t('measurements:start:duration')}{measurement.durationInMinutes}{t('measurements:start:minutes')}</p>
                                {
                                    measurement.completed &&
                                    <p className='text-danger'>{t('measurements:start:overwriteWarning')}</p>
                                }
                                <button type='button' className='btn btn-secondary mt-4' onClick={onStartMeasurement}>
                                    {t('measurements:start:startBtn')}
                                    <i className="fas fa-arrow-right ms-2"></i>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="bg-blauw py-5">
                <Container>
                    <Col sm={12} md={6} lg={6} xl={6} xxl={6} className='info'>
                        <h5 className='title'>{t('measurements:start:contentTitle')}</h5>
                        <p className='description'>{measurement.description}</p>
                    </Col>
                </Container>
            </Container>
        </div>
    )
}

export default MeasurementStart