import './radial-chart.scss'

type RadialChartProps = {
    title: string
    percentage: number
    displayPercentage?: boolean
    dimension?: number
    strokeWidth?: number
    fontSize?: number
    strokeColor?: string
    textColor?: string
}

function RadialChart({ title, percentage, displayPercentage = true, dimension, strokeWidth, fontSize, strokeColor = '#fe7225', textColor = '#000' }: RadialChartProps) {

    const radius = 80;
    const circleRadius = Math.min(radius, 85);
    const circumference = 2 * Math.PI * circleRadius;
    const strokeLength = (circumference / 100) * percentage;

    return (
        <div className={'radial-chart'} title={`${title}: ${percentage}%`}>
            <svg viewBox="0 0 180 180" width={dimension ?? 100} height={dimension ?? 100} aria-label={`${title}: ${percentage}%`}>
                <circle
                    className="radial-chart-total"
                    stroke={'#FFF'}
                    strokeWidth={strokeWidth ?? 15}
                    fill="none"
                    cx="90"
                    cy="90"
                    r={circleRadius}
                />
                <circle
                    className="radial-chart-progress"
                    stroke={strokeColor}
                    strokeWidth={strokeWidth ?? 15}
                    strokeDasharray={`${strokeLength} ${circumference}`}
                    strokeLinecap="round"
                    fill="none"
                    cx="90"
                    cy="90"
                    r={circleRadius}
                />
                {displayPercentage &&
                    <text className='radial-chart-percentage'
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize={fontSize ?? 30}
                        fontWeight={600}
                        fill={textColor}>
                        {percentage}%
                    </text>
                }
            </svg>
        </div>
    );
}

export default RadialChart