import { ErrorComponent } from '@/components/error-component/error-component';
import { LoadingComponent } from '@/components/loading-component/loading-component';
import { ConfigContext } from '@/context/config-context';
import Header from '@/layout/header/header';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { MeasurementDefinition } from '../model/measurement-definition';
import { MeasurementResult, MeasurementResultRadarChart } from '../model/measurement-result';
import { ResultVisualizationType } from '../model/result-visualization-type';
import './measurement-result.scss';
import RadarChartResult from './radar-chart-result';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

function MeasurementResultContainer() {

    useDocumentTitle(t('documentTitles:measurementResult'));

    const location = useLocation();
    const configContext = useContext(ConfigContext);
    const { measurementId } = useParams();
    const result: MeasurementResult = location.state?.result;

    const { data: measurement, isLoading: measurementLoading, error: measurementError, mutate } = useSWR<MeasurementDefinition>(measurementId ? configContext.configBody.api.endpoints.measurements.detail.replace('{id}', measurementId) : null, { shouldRetryOnError: false });
    const { data: apiResult, isLoading: resultLoading, error: resultError } = useSWR<MeasurementResult>(!result ? configContext.configBody.api.endpoints.measurements.results.replace('{id}', measurementId) : null, { shouldRetryOnError: false });
    const [measurementResult, setMeasurementResult] = useState<MeasurementResult>(null);
    
    useEffect(() => {

        if (result) {
            setMeasurementResult(result);
            mutate();
        }

        if (apiResult) {
            setMeasurementResult(apiResult);
        }

    }, [result, apiResult]);

    if (measurementLoading || resultLoading) {
        return <LoadingComponent message={t('measurements:results:loadingResult')} />;
    }

    if (measurementError || resultError) {
        return <ErrorComponent message={t('measurements:results:noResultError')}/>;
    }
    
    return (
        <div className='result-container'>
            {
                measurementResult &&
                <>
                    <Container fluid className="bg-groen pb-5 position-relative">
                        <Container>
                            <Row className='measurement-header'>

                                <div className='measurement-img position-absolute px-0'>
                                    <img src="/assets/img/WIHV_3D_Visual_Groen_Wit_Large.png" alt='' />
                                </div>

                                <div className="position-relative pb-5">
                                    <Header />
                                </div>

                                <Col sm={12} md={12} lg={6} xl={6} xxl={6} className='text-wit position-relative'>
                                    <h1 className='title'>{t('measurements:results:title')}</h1>
                                    <p className='intro'>{measurement.resultExplanation}</p>
                                </Col>

                            </Row>
                        </Container>
                    </Container>

                    <Container fluid className="bg-lichtblauw py-5">
                        <Container>
                            {
                                measurementResult && measurement.resultVisualizationType === ResultVisualizationType.RadarChart &&
                                <RadarChartResult measurementResult={measurementResult as MeasurementResultRadarChart} />
                            }

                            <Row>
                                <Col sm={12} md={12} lg={12} xl={12}>
                                    <Link to='/profile/measurements' className='btn btn-primary-outline mt-4'>
                                        {t('measurements:results:backBtn')}
                                        <i className="fas fa-arrow-right ms-2"></i>
                                    </Link>
                                    <Link to={`/profile/measurements/${measurement.id}`} className='btn btn-secondary mt-4'>
                                        {t('measurements:results:restartBtn')}
                                        <i className="fas fa-arrow-right ms-2"></i>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </>
            }
        </div>
    )
}

export default MeasurementResultContainer