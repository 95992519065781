import { Activity } from "@/company/model/activity";
import dayjs from 'dayjs';
import './employer-activity-card.scss';
import { useState } from "react";
import { useTranslation } from "react-i18next";

type EmployerActivityCardProps = {
    activity: Activity;
    onEdit: () => void;
    onDelete: () => void;
}

function EmployerActivityCard({ activity, onEdit, onDelete }: EmployerActivityCardProps) {

    const { t } = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <div className='activity-card'>
            <div className='card-content'>
                <div className='card-header'>
                    <div className='card-title'>{activity.name}</div>
                    <div className='card-actions' role="button" tabIndex={0}
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                setIsDropdownOpen(!isDropdownOpen);
                            }
                        }}>
                        <i className="fas fa-ellipsis-vertical fa-lg" />
                    </div>
                    <div className={`card-actions-dropdown ${isDropdownOpen ? 'show' : ''}`}
                        onMouseLeave={() => setIsDropdownOpen(false)}>
                        <div className='card-actions-dropdown-item' role="button" tabIndex={isDropdownOpen ? 0 : -1} onClick={() => onEdit()}>
                            <i className="fas fa-pen fa-md" />
                            <div>{t('activities:card:edit')}</div>
                        </div>
                        <div className='card-actions-dropdown-item' role="button" tabIndex={isDropdownOpen ? 0 : -1} onClick={() => onDelete()}>
                            <i className="fas fa-trash-can fa-md" />
                            <div>{t('activities:card:delete')}</div>
                        </div>
                    </div>
                </div>
                <div className='card-description'>
                    <p>
                        {activity.description}
                    </p>
                </div>
                <div className="card-icon-row"><i className="fas fa-location-dot" /><p>{activity.location}</p></div>

                {
                    <div className="card-icon-row">
                        <i className="fas fa-calendar" />
                        <p className="d-flex justify-content-between">
                            <span>
                                {dayjs(activity.date).format("DD-MM-YY")}
                            </span>
                            <span>
                                {dayjs(activity.startTime).format("HH:mm")} - {dayjs(activity.endTime).format("HH:mm")}
                            </span>
                        </p>
                    </div>
                }

                {
                    activity.link &&
                    <div className="card-icon-row">
                        <i className="fas fa-link" />
                        <a href={activity.link.startsWith('http://') || activity.link.startsWith('https://') ? activity.link : `https://${activity.link}`} target="_blank">
                            {t('activities:card:link')}
                        </a>
                    </div>
                }
            </div>
        </div>
    )
}

export default EmployerActivityCard