import { ConfigContext } from '@/context/config-context';
import { DataSourceType, FunctionDataSource } from '@/functions/model/function-data-source';
import debounce from "debounce-promise";
import { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { ProfessionSearchDocument } from './profession-search-document';
import './search-bar.scss';

type SearchBarProps = {
    selectedProfessions?: FunctionDataSource[];
    onProfessionsChange: (s: ProfessionSearchDocument[]) => void;
}

export default function ProfessionMultiSearchBar({ selectedProfessions, onProfessionsChange }: SearchBarProps) {
    const { t } = useTranslation();
    var configContext = useContext(ConfigContext);
    const [selectedOptions, setSelectedOptions] = useState<MultiValue<any>[]>([]);

    useEffect(() => {

        if (selectedProfessions && selectedProfessions.length > 0) {
            const selectedOptions = selectedProfessions.filter(x => x.type == DataSourceType.Profession).map((p) => {
                return {
                    value: {
                        id: p.id,
                        name: p.label,
                        skills: p.skills,
                        knowledge: p.knowledge,
                    } as ProfessionSearchDocument,
                    label: p.label
                }
            });

            setSelectedOptions(selectedOptions as unknown as MultiValue<any>[]);
        }
    }, [selectedProfessions]);

    const handleSelectedOptions = (selectedOptions: any) => {

        setSelectedOptions(selectedOptions);
        const newlySelectedProfessions = selectedOptions?.map((s: any) => s.value) ?? [];
        onProfessionsChange(newlySelectedProfessions);
    }

    const _loadProfessionSuggestions = (inputValue: string) => {

        const savedLanguage = localStorage.getItem('selectedLanguage') ?? 'nl';

        const body = {
            filter: `language eq '${savedLanguage}'`,
            select: 'name, id, skills, knowledge, description, professionName',
            search: inputValue,
            suggesterName: 'esco-profession-suggester',
            top: 15
        };

        return fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.search.professions}/suggest`, {
            credentials: "same-origin",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Invalid response");
            })
            .then((result) => {
                let groupedResults = result.value.reduce((acc: any, item: ProfessionSearchDocument) => {
                    const key = item.professionName;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(item);
                    return acc;
                }, {});

                return Object.keys(groupedResults).map((key) => {
                    return {
                        label: key,
                        options: groupedResults[key].map((item: ProfessionSearchDocument) => {
                            return {
                                value: item,
                                label: item.name
                            };
                        })
                    };
                });
            })
            .catch(
                (error) => {

                });
    }

    const loadProfessionSuggestions = debounce(_loadProfessionSuggestions, 300);

    return (
        <Form.Group as={Row} className='profession-search mt-4'>
            <Col>
                <Form.Label>{t('functions:detail:professionSearch:labelSearch')}</Form.Label>
                <AsyncSelect
                    isMulti={true}
                    isClearable
                    placeholder={t('functions:detail:professionSearch:placeholderSearch')}
                    value={selectedOptions}
                    onChange={(s) => handleSelectedOptions(s)}
                    defaultOptions={false}
                    loadOptions={loadProfessionSuggestions}
                    required={true}
                    hideSelectedOptions={true}
                    isOptionDisabled={() => selectedOptions?.length >= 3}
                    filterOption={(o) => selectedOptions?.find((s: any) => s.label === o.label) === undefined}
                    className='p-0'
                    noOptionsMessage={(e) => e.inputValue ? t('functions:detail:professionSearch:noProfessionsFound') : null}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: state.isDisabled ? '' : 'none',
                            borderRadius: '26px',
                            padding: '0.5rem',
                            boxShadow: 'none'
                        }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: 'black',
                            cursor: 'pointer'
                        }),
                    }} />
            </Col>
        </Form.Group>
    );
};