import { FieldOfWork } from '@/company/model/field-of-work';
import { ConfigContext } from '@/context/config-context';
import getToken from "@/general/token-retriever";
import { useMsal } from "@azure/msal-react";
import debounce from "debounce-promise";
import { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import AsyncSelect from 'react-select/async';
import { SingleValue } from "react-select/dist/declarations/src/types";
import './search-bar.scss';

type SearchBarProps = {
    selectedFieldOfWork?: FieldOfWork;
    onFieldOfWorkChange: (s: FieldOfWork) => void;
}

export default function FieldOfWorkSearchBar({ selectedFieldOfWork, onFieldOfWorkChange }: SearchBarProps) {
    const { t } = useTranslation();
    const configContext = useContext(ConfigContext);
    const msalContext = useMsal();

    const [selectedOption, setSelectedOption] = useState<SingleValue<any>[]>([]);

    useEffect(() => {

        if (selectedFieldOfWork) {
            const selectedOptions = {
                value: {
                    code: selectedFieldOfWork.code,
                    description: selectedFieldOfWork.description,
                    id: selectedFieldOfWork.id
                } as FieldOfWork,
                label: `${selectedFieldOfWork.code} - ${selectedFieldOfWork.description}`
            };

            setSelectedOption(selectedOptions as unknown as SingleValue<any>[]);
        }
    }, [selectedFieldOfWork]);

    const handleSelectedOption = (selectedOption: any) => {

        setSelectedOption(selectedOption);
        const selectedFieldOfWork = selectedOption?.value ?? null;
        onFieldOfWorkChange(selectedFieldOfWork);
    }

    const _loadFieldOfWorkSuggestions = async (inputValue: string) => {

        const token = await getToken(msalContext, configContext);
        let url = `${configContext.configBody.api.baseUrl}${configContext.configBody.api.endpoints.search.fieldOfWork}`;

        // Add query parameters to url
        const params = new URLSearchParams({ query: inputValue } as any);
        url += `?${params}`;

        return fetch(url, {
            credentials: "same-origin",
            method: 'GET',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey,
            }
        })
            .then((response) => {                
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Invalid response");
            })
            .then((result) => {                
                return result.map((item: FieldOfWork) => {
                    return {
                        value: item,
                        label: `${item.code} - ${item.description}`
                    };
                });
            })
            .catch(
                (error) => {

                });
    }

    const loadFieldOfWorkSuggestions = debounce(_loadFieldOfWorkSuggestions, 300);

    return (
        <Form.Group as={Row} className='fieldOfWork-search'>
            <Col>
                <Form.Label>{t('company:details:labelFieldOfWork')}</Form.Label>
                <AsyncSelect
                    isMulti={false}
                    isClearable
                    placeholder={t('company:details:placeholderFieldOfWork')}
                    value={selectedOption}
                    onChange={(s) => handleSelectedOption(s)}
                    defaultOptions={false}
                    loadOptions={loadFieldOfWorkSuggestions}
                    hideSelectedOptions={true}
                    className='p-0'
                    noOptionsMessage={(e) => e.inputValue ? t('company:details:noFieldOfWork') : null}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: state.isDisabled ? '' : 'none',
                            borderRadius: '26px',
                            padding: '0.5rem',
                            boxShadow: 'none'
                        }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: 'black',
                            cursor: 'pointer'
                        }),
                    }} />
            </Col>
        </Form.Group>
    );
};