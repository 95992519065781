import { FunctionDataSource } from "@/functions/model/function-data-source";
import { Knowledge, KnowledgeValue } from "@/functions/model/knowledge";
import { Skill, Skills } from "@/functions/model/skills";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Col, Form, Modal, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import SkillsSearchBar from "../skills-search/search-bar";
import SkillSourceSelection from "./skill-source-selection";
import { SkillValue } from "./skill-value";
import './skills-selection.scss';

type SkillsSelectionProps = {
    type?: 'function' | 'feedback';
    sources: FunctionDataSource[];
    skills: Skills;
    knowledge: Knowledge;
    onSkillsChange: (skills: Skills) => void;
    onKnowledgeChange: (knowledge: Knowledge) => void;
}

function SkillsSelection({ type = 'function', sources, skills, knowledge, onSkillsChange, onKnowledgeChange }: SkillsSelectionProps) {

    const prevFunctionSkills = useRef<Skills>();
    const prevFunctionKnowledge = useRef<Knowledge>();

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [skillToCreate, setSkillToCreate] = useState<SkillValue>(null);

    const [currentSource, setCurrentSource] = useState<FunctionDataSource>(sources[0]);

    const [functionSkills, setFunctionSkills] = useState<Skills>({ essential: [], optional: [] });
    const [functionKnowledge, setFunctionKnowledge] = useState<Knowledge>({ essential: [], optional: [] });

    useEffect(() => {

        if (!currentSource) {
            if (sources.length > 0) {
                setCurrentSource(sources[0]);
            } else {
                setCurrentSource(null);
            }
        } else {
            const source = sources.find(s => s.id === currentSource.id);
            if (!source) {
                setCurrentSource(sources[0]);
            }
        }  

        if (!prevFunctionSkills.current || JSON.stringify(prevFunctionSkills.current) !== JSON.stringify(skills)) {
            setFunctionSkills(skills);
            prevFunctionSkills.current = skills;
        }

        if (!prevFunctionKnowledge.current || JSON.stringify(prevFunctionKnowledge.current) !== JSON.stringify(knowledge)) {
            setFunctionKnowledge(knowledge);
            prevFunctionKnowledge.current = knowledge;
        }

    }, [sources]);

    useEffect(() => {
        if (!prevFunctionSkills.current || JSON.stringify(prevFunctionSkills.current) !== JSON.stringify(functionSkills)) {
            onSkillsChange(functionSkills);
            prevFunctionSkills.current = functionSkills;
        }
    }, [functionSkills]);

    useEffect(() => {
        if (!prevFunctionKnowledge.current || JSON.stringify(prevFunctionKnowledge.current) !== JSON.stringify(functionKnowledge)) {
            onKnowledgeChange(functionKnowledge);
            prevFunctionKnowledge.current = functionKnowledge;
        }
    }, [functionKnowledge]);


    const getList = (droppableId) => {
        switch (droppableId) {
            case 'source-skills-essential':
                return currentSource?.skills?.essential ?? [];
            case 'source-skills-optional':
                return currentSource?.skills?.optional ?? [];
            case 'source-knowledge-essential':
                return currentSource?.knowledge?.essential ?? [];
            case 'source-knowledge-optional':
                return currentSource?.knowledge?.optional ?? [];
            case 'function-skills-essential':
                return functionSkills?.essential ?? [];
            case 'function-skills-optional':
                return functionSkills?.optional ?? [];
            case 'function-knowledge-essential':
                return functionKnowledge?.essential ?? [];
            case 'function-knowledge-optional':
                return functionKnowledge?.optional ?? [];
            default:
                return [];
        }
    };

    const onDragEnd = result => {
        const { source, destination } = result;

        if (!destination) return;

        if (source.droppableId === destination.droppableId) {

            if (destination.droppableId === 'function-skills-essential') {
                const newEssentialSkills = Array.from(functionSkills.essential);
                const [removed] = newEssentialSkills.splice(source.index, 1);
                newEssentialSkills.splice(destination.index, 0, removed);
                setFunctionSkills({ ...functionSkills, essential: newEssentialSkills });
            } else if (destination.droppableId === 'function-skills-optional') {
                const newOptionalSkills = Array.from(functionSkills.optional);
                const [removed] = newOptionalSkills.splice(source.index, 1);
                newOptionalSkills.splice(destination.index, 0, removed);
                setFunctionSkills({ ...functionSkills, optional: newOptionalSkills });
            } else if (destination.droppableId === 'function-knowledge-essential') {
                const newEssentialKnowledge = Array.from(functionKnowledge.essential);
                const [removed] = newEssentialKnowledge.splice(source.index, 1);
                newEssentialKnowledge.splice(destination.index, 0, removed);
                setFunctionKnowledge({ ...functionKnowledge, essential: newEssentialKnowledge });
            } else if (destination.droppableId === 'function-knowledge-optional') {
                const newOptionalKnowledge = Array.from(functionKnowledge.optional);
                const [removed] = newOptionalKnowledge.splice(source.index, 1);
                newOptionalKnowledge.splice(destination.index, 0, removed);
                setFunctionKnowledge({ ...functionKnowledge, optional: newOptionalKnowledge });
            }

        } else {

            if (destination.droppableId === 'function-skills-essential') {
                const newEssentialSkills = Array.from(functionSkills.essential);
                const newOptionalSkills = Array.from(functionSkills.optional);
                const sourceSkills = getList(source.droppableId);

                if (!newEssentialSkills.find(x => x.name.trim().toLowerCase() == sourceSkills[source.index].name.trim().toLowerCase()) &&
                    !newOptionalSkills.find(x => x.name.trim().toLowerCase() == sourceSkills[source.index].name.trim().toLowerCase())) {
                    newEssentialSkills.splice(destination.index, 0, sourceSkills[source.index]);
                    setFunctionSkills({ ...functionSkills, essential: newEssentialSkills });
                    return;
                }

                if (source.droppableId === 'function-skills-optional' && !newEssentialSkills.find(x => x.name.trim().toLowerCase() == sourceSkills[source.index].name.trim().toLowerCase())) {
                    const [removed] = newOptionalSkills.splice(source.index, 1);
                    newEssentialSkills.splice(destination.index, 0, removed);
                    setFunctionSkills({ essential: newEssentialSkills, optional: newOptionalSkills });
                    return;
                }
            }

            if (destination.droppableId === 'function-skills-optional') {
                const newEssentialSkills = Array.from(functionSkills.essential);
                const newOptionalSkills = Array.from(functionSkills.optional);
                const sourceSkills = getList(source.droppableId);

                if (!newOptionalSkills.find(x => x.name.trim().toLowerCase() == sourceSkills[source.index].name.trim().toLowerCase()) &&
                    !newEssentialSkills.find(x => x.name.trim().toLowerCase() == sourceSkills[source.index].name.trim().toLowerCase())) {
                    newOptionalSkills.splice(destination.index, 0, sourceSkills[source.index]);
                    setFunctionSkills({ ...functionSkills, optional: newOptionalSkills });
                    return;
                }

                if (source.droppableId === 'function-skills-essential' && !newOptionalSkills.find(x => x.name.trim().toLowerCase() == sourceSkills[source.index].name.trim().toLowerCase())) {
                    const [removed] = newEssentialSkills.splice(source.index, 1);
                    newOptionalSkills.splice(destination.index, 0, removed);
                    setFunctionSkills({ essential: newEssentialSkills, optional: newOptionalSkills });
                    return;
                }
            }

            if (destination.droppableId === 'function-knowledge-essential') {
                const newEssentialKnowledge = Array.from(functionKnowledge.essential);
                const newOptionalKnowledge = Array.from(functionKnowledge.optional);
                const sourceKnowledge = getList(source.droppableId);

                if (!newEssentialKnowledge.find(x => x.name.trim().toLowerCase() == sourceKnowledge[source.index].name.trim().toLowerCase()) &&
                    !newOptionalKnowledge.find(x => x.name.trim().toLowerCase() == sourceKnowledge[source.index].name.trim().toLowerCase())) {
                    newEssentialKnowledge.splice(destination.index, 0, sourceKnowledge[source.index]);
                    setFunctionKnowledge({ ...functionKnowledge, essential: newEssentialKnowledge });
                    return;
                }

                if (source.droppableId === 'function-knowledge-optional' && !newEssentialKnowledge.find(x => x.name.trim().toLowerCase() == sourceKnowledge[source.index].name.trim().toLowerCase())) {
                    const [removed] = newOptionalKnowledge.splice(source.index, 1);
                    newEssentialKnowledge.splice(destination.index, 0, removed);
                    setFunctionKnowledge({ essential: newEssentialKnowledge, optional: newOptionalKnowledge });
                    return;
                }
            }

            if (destination.droppableId === 'function-knowledge-optional') {
                const newEssentialKnowledge = Array.from(functionKnowledge.essential);
                const newOptionalKnowledge = Array.from(functionKnowledge.optional);
                const sourceKnowledge = getList(source.droppableId);

                if (!newOptionalKnowledge.find(x => x.name.trim().toLowerCase() == sourceKnowledge[source.index].name.trim().toLowerCase()) &&
                    !newEssentialKnowledge.find(x => x.name.trim().toLowerCase() == sourceKnowledge[source.index].name.trim().toLowerCase())) {
                    newOptionalKnowledge.splice(destination.index, 0, sourceKnowledge[source.index]);
                    setFunctionKnowledge({ ...functionKnowledge, optional: newOptionalKnowledge });
                    return;
                }

                if (source.droppableId === 'function-knowledge-essential' && !newOptionalKnowledge.find(x => x.name.trim().toLowerCase() == sourceKnowledge[source.index].name.trim().toLowerCase())) {
                    const [removed] = newEssentialKnowledge.splice(source.index, 1);
                    newOptionalKnowledge.splice(destination.index, 0, removed);
                    setFunctionKnowledge({ essential: newEssentialKnowledge, optional: newOptionalKnowledge });
                    return;
                }
            }

            toast.error(t('functions:detail:skills:skillsValueExists'));
        }
    };

    const handleDelete = (droppableId, itemToDelete) => {

        switch (droppableId) {
            case 'function-skills-essential':
                toast(t('functions:detail:skills:skillsDeleted'));
                setFunctionSkills(prevSkills => {
                    const updatedList = prevSkills.essential.filter(item => item.name.trim().toLowerCase() !== itemToDelete.name.trim().toLowerCase());
                    return {
                        ...prevSkills,
                        essential: updatedList
                    };
                });
                return;
            case 'function-skills-optional':
                toast(t('functions:detail:skills:skillsDeleted'));
                setFunctionSkills(prevSkills => {
                    const updatedList = prevSkills.optional.filter(item => item.name.trim().toLowerCase() !== itemToDelete.name.trim().toLowerCase());
                    return {
                        ...prevSkills,
                        optional: updatedList
                    };
                });
                return;
            case 'function-knowledge-essential':
                toast(t('functions:detail:skills:knowledgeDeleted'));
                setFunctionKnowledge(prevKnowledge => {
                    const updatedList = prevKnowledge.essential.filter(item => item.name.trim().toLowerCase() !== itemToDelete.name.trim().toLowerCase());
                    return {
                        ...prevKnowledge,
                        essential: updatedList
                    };
                });
                return;
            case 'function-knowledge-optional':
                toast(t('functions:detail:skills:knowledgeDeleted'));
                setFunctionKnowledge(prevKnowledge => {
                    const updatedList = prevKnowledge.optional.filter(item => item.name.trim().toLowerCase() !== itemToDelete.name.trim().toLowerCase());
                    return {
                        ...prevKnowledge,
                        optional: updatedList
                    };
                });
                return;
            default:
                return;
        }
    };

    const handleKnowledgeSelected = (selectedKnowledge, container: 'essential' | 'optional') => {

        if (container === 'essential') {
            if (functionKnowledge?.essential.find(x => x.name.trim().toLowerCase() == selectedKnowledge.name.trim().toLowerCase())) {
                toast(t('functions:detail:skills:knowledgeDeleted'));
                setFunctionKnowledge(prevKnowledge => {
                    prevKnowledge = prevKnowledge || new class implements Knowledge {
                        essential: KnowledgeValue[] = [];
                        optional: KnowledgeValue[] = [];
                    }
                    const updatedList = prevKnowledge.essential.filter(item => item.name.trim().toLowerCase() !== selectedKnowledge.name.trim().toLowerCase());
                    return {
                        ...prevKnowledge,
                        essential: updatedList
                    };
                });
            } else {
                toast.success(t('functions:detail:skills:knowledgeAdded'));
                setFunctionKnowledge(prevKnowledge => {
                    prevKnowledge = prevKnowledge || new class implements Knowledge {
                        essential: KnowledgeValue[] = [];
                        optional: KnowledgeValue[] = [];
                    }
                    const updatedList = [...prevKnowledge.essential, selectedKnowledge as KnowledgeValue];
                    return {
                        ...prevKnowledge,
                        essential: updatedList
                    };
                });
            }
        } else {
            if (functionKnowledge?.optional.find(x => x.name.trim().toLowerCase() == selectedKnowledge.name.trim().toLowerCase())) {
                toast(t('functions:detail:skills:knowledgeDeleted'));
                setFunctionKnowledge(prevKnowledge => {
                    prevKnowledge = prevKnowledge || new class implements Knowledge {
                        essential: KnowledgeValue[] = [];
                        optional: KnowledgeValue[] = [];
                    }
                    const updatedList = prevKnowledge.optional.filter(item => item.name.trim().toLowerCase() !== selectedKnowledge.name.trim().toLowerCase());
                    return {
                        ...prevKnowledge,
                        optional: updatedList
                    };
                });
            } else {
                toast.success(t('functions:detail:skills:knowledgeAdded'));
                setFunctionKnowledge(prevKnowledge => {
                    prevKnowledge = prevKnowledge || new class implements Knowledge {
                        essential: KnowledgeValue[] = [];
                        optional: KnowledgeValue[] = [];
                    }
                    const updatedList = [...prevKnowledge.optional, selectedKnowledge as KnowledgeValue];
                    return {
                        ...prevKnowledge,
                        optional: updatedList
                    };
                });
            }
        }
    }

    const handleSkillSelected = (selectedSkill, container: 'essential' | 'optional') => {

        if (container === 'essential') {

            if (functionSkills?.essential.find(x => x.name.trim().toLowerCase() == selectedSkill.name.trim().toLowerCase())) {
                toast(t('functions:detail:skills:skillsDeleted'));
                setFunctionSkills(prevSkills => {
                    prevSkills = prevSkills || new class implements Skills {
                        essential: Skill[] = [];
                        optional: Skill[] = [];
                    };
                    const updatedList = prevSkills.essential.filter(item => item.name.trim().toLowerCase() !== selectedSkill.name.trim().toLowerCase());
                    return {
                        ...prevSkills,
                        essential: updatedList
                    };
                });
            } else {
                toast.success(t('functions:detail:skills:skillsAdded'));
                setFunctionSkills(prevSkills => {
                    prevSkills = prevSkills || new class implements Skills {
                        essential: Skill[] = [];
                        optional: Skill[] = [];
                    };
                    const updatedList = [...prevSkills.essential, selectedSkill as Skill];
                    return {
                        ...prevSkills,
                        essential: updatedList
                    };
                });
            }
        } else {
            if (functionSkills?.optional.find(x => x.name.trim().toLowerCase() == selectedSkill.name.trim().toLowerCase())) {
                toast(t('functions:detail:skills:skillsDeleted'));
                setFunctionSkills(prevSkills => {
                    prevSkills = prevSkills || new class implements Skills {
                        essential: Skill[] = [];
                        optional: Skill[] = [];
                    };
                    const updatedList = prevSkills.optional.filter(item => item.name.trim().toLowerCase() !== selectedSkill.name.trim().toLowerCase());
                    return {
                        ...prevSkills,
                        optional: updatedList
                    };
                });
            } else {
                toast.success(t('functions:detail:skills:skillsAdded'));
                setFunctionSkills(prevSkills => {
                    prevSkills = prevSkills || new class implements Skills {
                        essential: Skill[] = [];
                        optional: Skill[] = [];
                    };
                    const updatedList = [...prevSkills.optional, selectedSkill as Skill];
                    return {
                        ...prevSkills,
                        optional: updatedList
                    };
                });
            }
        }
    }

    const createNewSkillValue = () => {

        if (skillToCreate.type === 'skill') {

            if (skillToCreate?.isEssential) {
                toast.success(t('functions:detail:skills:skillsAdded'));
                setFunctionSkills(prevSkills => {
                    prevSkills = prevSkills || new class implements Skills {
                        essential: Skill[] = [];
                        optional: Skill[] = [];
                    };
                    const updatedList = [...prevSkills.essential, { name: skillToCreate.name, description: skillToCreate.description } as Skill];
                    return {
                        ...prevSkills,
                        essential: updatedList
                    };
                });
            } else {
                toast.success(t('functions:detail:skills:skillsAdded'));
                setFunctionSkills(prevSkills => {
                    prevSkills = prevSkills || new class implements Skills {
                        essential: Skill[] = [];
                        optional: Skill[] = [];
                    };
                    const updatedList = [...prevSkills.optional, { name: skillToCreate.name, description: skillToCreate.description } as Skill];
                    return {
                        ...prevSkills,
                        optional: updatedList
                    };
                });
            }

        } else {

            if (skillToCreate?.isEssential) {
                toast.success(t('functions:detail:skills:knowledgeAdded'));
                setFunctionKnowledge(prevKnowledge => {
                    prevKnowledge = prevKnowledge || new class implements Knowledge {
                        essential: KnowledgeValue[] = [];
                        optional: KnowledgeValue[] = [];
                    }
                    const updatedList = [...prevKnowledge.essential, { name: skillToCreate.name, description: skillToCreate.description } as KnowledgeValue];
                    return {
                        ...prevKnowledge,
                        essential: updatedList
                    };
                });
            } else {
                toast.success(t('functions:detail:skills:knowledgeAdded'));
                setFunctionKnowledge(prevKnowledge => {
                    prevKnowledge = prevKnowledge || new class implements Knowledge {
                        essential: KnowledgeValue[] = [];
                        optional: KnowledgeValue[] = [];
                    }
                    const updatedList = [...prevKnowledge.optional, { name: skillToCreate.name, description: skillToCreate.description } as KnowledgeValue];
                    return {
                        ...prevKnowledge,
                        optional: updatedList
                    };
                });
            }
        }

        setShowCreateModal(false);
        setSkillToCreate(null);
    }

    const handleKnowledgeCreated = (createdKnowledge) => {

        if (functionKnowledge && (functionKnowledge.essential.find(x => x.name.trim().toLowerCase() == createdKnowledge.trim().toLowerCase()) ||
            functionKnowledge.optional?.find(x => x.name.trim().toLowerCase() == createdKnowledge.trim().toLowerCase()))) {
            toast.error(t('functions:detail:skills:skillsValueExists'));
            return;
        }

        setSkillToCreate({name: createdKnowledge, description: null, isEssential: true, type: 'knowledge'});
        setShowCreateModal(true);
    }

    const handleSkillCreated = (createdSkill) => {

        if (functionSkills && (functionSkills.essential.find(x => x.name.trim().toLowerCase() == createdSkill.trim().toLowerCase()) ||
            functionSkills.optional.find(x => x.name.trim().toLowerCase() == createdSkill.trim().toLowerCase()))) {
            toast.error(t('functions:detail:skills:skillsValueExists'));
            return;
        }

        setSkillToCreate({ name: createdSkill, description: null, isEssential: true, type: 'skill' });
        setShowCreateModal(true);
    }

    const isSkillSelected = (skillName) => {
        if (skillName === null || skillName === undefined) return false;

        const normalizedSkillName = skillName.trim().toLowerCase();
        return !!(
            functionSkills?.essential.find(x => x.name.trim().toLowerCase() === normalizedSkillName) ||
            functionSkills?.optional.find(x => x.name.trim().toLowerCase() === normalizedSkillName)
        );
    };

    const isKnowledgeSelected = (skillName) => {
        if (skillName === null || skillName === undefined) return false;

        const normalizedSkillName = skillName.trim().toLowerCase();
        return !!(
            functionKnowledge?.essential.find(x => x.name.trim().toLowerCase() === normalizedSkillName) ||
            functionKnowledge?.optional.find(x => x.name.trim().toLowerCase() === normalizedSkillName)
        );
    };

    const handleInfoClick = (event, item, type, isOptional) => {
        event.stopPropagation();

        const isSelected = selectedValue?.type === 'skill'
            ? isSkillSelected(selectedValue?.name)
            : isKnowledgeSelected(selectedValue?.name);

        // Determine the droppableId based on the item's type and optional status
        const droppableIdMiddle = type === 'skill' ? 'skills' : type;
        const droppableIdSuffix = isOptional ? 'optional' : 'essential';
        const droppableId = `function-${droppableIdMiddle}-${droppableIdSuffix}`;

        // Set the selected value
        setSelectedValue({
            name: item.name,
            description: item.description,
            droppableId: droppableId,
            isOptional: isOptional,
            isSelected: isSelected,
            type: type
        });

        // Show the info modal
        setShowInfoModal(true);
    };

    function renderRemoveButton() {
        const buttonText = selectedValue?.type === 'knowledge'
            ? t('functions:detail:skills:removeKnowledge')
            : t('functions:detail:skills:removeSkill');

        return (
            <button
                className="btn btn-danger"
                onClick={() => { handleDelete(selectedValue?.droppableId, selectedValue); setShowInfoModal(false) }}
            >
                {buttonText}
            </button>
        );
    }

    function renderAddOrRemoveButton() {
        const isSelected = selectedValue?.type === 'skill'
            ? isSkillSelected(selectedValue?.name)
            : isKnowledgeSelected(selectedValue?.name);

        return isSelected ? renderRemoveButton() : renderAddButton();
    }

    function renderAddButton() {
        const isOptional = selectedValue?.isOptional ? 'optional' : 'essential';
        const handleClick = selectedValue?.type === 'knowledge'
            ? () => handleKnowledgeSelected(selectedValue, isOptional)
            : () => handleSkillSelected(selectedValue, isOptional);
        const buttonText = selectedValue?.type === 'knowledge'
            ? t('functions:detail:skills:addKnowledge')
            : t('functions:detail:skills:addSkill');

        return (
            <button className="btn btn-success" onClick={() => { handleClick(); setShowInfoModal(false) }}>{buttonText}</button>
        );
    }

    function handleSourceChange(source: FunctionDataSource) {
        setCurrentSource(source);
    }

    return (
        <div className="skills-selection-wrapper">

            <div className="skills-container bg-wit">

                <DragDropContext onDragEnd={onDragEnd}>

                    <div className="font-bold text-paars h3 p-2 m-0">
                        {t('functions:detail:skills:skillsTitle')}
                    </div>

                    <p className="p-2 pb-4">
                        {type == 'feedback'
                            ? t('feedback:skillsDescription')
                            : t('functions:detail:skills:skillsDescription')}
                    </p>

                    <Col sm={12} md={12} lg={6} xl={6} xxl={6} className="p-0 p-sm-2">
                        <div className="function-column">

                            <div className="h4 text-oranje font-heavy mb-2">
                                {type == 'feedback'
                                    ? t('feedback:functionTitle')
                                    : t('functions:detail:skills:functionTitle')}
                            </div>

                            <div className="droppables-wrapper p-2 d-flex">
                                <div className="skill-type">{t('functions:detail:skills:essential')}</div>
                                <Droppable droppableId="function-skills-essential">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            className={`droppable-container ${snapshot.isDraggingOver ? 'dragging-over' : ''} mb-4 flex-grow-1`}>
                                            {functionSkills?.essential.map((item, index) => (
                                                <Draggable
                                                    key={`function-skills-essential-"${index}`}
                                                    draggableId={`function-skills-essential-"${index}`}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`option mb-2 deleteable ${snapshot.isDragging ? 'dragging' : ''}`}>
                                                            <div className="drag-icon me-2">
                                                                <i className="fas fa-grip-vertical"></i>
                                                            </div>
                                                            <span className={`drag-item-title`}>{index + 1}. {item.name}</span>
                                                            {item.description && <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill', false)}>
                                                                <i className='fas fa-info fa-sm' />
                                                            </div>}
                                                            <div tabIndex={0} role="button" className="drag-item-delete ms-2 me-1" onClick={() => handleDelete('function-skills-essential', item)}>
                                                                <i className="fas fa-times"></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                                <div className="skill-type">{t('functions:detail:skills:optional')}</div>
                                <Droppable droppableId="function-skills-optional">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            className={`droppable-container optional p-2 ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}>
                                            {functionSkills?.optional.map((item, index) => (
                                                <Draggable
                                                    key={`function-skills-optional-"${index}`}
                                                    draggableId={`function-skills-optional-"${index}`}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`option mb-2 deleteable ${snapshot.isDragging ? 'dragging' : ''}`}>
                                                            <div className="drag-icon me-2">
                                                                <i className="fas fa-grip-vertical"></i>
                                                            </div>
                                                            <span className="drag-item-title">{index + 1}. {item.name}</span>
                                                            {item.description && <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill', true)}>
                                                                <i className='fas fa-info fa-sm' />
                                                            </div>}
                                                            <div tabIndex={0} role="button" className="drag-item-delete ms-2 me-1" onClick={() => handleDelete('function-skills-optional', item)}>
                                                                <i className="fas fa-times"></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6} className={`p-0 p-sm-2 mt-4 mt-md-0 ${sources?.length == 0 ? 'd-flex justify-content-center' : ''}`}>
                        <div className="source-column">
                            {
                                sources?.length == 0 &&
                                <div className="d-flex h-100 justify-content-center align-items-center no-source">
                                    <div className="d-flex flex-column gap-3 text-center">
                                        <i className="fas fa-exclamation-triangle fa-lg" />
                                        <span className="h5 font-bold">{t('functions:detail:skills:noSourceAvailable')}</span>
                                    </div>
                                </div>
                            }

                            {
                                sources?.length > 0 &&
                                <>
                                    <SkillSourceSelection sources={sources} currentSource={currentSource} onSourceChange={(s) => handleSourceChange(s)} />
                                    <div className={`source-options ${type == 'feedback' ? 'feedback' : ''}`}>

                                        <div className="skill-type mt-3">{t('functions:detail:skills:essential')}</div>

                                        {currentSource?.skills?.essential?.map((item, index) => (
                                            <div
                                                key={index}
                                                className={`option add ${isSkillSelected(item.name) ? 'selected' : ''}`}
                                                onClick={() => { handleSkillSelected(item, 'essential') }}>
                                                <div className="start-icon me-2">
                                                    {
                                                        isSkillSelected(item.name) ? <i className="fas fa-check"></i> : <i className="fas fa-plus"></i>
                                                    }
                                                </div>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {item.description && <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill', false)}>
                                                    <i className='fas fa-info fa-sm' />
                                                </div>}
                                            </div>
                                        ))}

                                        <div className="skill-type mt-3">{t('functions:detail:skills:optional')}</div>

                                        {currentSource?.skills?.optional?.map((item, index) => (
                                            <div
                                                key={index}
                                                className={`option add ${isSkillSelected(item.name) ? 'selected' : ''}`}
                                                onClick={() => { handleSkillSelected(item, 'optional') }}>
                                                <div className="start-icon me-2">
                                                    {
                                                        isSkillSelected(item.name) ? <i className="fas fa-check"></i> : <i className="fas fa-plus"></i>
                                                    }
                                                </div>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {item.description && <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'skill', true)}>
                                                    <i className='fas fa-info fa-sm' />
                                                </div>}
                                            </div>
                                        ))}
                                    </div>
                                </>
                            }
                        </div>
                    </Col>

                    <div className="skill-search p-2 pt-3 pb-4">
                        <div className="font-bold">{t('functions:detail:skills:skillsNotFoundTitle')}</div>
                        <p className="pb-2">{t('functions:detail:skills:skillsNotFoundDescription')}</p>
                        <SkillsSearchBar type="skill" onSkillSelected={(s) => { handleSkillSelected(s, 'essential') }} onSkillCreated={(s) => { handleSkillCreated(s) }} />
                    </div>

                </DragDropContext>
            </div>

            <div className="knowledge-container bg-wit mt-5">

                <DragDropContext onDragEnd={onDragEnd}>

                    <div className="font-bold text-paars h3 p-2 m-0">
                        {t('functions:detail:skills:knowledgeTitle')}
                    </div>

                    <p className="p-2 pb-4">
                        {type == 'feedback'
                            ? t('feedback:knowledgeDescription')
                            : t('functions:detail:skills:knowledgeDescription')}
                    </p>

                    <Col sm={12} md={12} lg={6} xl={6} xxl={6} className="p-0 p-sm-2">
                        <div className="function-column">

                            <div className="h4 text-oranje font-heavy mb-2">
                                {type == 'feedback'
                                    ? t('feedback:functionTitle')
                                    : t('functions:detail:skills:functionTitle')}
                            </div>

                            <div className="droppables-wrapper p-2 d-flex">
                                <div className="skill-type">{t('functions:detail:skills:essential')}</div>
                                <Droppable droppableId="function-knowledge-essential">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            className={`droppable-container ${snapshot.isDraggingOver ? 'dragging-over' : ''} mb-4 flex-grow-1`}>
                                            {functionKnowledge?.essential.map((item, index) => (
                                                <Draggable
                                                    key={`function-knowledge-essential-"${index}`}
                                                    draggableId={`function-knowledge-essential-"${index}`}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`option mb-2 deleteable ${snapshot.isDragging ? 'dragging' : ''}`}>
                                                            <div className="drag-icon me-2">
                                                                <i className="fas fa-grip-vertical"></i>
                                                            </div>
                                                            <span className={`drag-item-title`}>{index + 1}. {item.name}</span>
                                                            {item.description && <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'knowledge', false)}>
                                                                <i className='fas fa-info fa-sm' />
                                                            </div>}
                                                            <div tabIndex={0} role="button" className="drag-item-delete ms-2 me-1" onClick={() => handleDelete('function-knowledge-essential', item)}>
                                                                <i className="fas fa-times"></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                                <div className="skill-type">{t('functions:detail:skills:optional')}</div>
                                <Droppable droppableId="function-knowledge-optional">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            className={`droppable-container optional p-2 ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}>
                                            {functionKnowledge?.optional.map((item, index) => (
                                                <Draggable
                                                    key={`function-knowledge-optional-"${index}`}
                                                    draggableId={`function-knowledge-optional-"${index}`}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`option mb-2 deleteable ${snapshot.isDragging ? 'dragging' : ''}`}>
                                                            <div className="drag-icon me-2">
                                                                <i className="fas fa-grip-vertical"></i>
                                                            </div>
                                                            <span className="drag-item-title">{index + 1}. {item.name}</span>
                                                            {item.description && <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'knowledge', true)}>
                                                                <i className='fas fa-info fa-sm' />
                                                            </div>}
                                                            <div tabIndex={0} role="button" className="drag-item-delete ms-2 me-1" onClick={() => handleDelete('function-knowledge-optional', item)}>
                                                                <i className="fas fa-times"></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6} className={`p-0 p-sm-2 mt-4 mt-md-0 ${sources?.length == 0 ? 'd-flex justify-content-center' : ''}`}>
                        <div className="source-column">
                            {
                                sources?.length == 0 &&
                                <div className="d-flex h-100 justify-content-center align-items-center no-source">
                                    <div className="d-flex flex-column gap-3 text-center">
                                        <i className="fas fa-exclamation-triangle fa-lg" />
                                        <span className="h5 font-bold">{t('functions:detail:skills:noSourceAvailable')}</span>
                                    </div>
                                </div>
                            }

                            {
                                sources?.length > 0 &&
                                <>
                                    <SkillSourceSelection sources={sources} currentSource={currentSource} onSourceChange={(s) => handleSourceChange(s)} />
                                    <div className={`source-options ${type == 'feedback' ? 'feedback' : ''}`}>

                                        <div className="skill-type mt-3">{t('functions:detail:skills:essential')}</div>

                                        {currentSource?.knowledge?.essential?.map((item, index) => (
                                            <div
                                                key={index}
                                                className={`option add ${isKnowledgeSelected(item.name) ? 'selected' : ''}`}
                                                onClick={() => { handleKnowledgeSelected(item, 'essential') }}>
                                                <div className="start-icon me-2">
                                                    {
                                                        isKnowledgeSelected(item.name) ? <i className="fas fa-check"></i> : <i className="fas fa-plus"></i>
                                                    }
                                                </div>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {item.description && <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'knowledge', false)}>
                                                    <i className='fas fa-info fa-sm' />
                                                </div>}
                                            </div>
                                        ))}

                                        <div className="skill-type mt-3">{t('functions:detail:skills:optional')}</div>

                                        {currentSource?.knowledge?.optional?.map((item, index) => (
                                            <div
                                                key={index}
                                                className={`option add ${isKnowledgeSelected(item.name) ? 'selected' : ''}`}
                                                onClick={() => { handleKnowledgeSelected(item, 'optional') }}>
                                                <div className="start-icon me-2">
                                                    {
                                                        isKnowledgeSelected(item.name) ? <i className="fas fa-check"></i> : <i className="fas fa-plus"></i>
                                                    }
                                                </div>
                                                <span className={`drag-item-title`}>{item.name}</span>
                                                {item.description && <div className='drag-item-info ms-2' role='button' tabIndex={0} onClick={(e) => handleInfoClick(e, item, 'knowledge', true)}>
                                                    <i className='fas fa-info fa-sm' />
                                                </div>}
                                            </div>
                                        ))}
                                    </div>
                                </>
                            }
                        </div>
                    </Col>

                    <div className="skill-search p-2 pt-3 pb-4">
                        <div className="font-bold">{t('functions:detail:skills:knowledgeNotFoundTitle')}</div>
                        <p className="pb-2">{t('functions:detail:skills:knowledgeNotFoundDescription')}</p>
                        <SkillsSearchBar type="knowledge" onSkillSelected={(s) => { handleKnowledgeSelected(s, 'essential') }} onSkillCreated={(s) => { handleKnowledgeCreated(s) }} />
                    </div>
                </DragDropContext>
            </div>

            <Modal className="wihv-modal" centered show={showInfoModal} onHide={() => setShowInfoModal(false)}>
                <Modal.Header closeButton>
                    <div className='h3 text-donkerblauw'>{t('general:meaning')}</div>
                </Modal.Header>
                <Modal.Body>
                    <p className='py-3 px-2'><span className='font-bold'>{selectedValue?.name}:</span> {selectedValue?.description}</p>
                </Modal.Body>
                <Modal.Footer>
                    {renderAddOrRemoveButton()}
                    <button className="btn btn-primary-outline" onClick={() => setShowInfoModal(false)}>{t('general:close')}</button>
                </Modal.Footer>
            </Modal>

            <Modal className="add-skill-modal wihv-modal" centered show={showCreateModal} onHide={() => setShowCreateModal(false)}>
                <Modal.Header closeButton>
                    <div className='h3 text-donkerblauw'>{t('functions:detail:skills:addModal:title')}</div>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>
                        <div className="h3 font-bold pb-3">{skillToCreate?.name}</div>
                    </Form.Label>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Label>{t('functions:detail:skills:addModal:labelDescription')}</Form.Label>
                            <Form.Control as="textarea" rows={6} placeholder={t('functions:detail:skills:addModal:placeholderDescription')} value={skillToCreate?.description ?? ''} onChange={e => setSkillToCreate((prev) => { return { ...prev, description: e.target.value } })} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-3">
                        <Col>
                            <div className='form-toggle'>
                                <span
                                    role='button'
                                    tabIndex={0}
                                    onClick={() => setSkillToCreate((prev) => { return { ...prev, isEssential: true } })}
                                    className={`form-toggle-option ${skillToCreate?.isEssential ? 'selected' : ''}`}>
                                    {t('functions:detail:skills:essential')}
                                </span>
                                <span
                                    role='button'
                                    tabIndex={0}
                                    onClick={() => setSkillToCreate((prev) => { return { ...prev, isEssential: false } })}
                                    className={`form-toggle-option ${!skillToCreate?.isEssential ? 'selected' : ''}`}>
                                    {t('functions:detail:skills:optional')}
                                </span>
                            </div>
                        </Col>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary-outline" onClick={() => setShowCreateModal(false)}>{t('general:cancel')}</button>
                    <button className="btn btn-secondary" onClick={() => createNewSkillValue()}>{t('general:create')}</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SkillsSelection;